import { Paper } from '@mui/material'
import { FC } from 'react'

import styles from './maintenance.module.scss'

// INFO: Keep the h1 and h3 tags as is
// NOTE: Do not change them to the new TextV2 type. TextV2 tag uses redux right now
// 	     and the following message will not show if used
const Maintenance: FC = () => {
	return (
		<div className={styles.maintenanceWrapper}>
			<Paper className={styles.maintenanceContent}>
				<h1>Site is currently down for scheduled maintenance.</h1>
				<h3>We expect to be back in a couple hours. Thanks for your patience.</h3>
			</Paper>
		</div>
	)
}

export default Maintenance
