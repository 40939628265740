import { Close, Search } from '@mui/icons-material'
import { Autocomplete, Button, IconButton, Input, InputAdornment, TextField } from '@mui/material'
import { useIsFetching, useQuery } from '@tanstack/react-query'
import { searchKeys } from 'api/search'
import { getNonExclusiveFacetsQuery } from 'api/search/queryOptions/facets'
import { useOfferQuerySuggestionsQueryOptions } from 'api/search/queryOptions/offers'
import { useAppContext } from 'app/contexts/app-context'
import { useGetPrefixedIndex } from 'config/search-config'
import { generateSearchUrl } from 'feature/search/utils/url-mapping'
import { useDebounce } from 'hooks/use-debounce'
import { Transition } from 'hooks/use-navigation-popup'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { BlockerFunction, useBlocker, useLocation, useNavigate } from 'react-router-dom'
import { routesMap } from 'routes/routes-map'
import { useSearchSlice } from 'state/store'
import { getDealCategorySearchUrl } from 'utils/url'

import styles from './search-box.module.css'

export type SearchBoxProps = {
	closeMenu?: () => void
	id?: string
	onCancelClick: () => void
	track: (query: any) => unknown
}

const AUTOCOMPLETE_CONFIG = {
	hitsPerPage: 5,
}

const SearchBox: FC<SearchBoxProps> = memo(({ closeMenu, id, onCancelClick, track }: SearchBoxProps) => {
	const location = useLocation()
	const navigate = useNavigate()
	const { siteInfo } = useAppContext()
	const { b4b_search_autocomplete, b4b_searchAutoSelectCat } = useFlags()
	const hasDiscounts = siteInfo.properties.hasDiscounts
	const getIndexName = useGetPrefixedIndex()
	const queryConfig = getNonExclusiveFacetsQuery({}, { algoliaIndexName: getIndexName('offers') })
	const { data: nonExclusiveOfferFacets = [] } = useQuery({
		...queryConfig,
		enabled: hasDiscounts,
	})

	const { clearFacetValues } = useSearchSlice((s) => ({
		clearFacetValues: s.search.clearFacetValues,
	}))

	const { query, setQuery } = useSearchSlice((s) => ({
		query: s.search.query,
		setQuery: s.search.setQuery,
	}))

	const lastStateQueryValue = useRef(query)

	// Sync local query value with state query value
	useEffect(() => {
		if (lastStateQueryValue.current !== query) {
			lastStateQueryValue.current = query
			setLocalQueryValue(query)
		}
	}, [query])

	const isLoading = useIsFetching({
		exact: false,
		queryKey: searchKeys.search.all(),
	})

	const [localQueryValue, setLocalQueryValue] = useState(query)
	const debouncedQuery = useDebounce(localQueryValue, 300)

	const querySuggestionsConfig = useOfferQuerySuggestionsQueryOptions(debouncedQuery, {
		hitsPerPage: AUTOCOMPLETE_CONFIG.hitsPerPage,
	})
	const { data: querySuggestions = [], isLoading: querySuggestionsLoading } = useQuery({
		...querySuggestionsConfig,
		enabled: debouncedQuery.length > 2 && b4b_search_autocomplete,
	})

	const autoCompleteOptions = useMemo(
		() =>
			querySuggestions.map((suggestion) => ({
				...suggestion,
				label: suggestion.query,
			})),
		[querySuggestions],
	)

	const clearSearchValue = useCallback((): void => {
		setQuery('')
		setLocalQueryValue('')
		track('')
	}, [setQuery, track])

	const handleNavigateAwayFromSearch = useCallback<BlockerFunction>(
		(history: Transition) => {
			if (
				!history.nextLocation.pathname.includes('search') &&
				location.pathname.includes('search') &&
				localQueryValue.length > 0
			) {
				clearSearchValue()
			}

			return false
		},
		[location.pathname, localQueryValue.length, clearSearchValue],
	)

	useBlocker(handleNavigateAwayFromSearch)

	/**
	 * kicks off the search
	 * @param searchVal value to search on
	 * @returns nothing
	 */
	const search = useCallback(
		(searchVal: string): void => {
			const matchingFacet = nonExclusiveOfferFacets?.find(
				(item) => item.value.toLowerCase() === searchVal.toLowerCase(),
			)
			// Auto-select category if search matches category exactly
			if (matchingFacet && hasDiscounts && b4b_searchAutoSelectCat) {
				clearSearchValue()
				navigate(getDealCategorySearchUrl(matchingFacet.value))
			} else {
				clearFacetValues('offers', 'categoryNames')
				setQuery(searchVal)
				navigate(`${routesMap.search}${generateSearchUrl({ q: searchVal })}`)
			}

			track(searchVal)
			document.getElementById('searchInput')?.blur()
		},
		[
			b4b_searchAutoSelectCat,
			clearFacetValues,
			clearSearchValue,
			hasDiscounts,
			navigate,
			nonExclusiveOfferFacets,
			setQuery,
			track,
		],
	)

	// /**
	//  * Trigger focus on page render
	//  */
	const onKeyPressed = (e): void => {
		if (e.key === 'Enter') {
			search(e.target.value)
		}
	}

	const onSearchIconClick = useCallback(
		(e): void => {
			e.preventDefault()
			search(localQueryValue)
		},
		[localQueryValue, search],
	)

	const isClearIconVisible = localQueryValue.length > 0

	const endAdornment: JSX.Element = useMemo(
		() => (
			<InputAdornment position='end'>
				<div className={styles['icons']}>
					{isClearIconVisible && (
						<IconButton
							aria-label='Clear Search'
							id='button-clear-search'
							size='large'
							onClick={() => clearSearchValue()}
						>
							<Close />
						</IconButton>
					)}
					<IconButton aria-label='Search' edge='end' id='button-search' size='large' onClick={onSearchIconClick}>
						<Search />
					</IconButton>
				</div>
			</InputAdornment>
		),
		[clearSearchValue, isClearIconVisible, onSearchIconClick],
	)

	const handleAutocompleteChange = (_: any, newValue: { query: string } | null | string) => {
		if (!newValue) return
		const searchValue = typeof newValue === 'string' ? newValue : newValue?.query || ''
		if (searchValue) search(searchValue)
	}

	return (
		<div className={styles['container']}>
			<div className={styles['input-container']}>
				{b4b_search_autocomplete ? (
					<Autocomplete
						className={styles['autocomplete']}
						disabled={!!isLoading}
						freeSolo
						id={id ?? 'searchInput'}
						inputValue={localQueryValue}
						loading={querySuggestionsLoading}
						options={autoCompleteOptions}
						value={localQueryValue}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder='What can we help you find?'
								variant='standard'
								InputProps={{
									...params.InputProps,
									'aria-label': 'What can we help you find?',
									className: styles['search-bar'],
									disableUnderline: true,
									endAdornment: endAdornment,
								}}
								onKeyDown={onKeyPressed}
							/>
						)}
						onChange={handleAutocompleteChange}
						onInputChange={(_, newValue) => {
							setLocalQueryValue(newValue)
							if (closeMenu) closeMenu()
						}}
					/>
				) : (
					<Input
						autoComplete='cs-search'
						className={styles['search-bar']}
						disabled={!!isLoading}
						disableUnderline
						endAdornment={endAdornment}
						id={id ?? 'searchInput'}
						name='Benefits and Offers Search'
						placeholder='What can we help you find?'
						value={localQueryValue}
						inputProps={{
							'aria-label': 'What can we help you find?',
						}}
						onKeyDown={onKeyPressed}
						onChange={(e) => {
							e.preventDefault()

							setLocalQueryValue(e.target.value)
							if (closeMenu) closeMenu()
						}}
					/>
				)}
			</div>
			<Button
				aria-label={'Cancel Search'}
				className={styles['cancel']}
				color='error'
				variant='text'
				onClick={onCancelClick}
			>
				CANCEL
			</Button>
		</div>
	)
})

SearchBox.displayName = 'SearchBox'

export default SearchBox
