import { BenefitPlanV2 } from 'store/benefit-plans/types'

import { RelatedProductEntities } from '../types'

/**
 * Comparator for status
 */

export function compareByRecommendationRank(a: RelatedProductEntities, b: RelatedProductEntities): number {
	const aProducts = [...a.related.products.values()]
	const bProducts = [...b.related.products.values()]

	const recommendationRank = prioritizeRecommendation(
		aProducts.find((p) => p.recommendation),
		bProducts.find((p) => p.recommendation),
	)

	return recommendationRank
}

export function prioritizeRecommendation(a?: BenefitPlanV2 | null, b?: BenefitPlanV2 | null): number {
	const aRank = a?.recommendation?.rank ?? 0
	const bRank = b?.recommendation?.rank ?? 0

	if (!aRank && bRank) {
		return 1
	}

	if (aRank && !bRank) {
		return -1
	}

	if (!aRank && !bRank) {
		return 0
	}

	return aRank - bRank
}
