import { useCallback, useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { ConfiguredIndices } from 'state/slices/search'
import { useSearchSlice, vanillaStore } from 'state/store'

import {
	FACET_URL_MAPPINGS,
	generateSearchUrl,
	getFacetNameFromUrl,
	getUrlParamFromFacet,
	parseSearchUrl,
} from '../utils/url-mapping'

type SearchURLOptions = {
	indices?: ConfiguredIndices[]
	writeToURL?: boolean
}

// This hook returns a callback to initialize store from URL
export function useInitFromURL(indices: ConfiguredIndices[] = []) {
	const [searchParams] = useSearchParams()
	const {
		indices: storeIndices,
		query: storeQuery,
		setFacets,
		setQuery,
	} = useSearchSlice((s) => ({
		indices: s.search.indices,
		query: s.search.query,
		setFacets: s.search.setFacets,
		setQuery: s.search.setQuery,
	}))

	return useCallback(() => {
		const config = parseSearchUrl(searchParams)

		if (config.q && config.q !== storeQuery) {
			setQuery(config.q.toString())
		}

		indices.forEach((index) => {
			const indexRefinements: Record<string, string[]> = {}
			const storeIndexRefinements = storeIndices[index]?.params.facets || {}

			FACET_URL_MAPPINGS.filter((mapping) => mapping.indexId === index).forEach((mapping) => {
				const urlValue = searchParams.get(mapping.urlParam)
				if (urlValue) {
					const facetName = getFacetNameFromUrl(mapping.urlParam, index)
					if (facetName) {
						indexRefinements[facetName] = urlValue.split(',')
					}
				}
			})

			// only update facets if they have changed
			if (
				Object.keys(indexRefinements).length &&
				JSON.stringify(indexRefinements) !== JSON.stringify(storeIndexRefinements)
			) {
				setFacets(index, indexRefinements)
			}
		})
	}, [searchParams, storeQuery, indices, setQuery, storeIndices, setFacets])
}

// This hook handles ongoing sync between store and URL
export function useSearchURLSync({ indices = [], writeToURL = true }: SearchURLOptions = {}) {
	const { pathname } = useLocation()
	const [, setSearchParams] = useSearchParams()

	// Sync store to URL
	const syncToUrl = useCallback(() => {
		if (!writeToURL) return

		const query = vanillaStore.getState().search.query
		const urlConfig: Record<string, number | string> = {}

		// Only add query if it exists
		if (query) {
			urlConfig.q = query
		}

		indices.forEach((index) => {
			const allIndexFacetFilters = vanillaStore.getState().search.indices[index]?.params.facets || {}

			Object.entries(allIndexFacetFilters).forEach(([facetName, values]) => {
				const urlParam = getUrlParamFromFacet(facetName, index)
				if (urlParam && values.length) {
					urlConfig[urlParam] = values.join(',')
				}
			})
		})

		// Always update URL, even if empty - this ensures categories are cleared
		if (pathname.includes('search')) setSearchParams(generateSearchUrl(urlConfig))
	}, [writeToURL, indices, pathname, setSearchParams])

	// Subscribe to store changes
	useEffect(() => {
		if (!writeToURL) return

		const unsubscribe = vanillaStore.subscribe(
			(state) => ({
				query: state.search.query,
				refinements: state.search.indices,
			}),
			() => setTimeout(() => syncToUrl(), 0),
			{
				equalityFn: (prev, next) => JSON.stringify(prev) === JSON.stringify(next),
				fireImmediately: false,
			},
		)

		return () => unsubscribe()
	}, [writeToURL, syncToUrl])
}
