import { BenefitPlanV2 } from 'store/benefit-plans/types'

export const filterByMaxAllowedRecommendations = (list: BenefitPlanV2[]): BenefitPlanV2[] => {
	// Get max number of recommended products, grouping by product type
	const allProductTypes = list.map((plan) => plan.benefitPlanType)
	const uniqueAllProductTypes = [...new Set(allProductTypes)]

	// max number of recommended products is 4, but at least 1
	const numOfRecommendations = Math.min(4, Math.max(1, Math.floor(uniqueAllProductTypes.length / 2)))

	// Group by product type
	const plansWithRecommendationContent = list.filter((plan) => !!plan.recommendation)
	const orderedProductTypes = plansWithRecommendationContent.map((plan) => plan.benefitPlanType)
	const uniqueProductTypes = [...new Set(orderedProductTypes)]

	// Slice the product types to only allow the given max number
	const recommendedProductTypes = uniqueProductTypes.slice(0, numOfRecommendations)

	// Filter by recommended product types
	const showAsRecommended = plansWithRecommendationContent.filter((plan) =>
		recommendedProductTypes.includes(plan.benefitPlanType),
	)

	const showAsRecommendedIds = showAsRecommended.map((plan) => plan.benefitPlanId)
	const rest = list
		.filter((plan) => !showAsRecommendedIds.includes(plan.benefitPlanId))
		.map((plan) => {
			const { recommendation, ...planWithoutRecommendation } = plan

			return planWithoutRecommendation
		})

	return [...showAsRecommended, ...rest]
}
