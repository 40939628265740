import { drawerClasses } from '@mui/material/Drawer'
import { alpha, CssVarsThemeOptions } from '@mui/material/styles'

import { paper } from '../../css'

// ----------------------------------------------------------------------

export function drawer(): CssVarsThemeOptions['components'] {
	return {
		MuiDrawer: {
			styleOverrides: {
				root: ({ ownerState, theme }) => ({
					...(ownerState.variant === 'temporary' && {
						[`& .${drawerClasses.paper}`]: {
							...paper({ theme }),
							...(ownerState.anchor === 'left' && {
								boxShadow: `40px 40px 80px -8px ${alpha(theme.palette.grey[500], 0.24)}`,
							}),
							...(ownerState.anchor === 'right' && {
								boxShadow: `-40px 40px 80px -8px ${alpha(theme.palette.grey[500], 0.24)}`,
							}),
						},
					}),
				}),
			},
		},
	}
}
