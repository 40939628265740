import { useQuery } from '@tanstack/react-query'
import { getFacetsQuery, getMemberExclusiveFacetsQuery } from 'api/search/queryOptions/facets'
import { MegaMenuItem } from 'components/app-header/components/mega-menu/types'
import { useGetPrefixedIndex } from 'config/search-config'
import { getDealCategorySearchUrl } from 'utils/url'

import { routesMap } from '../routes/routes-map'
import { useTrackDetailedPageEvent } from './analytics'

const useMegaMenuDeals = () => {
	const getIndexName = useGetPrefixedIndex()
	const { data: memberExclusiveOfferFacets = [] } = useQuery(
		getMemberExclusiveFacetsQuery({}, { algoliaIndexName: getIndexName('offers') }),
	)

	const { data: offerFacets = [] } = useQuery(
		getFacetsQuery('offers', {}, { algoliaIndexName: getIndexName('offers') }),
	)
	const track = useTrackDetailedPageEvent()

	const formatDealsFacetIntoMenuItem = (facet): MegaMenuItem => {
		return {
			href: getDealCategorySearchUrl(facet.value),
			label: facet.value,
			onClick: () => {
				track({
					actionType: 'click',
					elementDetails: facet.value,
					elementName: 'mega menu deals category cta',
					elementType: 'button',
				})
			},
		}
	}
	const dealsMenuItems = offerFacets.map(formatDealsFacetIntoMenuItem)

	const exclusiveDealsMenuItems = memberExclusiveOfferFacets.map(formatDealsFacetIntoMenuItem)

	const supplementalDealsItems: MegaMenuItem[] = [
		{
			href: routesMap.deals.base,
			label: 'Deals Home',
			onClick: () => {
				track({
					actionType: 'click',
					elementName: 'mega menu deals home cta',
					elementType: 'button',
				})
			},
		},
		{
			href: routesMap.deals.travel.path,
			label: 'Exclusive Travel Deals',
			onClick: () => {
				track({
					actionType: 'click',
					elementDetails: 'mega menu item',
					elementName: 'Exclusive Travel Deals',
					elementType: 'hyperlink',
				})
			},
		},
		...exclusiveDealsMenuItems,
	]

	return { dealsMenuItems, supplementalDealsItems }
}
export default useMegaMenuDeals
