import { queryOptions } from '@tanstack/react-query'
import { algoliaClient } from 'config/search-config'
import { ModularMarketingContent } from 'store/benefit-plans/types'

import { searchKeys } from '../keys'
import { buildAlgoliaFilters } from '../utils'

export function getMarketingContentByIdQuery(id: string, indexName: string) {
	return queryOptions({
		queryFn: async (): Promise<ModularMarketingContent> => {
			const response = await algoliaClient.searchForHits<ModularMarketingContent>({
				requests: [
					{
						filters: buildAlgoliaFilters({ objectID: id }),
						indexName,
						type: 'default',
					},
				],
			})

			const hit = response.results[0].hits[0]
			if (!hit) throw new Error('Marketing content not found')

			return hit
		},
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: searchKeys.marketingContent.byId(id),
	})
}
