import { accordionClasses } from '@mui/material/Accordion'
import { accordionSummaryClasses } from '@mui/material/AccordionSummary'
import { CssVarsThemeOptions } from '@mui/material/styles'
import { typographyClasses } from '@mui/material/Typography'

// ----------------------------------------------------------------------

export function accordion(): CssVarsThemeOptions['components'] {
	return {
		MuiAccordion: {
			styleOverrides: {
				root: ({ theme }) => ({
					[`&.${accordionClasses.disabled}`]: {
						backgroundColor: 'transparent',
					},
					[`&.${accordionClasses.expanded}`]: {
						backgroundColor: theme.palette.background.paper,
						// boxShadow: theme.customShadows.z8,
						borderRadius: theme.shape.borderRadius,
					},
					backgroundColor: 'transparent',
				}),
			},
		},
		MuiAccordionSummary: {
			styleOverrides: {
				expandIconWrapper: {
					color: 'inherit',
					flexShrink: 0,
				},
				root: ({ theme }) => ({
					[`&.${accordionSummaryClasses.disabled}`]: {
						[`& .${typographyClasses.root}`]: {
							color: 'inherit',
						},
						color: theme.palette.action.disabled,
						opacity: 1,
					},
					paddingLeft: theme.spacing(2),
					paddingRight: theme.spacing(1),
				}),
			},
		},
	}
}
