import { useFlags } from 'launchdarkly-react-client-sdk'
import { ExperienceConfig } from 'utils/launchdarkly/types'

// https://app.launchdarkly.com/projects/wrp/flags/b4b_19026_experience_config/targeting?env=prod&env=int&env=uat&selected-env=int
export const useExperienceConfig = (): ExperienceConfig => {
	const { b4b_19026_experience_config: experienceTest } = useFlags()

	return {
		...experienceTest,
	}
}
