// make new snackbar slice
// make new snackbar component, put it next to the global dialog component

import { AlertStatus } from 'components/snackbar/snackbar'
import { ZustandStore } from 'state/store'
import { createZustandSlice } from 'state/utils/create-slice'
export type SnackbarSlice = SnackbarSliceActions & SnackbarSliceData

export type SnackbarSliceActions = {
	closeSnackbar: () => void
	openSnackbar: (message: string, status: AlertStatus) => void
}

export type SnackbarSliceData = {
	message: string
	open: boolean
	status: 'error' | 'info' | 'success' | 'warning'
}

const initialState: SnackbarSliceData = {
	message: '',
	open: false,
	status: 'info',
}

export const createSnackbarSlice = createZustandSlice<ZustandStore, SnackbarSliceData, SnackbarSliceActions>(
	'snackbar',
	(set) => ({
		closeSnackbar: () =>
			set((state) => {
				state.message = ''
				state.open = false
			}, 'closeSnackbar'),
		openSnackbar: (message: string, status: AlertStatus) =>
			set((state) => {
				state.message = message
				state.open = true
				state.status = status
			}, 'openSnackbar'),
	}),
	initialState,
)
