import { useGetUserRecommendation } from 'api/user-insights'
import { PipeFuncReturn } from 'hooks/user-data'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { prioritizeRecommendation } from 'utils/benefit-plans/product-type/sort/compareByRecommendationRank'
import { createCompositeComparator } from 'utils/sort'

import { filterByMaxAllowedRecommendations } from '../../feature/survey/utils/filterByMaxAllowedRecommendations'
import { useIsDecisionSupportEnabledForClient } from './use-is-decision-support-enabled-for-client'

export const usePipeRecommendationData = (): PipeFuncReturn<BenefitPlanV2[][]> => {
	const recommendationData = useGetUserRecommendation()

	const isEnabled = useIsDecisionSupportEnabledForClient()

	return {
		apply: (listsOfPlans) => {
			if (!isEnabled) return listsOfPlans

			return listsOfPlans
				.map((list) =>
					list
						.map((plan) => {
							const recommendation = recommendationData.data?.productRecommendations.find(
								(x) => x.benefitProductType === plan.benefitPlanType,
							)

							return {
								...plan,
								recommendation,
							}
						})
						.sort(createCompositeComparator([prioritizeRecommendation])),
				)
				.map(filterByMaxAllowedRecommendations)
		},
		subscription: {
			data: recommendationData.data,
			isFetched: isEnabled ? recommendationData.isFetched : true,
			isLoading: recommendationData.isFetching,
		},
	}
}
