import EnrollmentEvent from './enrollment-event'

export const PET_PLAN_ID = 374
export const PET_MISSING_ZIP = 'zip code is required'

export enum PetSpecies {
	CAT = 'Feline',
	DOG = 'Canine',
	NONE = '',
}

export interface ElectionPetData {
	isPushed: boolean
	pendingTotalCost: number
	pet: PetElectionData
	pushedTotalCost: number
}

export interface EnrolledPet extends PetSelectionData {
	age?: string
	amount: number
	annualAmount?: number
	breed?: string
	breedCode?: number
	createdOn?: string
	hasWellness: boolean
	isCancelled: boolean
	isCovered: boolean
	isPushed?: boolean
	modifiedOn?: string
	percentBack?: number
	petGuid: string
	petId?: number
	policyNumber?: string
	programEnrollment?: any // TODO: find proper type
	programEnrollmentId?: string
	quoteId?: string
	species: string
	statusCode?: string
	workerId?: string
}

export interface EnrolledPetData {
	effectiveDate?: string
	frequency: string
	mappedPets: Array<EnrolledPet>
	pendingTotalCost: number
	pets: Array<EnrolledPet>
	pushedTotalCost: number
	splitPets: SplitPets
}

export interface PetBreed {
	coat: string
	code?: string
	id: string
	name: string
	size: string
	type: string
}

export interface PetData extends PetSelectionData {
	age?: string
	amount?: number
	breed?: PetBreed
	breedCode?: null | number
	coverageAmount?: number
	dateOfBirth?: string
	frequency: string
	hasWellness?: boolean
	isCancelled?: boolean
	isCovered?: boolean
	rates: PetRate[]
	species: PetSpecies
}

export interface PetElectionData {
	age: number
	amount: number
	annualAmount: number
	coverageCode: string
	coverageName: string
	createdDate?: string
	isPushed?: boolean
	modifiedDate?: string
	name: string
	nationwideId: string
	nationwidePetBreedId: number
	nationwidePetId: string
	percentBack?: number
	petId?: number
	policyNumber?: string
	quoteId?: string
}

export interface PetEnrollmentSave {
	enrollmentId: string
	pets: Pick<PetResponse, 'coverage' | 'petGuid'>[]
	postalCode?: number | string
	profileId: number
	programEnrollmentId: string
	statusCode: string
}

export interface PetEnrollmentSaveV2 {
	benefitPlanId: number
	confirmedDisclaimer: string
	effectiveDate?: Date | string
	enrollmentEvent: EnrollmentEvent
	enrollmentId: string
	isSingleProductFlow: boolean
	pets: Pick<PetResponse, 'coverage' | 'petGuid'>[]
	postalCode?: number | string
	programEnrollmentId: string
	programId: number
	statusCode: string
}

export interface PetPostRes {
	disclaimer: string
	messageCode: string
	pets: PetResponse[]
	userMessage: string
}

export interface PetRate {
	amount: number
	annualAmount?: number
	baseRate?: number
	coverage: string
	coverageName: string
	discount?: number
	frequency: string
	paymentType?: string
	percentBack?: number
}

export type PetResponse = PetData & PetRate

export interface PetSelectionData {
	coverage: string
	coverageName?: string
	effectiveDate?: string
	petGuid: string
	petName: string
}
export interface SplitPets {
	existing: Array<EnrolledPet>
	newlyEnrolled: Array<EnrolledPet>
}

export const petEnrollmentsInitState: EnrolledPetData = {
	frequency: '',
	mappedPets: [],
	pendingTotalCost: 0,
	pets: [],
	pushedTotalCost: 0,
	splitPets: { existing: [], newlyEnrolled: [] },
}
