import { SearchFilters } from 'api/search/types/search'

import { CsAlgoliaSearchConfigDto } from './types/algolia'
import { FeatureLevel } from './types/feature-level'

export const searchKeys = {
	all: ['search'] as const,
	facets: {
		all: () => [...searchKeys.all, 'facets'] as const,
		memberExclusive: () => [...searchKeys.facets.all(), 'member-exclusive'] as const,
		nonMemberExclusive: () => [...searchKeys.facets.all(), 'non-member-exclusive'] as const,
	},
	marketingContent: {
		all: () => [...searchKeys.all, 'marketing-content'] as const,
		byId: (id: string) => [...searchKeys.marketingContent.all(), 'by-id', id] as const,
	},
	offers: {
		// allows cache invalidation of all active offers
		_activeBase: () => [...searchKeys.offers._all(), 'active'] as const,
		_all: () => [...searchKeys.all, 'offers'] as const,
		active: (filters?: SearchFilters) => [...searchKeys.offers._activeBase(), filters] as const,
		available: {
			_all: () => [...searchKeys.offers._all(), 'available'] as const,
			byIds: (ids: string[]) => [...searchKeys.offers.available._all(), 'by-ids', ids] as const,
		},
		card: {
			_all: () => [...searchKeys.offers._all(), 'card'] as const,
			active: (filters?: SearchFilters) => [...searchKeys.offers.active(filters), 'card'] as const,
			byId: (id: string, filters?: SearchFilters) => [...searchKeys.offers.card.active(filters), 'by-id', id] as const,
			byIds: (ids: string[], filters?: SearchFilters) =>
				[...searchKeys.offers.card.active(filters), 'by-ids', ids] as const,
			byLevel: (level: FeatureLevel | FeatureLevel[], filters?: SearchFilters, page?: number) =>
				[...searchKeys.offers.card.active(filters), 'by-level', level, page] as const,
		},
		detail: {
			_all: () => [...searchKeys.offers._all(), 'detail'] as const,
			active: (filters?: SearchFilters) => [...searchKeys.offers.active(filters), 'detail'] as const,
			byId: (id: string, filters?: SearchFilters) =>
				[...searchKeys.offers.detail.active(filters), 'by-id', id] as const,
			byIds: (ids: string[], filters?: SearchFilters) =>
				[...searchKeys.offers.detail.active(filters), 'by-ids', ids] as const,
		},
		querySuggestions: {
			_all: () => [...searchKeys.offers._all(), 'query-suggestions'] as const,
			byQuery: (query: string) => [...searchKeys.offers.querySuggestions._all(), 'by-query', query] as const,
		},
	},
	search: {
		all: () => [...searchKeys.all, 'search-results'] as const,
		byIndex: (indexName: string) => [...searchKeys.search.all(), indexName] as const,
		withParams: (params: { config: { algoliaIndexName: string } & Partial<CsAlgoliaSearchConfigDto>; index: string }) =>
			[...searchKeys.search.byIndex(params.index), params.config] as const,
	},
} as const
