import { PublicContext } from 'pages/register/public-context'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'
import { isLoggedInSelector } from 'store/user/selectors'

// Purpose of this component is to redirect to the home page if the user is logged in
const PublicRoute = (): JSX.Element => {
	const isLoggedIn: boolean = useSelector(isLoggedInSelector)

	if (isLoggedIn) {
		return <Navigate replace to='/home' />
	}

	return (
		<PublicContext.Provider value={{}}>
			<Outlet />
		</PublicContext.Provider>
	)
}

export default PublicRoute
