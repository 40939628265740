import { Environments } from 'utils/env'

import { SiteEnvironment } from './types'

const options = [
	{ label: 'Int', value: 'int' },
	{ label: 'Dev', value: 'dev' },
	// { value: 'test', label: 'UAT' },
	// { value: '', label: 'Prod' },
]

const tenantOptions = [
	{ label: 'Wawa', value: 'wawa' },
	{ label: 'Advantage Solutions', value: 'advantagesolutions' },
	{ label: 'Adventist Midwest Health (AMITA)', value: 'amita' },
	{ label: 'Akumin', value: 'akumin' },
	{ label: 'Alliance Healthcare', value: 'ahcs' },
	{ label: 'Alion Science and Technology Corporation (ALION)', value: 'alion' },
	{ label: 'Atlanticare', value: 'adp-atlanticare' },
	{ label: 'Aptiv', value: 'aptiv' },
	{ label: 'Ascension - Benefits Eligible', value: 'myascension' },
	{ label: 'Community Medical Centers', value: 'cmc' },
	{ label: 'CVS Health', value: 'cvs' },
	{ label: 'Dovel Technologies', value: 'guidehousedigital' },
	{ label: 'EPRI', value: 'epri' },
	{ label: 'Ferguson', value: 'ferguson' },
	{ label: 'Franklin Electric', value: 'adp-franklinelectric' },
	{ label: 'Ikea', value: 'ikea' },
	{ label: 'IU Health', value: 'iuhealth' },
	{ label: 'Mass General Brigham', value: 'mgbrigham' },
	{ label: 'Michigan State University (MSU)', value: 'msu' },
	{ label: 'Moffitt Cancer Center', value: 'moffitt' },
	{ label: 'Ochsner Health', value: 'ochsner' },
	{ label: 'Orix', value: 'orix' },
	{ label: 'Paychex', value: 'paychex' },
	{ label: 'Pfizer', value: 'pfizer' },
	{ label: 'Raymond Corp', value: 'adp-raymond' },
	{ label: 'Salt Lake County', value: 'slcounty' },
	{ label: 'State of Illinois', value: 'ms-soi' },
	{ label: "Texas Woman's University", value: 'twu' },
	{ label: 'Tillys', value: 'adp-tillys' },
	{ label: 'Tower Health', value: 'towerhealth' },
	{ label: 'Toyota Motor North America', value: 'ms-toyota' },
	{ label: 'Temple Health (TUHS)', value: 'temple' },
	{ label: 'Temple University (TUEDU)', value: 'tuedu' },
	{ label: 'University of Idaho', value: 'ms-idaho' },
	{ label: 'University of Pennsylvania Health System', value: 'upenn-uphs' },
	{ label: 'University of Pittsburgh', value: 'universityofpittsburgh' },
	{ label: 'University of Rochester', value: 'universityofrochester' },
	{ label: 'University of Utah Health', value: 'uuhc' },
	{ label: 'CS Friends & Family', value: 'csfriendsfamily' },
	{ label: 'Roche', value: 'moreusrochebenefits' },
]

export function PreviewEnvironmentSelectorForm({ onSubmit }: { onSubmit: (site: SiteEnvironment) => void }) {
	return (
		<form
			style={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				gap: '1rem',
				height: '100vh',
				justifyContent: 'center',
			}}
			onSubmit={(e) => {
				e.preventDefault()

				const data = new FormData(e.target as HTMLFormElement)

				const object: {
					env: string
					tenant: string
				} = {
					env: '',
					tenant: '',
				}
				data.forEach((value, key) => (object[key] = value))

				onSubmit({
					env: object.env as Environments,
					subdomain: object.tenant,
				})
			}}
		>
			<div style={{ display: 'flex', gap: '1rem' }}>
				<label htmlFor='env'>Environment</label>
				<select id='env' name='env'>
					{options.map((v) => (
						<option key={v.value} value={v.value}>
							{v.label}
						</option>
					))}
				</select>
			</div>
			<div style={{ display: 'flex', gap: '1rem' }}>
				<label htmlFor='tenant'>Tenant</label>
				<select id='tenant' name='tenant'>
					{tenantOptions.map((v) => (
						<option key={v.value} value={v.value}>
							{v.label}
						</option>
					))}
				</select>
			</div>
			<button type='submit'>Submit</button>
		</form>
	)
}
