import { useQuery } from '@tanstack/react-query'
import { BenefitPlansService } from 'api/benefit-elections'
import { formatBenefitPlan } from 'api/query-hooks/utils'
import { useMarketingContentById } from 'api/search/hooks/useMarketingContent'
import { sortBenefitPlanGroup } from 'feature/benefit-plan/utils'
import { applyAccessoryPlanSorting } from 'feature/benefit-plan/utils/accessory'
import { usePipeSortingByProductType } from 'hooks/benefit-plans/product-type/use-sorting-by-product-type'
import { useEnrollmentsForBenefit } from 'hooks/elections'
import { useAppSelector } from 'hooks/redux'
import { usePipeRecommendationData } from 'hooks/user-data/usePipeRecommendationData'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { useMemo } from 'react'
import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { EnrollmentStatusUtilities } from 'utils/benefit-plans'
import { pipe } from 'utils/pipe'

export const queryKeys = {
	all: (orgId: string) => ['benefit-plans', orgId] as const,
}

export const useEligiblePlansQuery = (enabled: boolean, organizationId: string) => {
	const hasVoluntaryBenefits = useAppSelector((s) => s.site.siteInfo.properties.hasVoluntaryBenefits)

	return useQuery({
		enabled: enabled && hasVoluntaryBenefits,
		queryFn: () => BenefitPlansService.getEligiblePlans(organizationId),
		queryKey: queryKeys.all(organizationId),
	})
}

export const useBenefitPlans = () => {
	const orgId = useAppSelector((s) => s.site.siteInfo.organizationId)

	const oeWindow = useAppSelector((s) => s.benefitPlans.oeWindow)
	const reqStates = useAppSelector((s) => s.benefitPlans.requestStates)

	const {
		apply,
		subscription: { isLoading },
	} = usePipeRecommendationData()

	const {
		apply: productTypeSort,
		subscription: { isLoading: corestreamProductLoading },
	} = usePipeSortingByProductType()

	const eligiblePlansQuery = useEligiblePlansQuery(!!orgId, orgId)

	// Wait for the OE window and the eligible plans to load before processing the data
	const processedData = useMemo(() => {
		if (!eligiblePlansQuery.data || reqStates.oeWindowsFetchStatus !== 'success') {
			return []
		}

		const formattedPlans =
			eligiblePlansQuery.data.eligiblePlans.map((bp) =>
				formatBenefitPlan({ bp, effectiveDates: eligiblePlansQuery.data?.benefitPlanEffectiveDates, oeWindow }),
			) ?? []

		return pipe(apply, productTypeSort)([formattedPlans])[0] as BenefitPlanV2[]
	}, [eligiblePlansQuery.data, oeWindow, reqStates.oeWindowsFetchStatus, apply, productTypeSort])

	if (!processedData) {
		return {
			...eligiblePlansQuery,
			data: [],
			isFetching: true,
			isLoading: true,
			isSuccess: false,
		}
	}

	return {
		...eligiblePlansQuery,
		data: processedData,
		isFetching: isLoading || corestreamProductLoading || eligiblePlansQuery.isFetching,
		isLoading: eligiblePlansQuery.isLoading,
	}
}

export const useBenefitPlansById = (id?: number) => {
	const query = useBenefitPlans()
	const benefitPlan = query.data?.find((benefitPlan) => benefitPlan.benefitPlanId === id) ?? null

	const customMCId = sessionStorage.getItem(`custom-mc-${id}`)
	const customMC = useMarketingContentById(customMCId ?? '', { enabled: !!customMCId })

	const isPreviewingEffectiveMC = customMCId === benefitPlan?.modularMarketingContent?.benefitPlanMarketingContentId

	const isPreview = !!customMCId

	return {
		...query,
		data: benefitPlan
			? ({
					...benefitPlan,
					modularMarketingContent: customMC?.data ?? benefitPlan.modularMarketingContent,
			  } as BenefitPlanV2)
			: null,
		data_Original: query.data,
		isFetching: query.isFetching || customMC.isFetching,
		isLoading: query.isLoading || customMC.isLoading,
		meta: {
			isPreview,
			isPreviewingEffectiveMC,
		},
	}
}

export const useGroupedBenefitPlans = (benefitPlanId?: number) => {
	const query = useBenefitPlans()

	const benefitPlans = query.data ? applyAccessoryPlanSorting(query.data) : undefined

	const benefitPlan = benefitPlans?.find((plan) => plan.benefitPlanId === benefitPlanId)

	const groupedPlans = benefitPlans
		?.filter(
			(plan) =>
				plan.benefitPlanId === benefitPlan?.benefitPlanId ||
				plan.benefitPlanId === benefitPlan?.benefitPlanGroupPrimaryId ||
				plan.benefitPlanGroupPrimaryId === benefitPlan?.benefitPlanId ||
				(benefitPlan?.benefitPlanGroupPrimaryId &&
					plan.benefitPlanGroupPrimaryId === benefitPlan?.benefitPlanGroupPrimaryId),
		)
		.toSorted(sortBenefitPlanGroup(benefitPlan))

	const isPrimaryPlan = groupedPlans?.some((plan) => plan.benefitPlanGroupPrimaryId === benefitPlanId)

	const isGrouped = Boolean(groupedPlans && groupedPlans.length > 1)

	const isBenefitPlanGroupEditing = useIsBenefitPlanGroupEditing(groupedPlans?.[0]?.benefitPlanId) && isGrouped

	return {
		...query,
		benefitPlan: benefitPlan ?? null,
		data: benefitPlans,
		groupedPlans,
		isBenefitPlanGroupEditing,
		isGrouped,
		isPrimaryPlan,
	}
}

const useIsBenefitPlanGroupEditing = (primaryBenefitPlanId?: number) => {
	const { latestEnrollment } = useEnrollmentsForBenefit(String(primaryBenefitPlanId))
	const cartItems = useAppSelector((s) => s.cart.items)
	const plans = useBenefitPlans()

	const accessoryPlans = useMemo(
		() => plans.data?.filter((plan) => plan.benefitPlanGroupPrimaryId === primaryBenefitPlanId) ?? [],
		[plans.data, primaryBenefitPlanId],
	)

	const primaryCartItem = cartItems.find((item) => item.benefitPlanId === primaryBenefitPlanId)
	const haveAllAccessoriesBeenEdited = accessoryPlans.every((plan) =>
		cartItems.find((item) => item.benefitPlanId === plan.benefitPlanId && item.statusCode !== EnrollmentStatus.LEAVED),
	)

	const isBenefitPlanEditing =
		EnrollmentStatusUtilities.isEnrolled(latestEnrollment?.statusCode) &&
		primaryCartItem?.statusCode === EnrollmentStatus.NEW &&
		!haveAllAccessoriesBeenEdited

	return isBenefitPlanEditing
}
