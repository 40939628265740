import { useAppSelector } from 'hooks/redux'
import { useEffectOnceWithRef } from 'hooks/utils'
import LogRocket from 'logrocket'
import { useEffect, useState } from 'react'
import { myProfileSelector } from 'store/user/selectors'
import { useGetEnv } from 'utils/env'
import { LocalStore } from 'utils/utils'

import { requestSanitizer, responseSanitizer } from '../../../../hooks/logRocket/networkSanitizer'

const useLogRocketAppId = (): string => {
	const env = useGetEnv()
	switch (env()) {
		case 'dev':
			return import.meta.env.VITE_LOGROCKET_APP_ID_DEV
		case 'int':
			return import.meta.env.VITE_LOGROCKET_APP_ID_INT
		case 'test':
			return import.meta.env.VITE_LOGROCKET_APP_ID_UAT
		default:
			return import.meta.env.VITE_LOGROCKET_APP_ID
	}
}

type LogRocketComponentProps = {
	isLoggedIn: boolean
}

/**
 * Component that initializes LogRocket and adds a hidden div with the session URL
 */
export function LogRocketComponent({ isLoggedIn }: LogRocketComponentProps) {
	const user = useAppSelector(myProfileSelector)
	const employerAccountCode = useAppSelector((s) => s.site.siteInfo.properties.ppdsTenantCode)
	const orgId = useAppSelector((s) => s.site.siteInfo.organizationId)
	const orgUnitId = useAppSelector((s) => s.site.siteInfo.organizationUnitId)
	const [logRocketInitialized, setLogRocketInitialized] = useState(false)
	const [sessionUrl, setSessionUrl] = useState('')

	const logRocketAppId = useLogRocketAppId()

	// Initialize LogRocket
	useEffectOnceWithRef(logRocketAppId && import.meta.env.VITE_REACT_APP_VERSION, () => {
		LogRocket.init(logRocketAppId, {
			network: {
				requestSanitizer,
				responseSanitizer,
			},
			release: import.meta.env.VITE_REACT_APP_VERSION,
			// log rocket ingest proxy
			serverURL: 'https://cdp.corestream.com/i',
		})

		// Get session URL after initialization
		setLogRocketInitialized(true)
	})

	// Identify user when logged in
	useEffect(() => {
		if (logRocketInitialized && !sessionUrl) {
			LogRocket.getSessionURL((url) => {
				setSessionUrl(url)
			})
		}

		if (isLoggedIn && user?.userId && logRocketInitialized) {
			LogRocket.identify(user.userId, {
				anonymous_id: LocalStore.get<string>('ajs_anonymous_id') ?? '',
				email: user.email,
				employerAccountCode: employerAccountCode ?? '',
				name: `${user.firstName} ${user.lastName}`,
				organizationId: orgId ?? '',
				organizationUnitId: orgUnitId ?? '',
				userId: user.userId,
				workerId: user.workerId,
			})
		}
	}, [
		employerAccountCode,
		isLoggedIn,
		logRocketInitialized,
		user.email,
		user.employeeId,
		user.firstName,
		user.lastName,
		user.userId,
		user.workerId,
		orgId,
		orgUnitId,
		sessionUrl,
	])

	// Render hidden span with session URL
	return <span className='hidden' data-session-url={sessionUrl} id='logrocket-session-url' />
}
