import { useNewHire } from 'api/enrollments/queries'
import { useExperienceConfig } from 'hooks/experience/useExperienceConfig'
import { useAppSelector } from 'hooks/redux'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAvailablePlans } from 'store/benefit-plans/selectors'
import { BenefitPlanV2, NATIVE, NON_NATIVE } from 'store/benefit-plans/types'

/**
 * Calls the NewHire Query to get it's status
 * @returns If user is New Hire, the Start and End  Date, the New Hire plans, and if those
 * 					plans contain Native/NonNative Plans
 */
export const useNewHirePlans = () => {
	const isCorestreamUser = useAppSelector((state) => state.user.userProfile.isCorestreamUser)
	const hasVoluntaryBenefits = useAppSelector((state) => state.site.siteInfo.properties.hasVoluntaryBenefits)
	const experience = useExperienceConfig()

	const newHireData = useNewHire(!isCorestreamUser && !!hasVoluntaryBenefits)

	const plans: BenefitPlanV2[] = useSelector(selectAvailablePlans)

	const newHireStartDate = useMemo(
		() =>
			newHireData.data
				? new Date(newHireData.data.newHireQualificationStartDate)
				: new Date(new Date().setHours(0, 0, 0, 0)),
		[newHireData.data],
	)
	const newHireEndDate = useMemo(
		() =>
			newHireData.data
				? new Date(newHireData.data.newHireQualificationEndDate)
				: new Date(new Date().setHours(0, 0, 0, 0)),
		[newHireData.data],
	)
	const newHirePlans = plans.filter(
		(x) => x.isAvailableForNewhires || (x.isEvergreen && experience.doesWizardHaveEvergreens),
	)
	const hasNative = !!newHirePlans.find((x) => x.enrollmentMethod === NATIVE)
	const hasNonNative = !!newHirePlans.find((x) => x.enrollmentMethod === NON_NATIVE)

	// If the user does not have any native plans, do not mark them as a new hire
	const isNewHire = Boolean(sessionStorage.getItem('isNewHire')) || newHireData?.data?.isNewHire || false

	return useMemo(
		() => ({
			hasNative,
			hasNonNative,
			isFetched: newHireData.isFetched,
			isNewHire,
			isProgramStart: newHireData.data?.isProgramStart,
			newHireEndDate,
			newHirePlans,
			newHireStartDate,
			rawNewHireEndDate: newHireData.data?.newHireQualificationEndDate,
			rawNewHireStartDate: newHireData.data?.newHireQualificationStartDate,
		}),
		[
			hasNative,
			hasNonNative,
			isNewHire,
			newHireData.data?.isProgramStart,
			newHireData.data?.newHireQualificationEndDate,
			newHireData.data?.newHireQualificationStartDate,
			newHireData.isFetched,
			newHireEndDate,
			newHirePlans,
			newHireStartDate,
		],
	)
}
