import { BENEFIT_FACETS, OFFER_FACETS } from 'api/search/consts'
import { ConfiguredIndices } from 'state/slices/search'

type FacetUrlMap = {
	facetName: string
	indexId: ConfiguredIndices
	urlParam: string
}

// Maps URL-friendly names to internal facet names per index
export const FACET_URL_MAPPINGS: FacetUrlMap[] = [
	// Offers
	{
		facetName: OFFER_FACETS.CATEGORY_NAMES,
		indexId: 'offers',
		urlParam: 'category',
	},
	// Benefit Plans
	{
		facetName: BENEFIT_FACETS.PLAN_TYPE,
		indexId: 'benefit_plans',
		urlParam: 'type',
	},
]

type SearchUrlConfig = {
	[key: string]: number | string | undefined
	q?: string
}

export function generateSearchUrl(config: SearchUrlConfig): string {
	const params = new URLSearchParams()

	// Sort keys to ensure consistent order
	const sortedEntries = Object.entries(config).sort(([keyA], [keyB]) => {
		// Always put 'q' parameter first
		if (keyA === 'q') return -1
		if (keyB === 'q') return 1

		return keyA.localeCompare(keyB)
	})

	sortedEntries.forEach(([key, value]) => {
		if (value !== undefined) {
			params.set(key, value.toString())
		}
	})

	return `?${params.toString()}`
}

export function getFacetNameFromUrl(urlParam: string, indexId: string): string | undefined {
	return FACET_URL_MAPPINGS.find((mapping) => mapping.urlParam === urlParam && mapping.indexId === indexId)?.facetName
}

export function getUrlParamFromFacet(facetName: string, indexId: string): string | undefined {
	return FACET_URL_MAPPINGS.find((mapping) => mapping.facetName === facetName && mapping.indexId === indexId)?.urlParam
}

export function parseSearchUrl(searchParams: URLSearchParams): SearchUrlConfig {
	const config: SearchUrlConfig = {}
	const seenParams = new Set<string>()

	for (const [key, value] of searchParams.entries()) {
		if (key === 'page') {
			config[key] = parseInt(value)
		} else {
			// Split comma-separated values into array first
			const values = value.split(',')
			if (seenParams.has(key)) {
				// If we've seen this param before, concat the arrays
				const existing = (config[key] as string).split(',')
				config[key] = [...existing, ...values].join(',')
			} else {
				config[key] = value
				seenParams.add(key)
			}
		}
	}

	return config
}
