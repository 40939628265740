import { axiosInstance as axios } from 'config/axios'
import { MyProfileUser } from 'store/user/selectors'
import { PasswordReset } from 'types/password-reset'
import { PasswordResetVerificationOptions } from 'types/password-reset-verification-options'
import { Claim, RegistrationInfo } from 'types/registration'
import { UserRegistration } from 'types/user-registration'

import UrlBuilder from './util/url-builder'

const identitySubdomain = 'auth'
const urlBuilder: UrlBuilder = new UrlBuilder(identitySubdomain)
export enum EmployeeIdType {
	DateOfBirth = 'DateOfBirth',
	EmployeeId = 'EmployeeId',
}

export type LoginResponse = { isOk: boolean; redirectUrl: null | string }

export type RegistrationData = {
	email?: string
	employeeId?: string
	firstName?: string
	lastName?: string
	zipCode?: number | string
}

export type RegistrationEmailTokenResponse = {
	employeeIdType?: EmployeeIdType
	isMoreInformationRequired: boolean
}

type EmailReturnType = {
	doesTheUserExist: boolean
	firstName: string
	isEligible?: boolean
	isTheAccountLocked: boolean
	lastName: string
}

export default class IdentityService {
	static decodeEmailToken(token: string): Promise<RegistrationInfo> {
		const searchParams = { token, tokenType: 'email' }

		return axios.get(
			new UrlBuilder(identitySubdomain)
				.setEndpoint('Account/VerifyToken')
				.setQueryParams(new URLSearchParams(searchParams))
				.url(),
		)
	}

	static async decodeSamlToken(token: string): Promise<RegistrationData> {
		const searchParams = { token, tokenType: 'saml' }
		const EMPLOYEE_ID: string = 'employee_id' // constant string
		const ZIP_CODE: string = 'zip_code' // constant string

		try {
			const samlData: RegistrationInfo = await axios.get(
				new UrlBuilder(identitySubdomain)
					.setEndpoint('Account/VerifyToken')
					.setQueryParams(new URLSearchParams(searchParams))
					.url(),
			)

			const findClaim = (claimTypeKey: string): Claim | undefined =>
				samlData.claims.find((claim) => claim.claimType === claimTypeKey)

			return {
				email: samlData.email ?? '',
				employeeId: findClaim(EMPLOYEE_ID)?.claimValue.toString() ?? '',
				firstName: samlData.firstName ?? '',
				lastName: samlData.lastName ?? '',
				zipCode: findClaim(ZIP_CODE)?.claimValue.toString() ?? '',
			}
		} catch (e) {
			throw new Error(e as string)
		}
	}

	static getPasswordResetVerificationOptions(email: string): Promise<PasswordResetVerificationOptions> {
		return axios.get(
			urlBuilder
				.setEndpoint('Account/GetPasswordResetVerificationOptions')
				.setQueryParams(new URLSearchParams({ email }))
				.url(),
		)
	}
	static login(Username: string, Password: string): Promise<LoginResponse> {
		return axios.post(urlBuilder.setEndpoint('Account/LoginApi').url(), { Password, Username })
	}

	static async magicLinkLogin(code: string, username: string, returnUrl?: string): Promise<LoginResponse> {
		return axios.post(urlBuilder.setEndpoint('Account/MagicLinkLogin').url(), { code, email: username, returnUrl })
	}

	/**
	 * Register endpoint for SSO clients.
	 */
	static async register(user: UserRegistration): Promise<unknown> {
		const res = await axios.post(urlBuilder.setEndpoint('Account/Register').url(), user)

		return res
	}

	/**
	 * Finalize Registration endpoint for non-SSO clients.
	 * Replaces Register endpoint for non-SSO clients, because that flow was exposing too much information.
	 */
	static async registerV2(user: UserRegistration): Promise<unknown> {
		if (user.dateOfBirth) {
			// The datepicker will give us a string formatted as 'YYYY-MM-DDT00:00:00.000Z'
			// We need to convert it to 'YYYY-MM-DD' for the API
			user.dateOfBirth = new Date(user.dateOfBirth).toISOString().split('T')[0]
		}

		const res = await axios.post(urlBuilder.setEndpoint('AccountRegistration/FinalizeAccountRegistration').url(), user)

		return res
	}

	static async requestLoginMagicLink(email: string, returnUrl?: string): Promise<unknown> {
		return axios.post(urlBuilder.setEndpoint('Account/RequestLoginMagicLink').url(), { email, returnUrl })
	}

	static requestPasswordReset(
		email: string,
		returnUrl: string,
		clientId: string,
		usePhoneNumber: boolean,
		useMagicLink: boolean,
	): Promise<unknown> {
		return axios.post(urlBuilder.setEndpoint('Account/RequestPasswordReset').url(), {
			clientId,
			email,
			returnUrl,
			useMagicLink,
			usePhoneNumber,
		})
	}

	static async resetPassword(
		token: string,
		password: string,
		confirmedPassword: string,
		selectedTenantId: string,
		returnUrl: string,
	): Promise<LoginResponse> {
		const res: PasswordReset = await axios.post(urlBuilder.setEndpoint('Account/ResetPassword').url(), {
			confirmedPassword,
			password,
			returnUrl,
			selectedTenantId,
			token,
		})

		if (!res.userName) {
			throw 'No username found.'
		}

		const loginRes = await IdentityService.login(res.userName, password)

		return loginRes
	}

	static resetPasswordAuthenticated(currentPassword: string, newPassword: string): Promise<unknown> {
		return axios.post(urlBuilder.setEndpoint('Account/ResetPasswordAuthd').url(), { currentPassword, newPassword })
	}

	static async resetPasswordWithCode(
		token: string,
		email: string,
		password: string,
		confirmedPassword: string,
		returnUrl: string,
		usePhoneNumber: boolean,
		useMagicLink: boolean,
	): Promise<LoginResponse> {
		const res: PasswordReset = await axios.post(urlBuilder.setEndpoint('Account/ResetPasswordWithCode').url(), {
			confirmedPassword,
			email,
			password,
			returnUrl,
			token,
			useMagicLink,
			usePhoneNumber,
		})

		const loginRes = await IdentityService.login(res.userName, password)

		return loginRes
	}

	static updateCommunication(user: Partial<MyProfileUser>): Promise<unknown> {
		return axios.post(urlBuilder.setEndpoint('Account/Communication').url(), { ...user })
	}

	static updateProfile(user: MyProfileUser, phoneNumberVerificationCode?: string): Promise<unknown> {
		return axios.post(urlBuilder.setEndpoint('Account/Profile').url(), { ...user, phoneNumberVerificationCode })
	}

	static async updateProfileDob(birthDate: string): Promise<void> {
		try {
			await axios.post(urlBuilder.setEndpoint('Account/Profile').url(), { birthDate })
			localStorage.setItem('profileBirthDate', birthDate)
		} catch (e) {
			throw new Error(e as string)
		}
	}

	/**
	 * Verify Email endpoint for SSO clients.
	 */
	static verifyEmail(email: string, clientId: string, returnUrl: string): Promise<EmailReturnType> {
		return axios.post(urlBuilder.setEndpoint('Account/RequestNewUserRegistrationEmailToken').url(), {
			clientId,
			email,
			returnUrl,
		})
	}

	/**
	 * Verify Email endpoint for non-SSO clients.
	 * Replaces verifyEmail endpoint for non-SSO clients, because it was exposing too much information.
	 */
	static verifyEmailV2(email: string, clientId: string, returnUrl?: string): Promise<RegistrationEmailTokenResponse> {
		return axios.post(urlBuilder.setEndpoint('AccountRegistration/RequestRegistrationEmailToken').url(), {
			clientId,
			email,
			returnUrl,
		}) as Promise<RegistrationEmailTokenResponse>
	}

	static verifyPhoneNumber(phoneNumber: string, clientId: string, employeeId: string, email: string) {
		return axios.post(urlBuilder.setEndpoint('Account/RequestPhoneNumberVerificationToken').url(), {
			clientId,
			email,
			employeeId,
			phoneNumber,
		})
	}
}
