import { BenefitPlanV2 } from 'store/benefit-plans/types'

export const calculateKeydate = (plan: BenefitPlanV2 | null) => {
	if (!plan?.keyDate) {
		return ''
	}

	// only format date if it exists, otherwise use string if provided / undefined if not
	if (plan.keyDate instanceof Date) {
		return plan.keyDate.toDateString()
	} else {
		return plan.keyDate as string
	}
}
