import { ratingClasses } from '@mui/material/Rating'
import { alpha, CssVarsThemeOptions } from '@mui/material/styles'
import { svgIconClasses } from '@mui/material/SvgIcon'

// ----------------------------------------------------------------------

export function rating(): CssVarsThemeOptions['components'] {
	return {
		MuiRating: {
			styleOverrides: {
				iconEmpty: ({ theme }) => ({
					color: alpha(theme.palette.grey[500], 0.48),
				}),
				root: {
					[`&.${ratingClasses.disabled}`]: {
						opacity: 0.48,
					},
				},
				sizeLarge: {
					[`& .${svgIconClasses.root}`]: {
						height: 28,
						width: 28,
					},
				},
				sizeMedium: {
					[`& .${svgIconClasses.root}`]: {
						height: 24,
						width: 24,
					},
				},
				sizeSmall: {
					[`& .${svgIconClasses.root}`]: {
						height: 20,
						width: 20,
					},
				},
			},
		},
	}
}
