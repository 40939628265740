import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function dialog(): CssVarsThemeOptions['components'] {
	return {
		MuiDialog: {
			styleOverrides: {
				paper: ({ ownerState, theme }) => ({
					borderRadius: theme.shape.borderRadius * 2,
					boxShadow: theme.customShadows.dialog,
					...(!ownerState.fullScreen && {
						margin: theme.spacing(2),
					}),
				}),
				paperFullScreen: {
					borderRadius: 0,
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: ({ theme }) => ({
					'& > :not(:first-of-type)': {
						marginLeft: theme.spacing(1.5),
					},
					padding: theme.spacing(4),
				}),
			},
		},
		MuiDialogContent: {
			styleOverrides: {
				dividers: ({ theme }) => ({
					borderBottomStyle: 'dashed',
					borderTop: 0,
					padding: theme.spacing(4),
				}),
				root: ({ theme }) => ({
					overflowY: 'visible',
					padding: theme.spacing(4),
				}),
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: ({ theme }) => ({
					padding: theme.spacing(3, 4, 4, 4),
				}),
			},
		},
	}
}
