export const NONE = 'None'
export const NONE_BUGFIX = ''
export const TRENDING = 'Trending'
export const FEATURED = 'Featured'
export const HERO = 'Hero'
export const EXCLUSIVE_PARTNERSHIPS = 'ExclusivePartnership'
export type FeatureLevel =
	| ''
	| typeof EXCLUSIVE_PARTNERSHIPS
	| typeof FEATURED
	| typeof HERO
	| typeof NONE
	| typeof TRENDING

export const DEFAULT_FEATURE_LEVELS = [NONE, TRENDING, FEATURED, HERO, EXCLUSIVE_PARTNERSHIPS]
