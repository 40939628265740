import { Snackbar } from 'components/snackbar'
import { useEffect } from 'react'
import { useZustandStore, ZustandStore } from 'state/store'

export const GlobalSnackbar = () => {
	const { closeSnackbar, message, open, status } = useZustandStore((state: ZustandStore) => state.snackbar)

	useEffect(() => {
		if (open) {
			// delay 5 seconds
			setTimeout(() => {
				closeSnackbar()
			}, 5000)
		}
	}, [closeSnackbar, open])

	return <Snackbar message={message} open={open} status={status} onClose={closeSnackbar} />
}
