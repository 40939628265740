/* eslint-disable react-hooks/rules-of-hooks */
import { EnrollmentType } from 'hooks/user-data'
import { useFlags } from 'launchdarkly-react-client-sdk'
import LogRocket from 'logrocket'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { siteInfoSelector } from 'store/site/selectors'
import { spaceToSnakeCase } from 'utils/str/spaceToSnakeCase'
import { trackEvent } from 'utils/utils'

import { useAlgoliaTrackingContext } from './algolia-tracking-context'
import { useEventPageName } from './useEventPageName'

export type AccountPageNames =
	| 'account deductions'
	| 'account deductions legacy'
	| 'account enrollments'
	| 'account my benefits legacy'
	| 'account profile'
	| 'account profile legacy'

export type AnalyticsActionTypes =
	| 'click'
	| 'element viewed'
	| 'on complete'
	| 'on leave'
	| 'search'
	| 'user evaluation'

export type CaptureEventDetails = {
	// plans
	benefitPlanId?: number

	// offers
	discountOfferId?: string

	// details
	elementDetails?: string

	enrollmentType?: EnrollmentType
	// errors
	errorType?: errorTypes
	offerBrand?: string // hotwire, truecar, ticketsatwork, ge appliances, etc.
	offerName?: string // “10% off!”, “save on flights + hotels”, “preferred pricing”, etc.

	offerType?: 'benefits' | 'discounts'

	// autofilled
	organizationId?: string // autofilled
	organizationName?: string // autofilled
	tenant?: string // autofilled
	url?: string // autofilled
} & RequiredTypes

export type EnrollmentImplementationNames = 'liberty-auto' | 'pet' | 'single-tier' | 'trustmark-simplink'

export type HomePageNames = 'home' | 'product values home'

export type LegacyWizardPageNames = 'old wizard' | 'old wizard confirmation'

export type LoginPageNames =
	| 'callback'
	| 'forgot password'
	| 'login'
	| 'magic link'
	| 'register'
	| 'saml'
	| 'worker redirect'

// Add new Page Names here!
export type pageNames =
	| ''
	| 'auto'
	| 'coming soon'
	| 'data use policy'
	| 'deals'
	| 'enrollment wizard'
	| 'eoi'
	| 'error'
	| 'faq'
	| 'faq'
	| 'home'
	| 'legacy trustmark simplink'
	| 'local discounts'
	| 'more coverage'
	| 'my benefits'
	| 'non native plan'
	| 'plan'
	| 'plan category'
	| 'plan category legacy'
	| 'plan legacy'
	| 'receipt'
	| 'search'
	| 'terms'
	| 'themes'
	| 'wizard'
	| 'wizard intro'
	| AccountPageNames
	| EnrollmentImplementationNames
	| HomePageNames
	| LegacyWizardPageNames
	| LoginPageNames
	| RegistrationPageNames
	| SurveyPageNames

export type RegistrationPageNames = 'native registration' | 'saml registration'

/**
 * IMPORTANT Please update the "Corestream Event Tracking" Google Sheet when adding an event to a user action in our app
 *
 * https://docs.google.com/spreadsheets/d/1WbwcPNAt4xlkkMfgggahdkxkaWBt4e3qXeLcvGxn6PI/edit?usp=sharing
 */
export type RequiredTypes = {
	actionType: AnalyticsActionTypes
	elementName: string // e.g. product card, wizard action button, etc.
	elementType:
		| 'button'
		| 'carousel'
		| 'eligibility'
		| 'enrollment'
		| 'hyperlink'
		| 'login'
		| 'modal'
		| 'page'
		| 'textbox'
		| 'video'
	pageName?: pageNames
}

export type SurveyPageNames = 'survey-results' | 'survey' | 'typeform-survey'

// Add new Error Types here!
type errorTypes = ''

type InternalCaptureEventDetails = {
	eventName: string
} & CaptureEventDetails

/**
 * Send tracking events to Segment.
 *
 * The purpose of this hook is to gatekeep the properties we send
 * in our events. We need to be thoughtful about any new properties
 * we add, and get permission from the data team.
 *
 * Only use this in special one-off cases. Please make a new hook
 * with a defined interface for the additonal required attributes.
 */
export const _useCaptureEvent = () => {
	const { b4b_13017_logrocketevents, b4b_19185_disable_segment } = useFlags()
	const siteInfo = useSelector(siteInfoSelector)
	const currentURL = window.location.href

	const routePageName = useEventPageName()

	const algoliaData = useAlgoliaTrackingContext()

	return useCallback(
		(eventData: CaptureEventDetails) => {
			const updatedEventData: InternalCaptureEventDetails = {
				...algoliaData,
				eventName: `${spaceToSnakeCase(eventData.elementName)}_${spaceToSnakeCase(
					eventData.elementType,
				)}_${spaceToSnakeCase(eventData.actionType)}`,
				organizationId: siteInfo.organizationId,
				organizationName: siteInfo.organizationName,
				tenant: siteInfo.properties.ppdsTenantCode,
				url: currentURL,
				...eventData,
				pageName: eventData.pageName ?? routePageName ?? '',
			}

			if (!b4b_19185_disable_segment) {
				trackEvent('event', updatedEventData)
			}

			if (b4b_13017_logrocketevents) {
				LogRocket.track('event', {
					...updatedEventData,
				})
			}
		},
		[
			algoliaData,
			b4b_13017_logrocketevents,
			b4b_19185_disable_segment,
			currentURL,
			routePageName,
			siteInfo.organizationId,
			siteInfo.organizationName,
			siteInfo.properties.ppdsTenantCode,
		],
	)
}

// Takes in JSON flag and logs the event to logrocket for filtering on logrocket dashboards
export const captureFlagEvaluation = (flagName: string, jsonFlag: Record<string, boolean | null | number | string>) => {
	LogRocket.track(flagName, jsonFlag)
}
