import { axiosInstance } from 'config/axios'
import UrlBuilder from 'services/util/url-builder'

import { API_SUBDOMAIN, endpoints } from '../constants'
import { LTCEnrollmentResponse, WorkerAddressResponse } from '../types'

const urlBuilder: UrlBuilder = new UrlBuilder(API_SUBDOMAIN)

export class TrustmarkSimplinkService {
	static getEnrollmentData(signal?: AbortSignal): Promise<LTCEnrollmentResponse> {
		const url = urlBuilder.setEndpoint(endpoints.TrustmarkSimplink.getEnrollmentData()).url()

		return axiosInstance.get(url, { signal })
	}

	static getTrustmarkClientToken(
		data: { isCustomFunctionality: boolean } & Partial<WorkerAddressResponse>,
		signal?: AbortSignal,
	): Promise<string> {
		const url = urlBuilder.setEndpoint(endpoints.TrustmarkSimplink.getToken()).url()

		return axiosInstance.post(url, { ...data }, { signal })
	}

	static getWorkerAddress(signal?: AbortSignal): Promise<WorkerAddressResponse> {
		const url = urlBuilder.setEndpoint(endpoints.TrustmarkSimplink.getWorkerAddress()).url()

		return axiosInstance.get(url, { signal })
	}
}
