import { liteClient } from 'algoliasearch/lite'
import { useAppContext } from 'app/contexts/app-context'
import { useCallback } from 'react'
import { match } from 'ts-pattern'
import { Environments } from 'utils/env'

const algoliaAppId: string = import.meta.env.VITE_ALGOLIA_APP_ID ?? ''
const algoliaApiKey: string = import.meta.env.VITE_ALGOLIA_API_KEY ?? ''

const getIndex =
	(index: string) =>
	(val: string): string =>
		match(val)
			.with(Environments.INT, (e) => `${e}_${index}`)
			.with(Environments.UAT, () => `uat_${index}`) // uat env is test, we need the uat string
			.with(Environments.PROD, () => index)
			.otherwise(() => `${Environments.DEV}_${index}`)

// Centralized mapping for index names
export const indexNames = {
	benefit_plans: 'benefit_plans',
	faqs: 'faqs',
	marketing_content: 'marketing_content',
	offers: 'offers',
}

// Create a type for valid index names
export type ValidIndexNames = `${(typeof indexNames)[keyof typeof indexNames]}`

export const useGetEnvIndex = (index: ValidIndexNames) => {
	// need to consume from here to work with preview mode
	const env = useAppContext().site.env

	return getIndex(index)(env)
}

export const useGetPrefixedIndex = () => {
	const env = useAppContext().site.env

	return useCallback((index: ValidIndexNames) => getIndex(index)(env), [env])
}

export const algoliaClient = liteClient(algoliaAppId, algoliaApiKey)

export const showMoreLimit = 100
