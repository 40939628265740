import { CsAlgoliaSearchConfigDto, LocalAlgoliaConfig } from 'api/search/types/algolia'
import { ConfiguredIndices, IndexParams } from 'state/slices/search'
import { vanillaStore } from 'state/store'

import { createFacetFiltersFromRecord } from '../facets'
import { buildAlgoliaFilters } from '../filters'
import { NumericFilter } from '../numeric-filters'

export type CreateSearchConfigOptions = {
	algoliaIndexName: string
	ignoreBaseConfig?: boolean
	omit?: {
		facets?: boolean
		filters?: boolean
		numericFilters?: boolean
		query?: boolean
	}
}

/** the type we use to send to algoia's api client */
export function createSearchConfig(
	index: ConfiguredIndices,
	config: LocalAlgoliaConfig,
	options: CreateSearchConfigOptions = { algoliaIndexName: '' },
): CsAlgoliaSearchConfigDto {
	const { ignoreBaseConfig = false, omit = { facets: false, filters: false, numericFilters: false, query: false } } =
		options
	// this is a one time pull of the base config - if base config updates it will not be reflected here
	const params: IndexParams = ignoreBaseConfig
		? ({ facetConfig: {}, facets: {}, filters: {} } as IndexParams)
		: (vanillaStore.getState().search.indices[index]?.params as IndexParams)

	const facetFilters = omit.facets ? [] : createFacetFiltersFromRecord(params.facets, params?.facetConfig || {})

	const baseConfig: Omit<LocalAlgoliaConfig, 'facetConfig'> = {
		...params,
		// @ts-expect-error - this is a temporary fix to remove facetConfig from the config
		facetConfig: undefined,
		facetFilters: facetFilters as string[] | string[][] | undefined,
		facets: [],
		numericFilters: params.numericFilters as NumericFilter[],
		query: params.query || '',
		sortFacetValuesBy: params.sortFacetValuesBy,
		userToken: params.userToken,
	}

	const { facetConfig, ...configWithoutFacetConfig } = config

	const mergedFilters = omit.filters
		? config.filters
		: {
				...baseConfig.filters,
				...config.filters,
		  }

	const mergedNumericFilters = [...((params.numericFilters as NumericFilter[]) || []), ...(config.numericFilters || [])]

	return {
		...baseConfig,
		...configWithoutFacetConfig,
		filters: buildAlgoliaFilters(mergedFilters), // Final override for merged filters
		numericFilters: omit.numericFilters ? undefined : mergedNumericFilters,
		query: omit.query ? config.query || '' : config.query || params.query || '',
	}
}
