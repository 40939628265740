// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories
export const queryKeys = {
	all: () => ['tenants'] as const,

	benefitElections: {
		all: () => [...queryKeys.all(), 'benefitElections'] as const,
		questions: (benefitPlanId: number, keyDate: string) =>
			[...queryKeys.benefitElections.all(), 'questions', benefitPlanId, keyDate] as const,
	},

	integrationPartners: {
		accessTravel: {
			all: () => [...queryKeys.integrationPartners.all(), 'accessTravel'] as const,
			sessionToken: () => [...queryKeys.integrationPartners.accessTravel.all(), 'sessionToken'] as const,
		},
		all: () => [...queryKeys.all(), 'integrationPartners'] as const,
		trueCar: {
			all: () => [...queryKeys.integrationPartners.all(), 'truecar'] as const,
			carMakes: () => [...queryKeys.integrationPartners.trueCar.all(), 'carMakes'] as const,
		},
	},

	qle: {
		all: () => [...queryKeys.all(), 'qle'] as const,
		events: (employerAccountCode?: string) =>
			[...queryKeys.all(), 'qle', 'legacy-events', employerAccountCode] as const,
		organization: {
			all: (organizationId: string) => [...queryKeys.qle.all(), 'organization', organizationId] as const,
		},
	},

	rates: {
		all: () => [...queryKeys.all(), 'rates'] as const,
		coverageOptionsAndLowestRates: (params: { benefitPlanId: number; keyDate: Date | undefined }) =>
			[...queryKeys.rates.all(), 'coverageOptionsAndLowestRates', params] as const,
	},

	tracking: {
		all: () => ['tracking'] as const,

		offers: {
			all: () => [...queryKeys.all(), 'offers'] as const,
		},
	},
}
