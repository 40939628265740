import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { cx } from 'class-variance-authority'
import { WysiwigContent } from 'components/wysiwig-content/wysiwig-content'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { FC, ReactElement, useState } from 'react'
import { useSelector } from 'react-redux'

import './accordion-item.css'

import { siteInfoSelector } from 'store/site/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'

import { AddIcon, RemoveIcon } from './styles'
import { AccordionItemProps } from './types'

const AccordionItem: FC<AccordionItemProps> = (props: AccordionItemProps): ReactElement => {
	const [expanded, setExpanded] = useState<boolean>(false)
	const textComponent = typeof props.details === 'string' ? 'p' : 'div'
	const id = self.crypto.randomUUID()
	const siteInfo: SiteInfoResponse = useSelector(siteInfoSelector)
	const openIcon = props.openIcon ?? <RemoveIcon />
	const closedIcon = props.closedIcon ?? <AddIcon />

	const removePhoneNumber = (text: string) => {
		if (!siteInfo.properties.excludeCSPhoneNumber) return text

		return text.replace(`call ${siteInfo.properties.customerCarePhone}`, 'click to chat below')
	}

	const track = useTrackDetailedPageEvent()

	const onItemExpand = () => {
		if (!expanded && props.onExpandEvent) {
			props.onExpandEvent()
		}

		setExpanded(!expanded)
	}

	return (
		<div className={cx('accordion', { 'hide-seperator': props.hideSeparator })}>
			<Accordion className={props.className} expanded={expanded} square onChange={onItemExpand}>
				<AccordionSummary
					aria-controls={`panel-${id}-content`}
					className={props.summaryClassName}
					expandIcon={expanded ? openIcon : closedIcon}
					id={`panel-${id}-header`}
				>
					<Typography className='accordion-text accordion-summary-text' variant={props.summaryVariant ?? 'body1'}>
						{props.summary}
					</Typography>
				</AccordionSummary>
				<AccordionDetails className={props.detailsClassName}>
					<Typography className='accordion-text' component={textComponent}>
						{typeof props.details === 'string' ? (
							<WysiwigContent
								content={removePhoneNumber(props.details)}
								onLinkClick={(url) => {
									track({
										actionType: 'click',
										elementDetails: url,
										elementName: `${props.trackingName ?? 'accordion'} link`,
										elementType: 'hyperlink',
									})
								}}
							/>
						) : (
							<>{props.details}</>
						)}
					</Typography>
				</AccordionDetails>
			</Accordion>
		</div>
	)
}

AccordionItem.defaultProps = {}

export default AccordionItem
