import { cx } from 'class-variance-authority'
import AppFooter from 'components/app-footer'
import { AppHeader } from 'components/app-header'
import CookiePolicy from 'components/cookie-policy/cookie-policy'
import ErrorBoundary from 'components/error-boundary'
import IEModal from 'components/ie-modal/ie-modal'
import { Loading } from 'components/loading'
import OptInModal from 'components/opt-in-modal/opt-in-modal'
import { SkipLink } from 'components/skip-link'
import { Snackbar } from 'components/snackbar'
import { ScrollToTop } from 'components/util'
import { useSearchInit } from 'feature/search/hooks/use-search-init'
import { useAccessTravel } from 'hooks/accessDevelopment/useAccessTravel'
import { useIntercom } from 'hooks/intercom'
import { useAppSelector } from 'hooks/redux'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { useBenefitPlanInit } from 'hooks/use-benefit-plan-init'
import { useCallbackDispatch } from 'hooks/use-callback-dispatch'
import useLdIdentify from 'hooks/use-ld-identify'
import { usePageTrack } from 'hooks/use-page-track'
import { useTitle } from 'hooks/use-title'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { lazy, Suspense, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { selectBenefitPlansFetchStatus } from 'store/benefit-plans/selectors'
import { userEnrollmentOverviewStatusSelector } from 'store/enrollments/selectors'
import { getUserEnrollmentOverview } from 'store/enrollments/slice'
import { actions, errorStateSelector } from 'store/error-slice'
import { siteInfoSelector } from 'store/site/selectors'
import { isLoggedInSelector, myProfileSelector, MyProfileUser } from 'store/user/selectors'
import { match, P } from 'ts-pattern'
import { SITE_NAMES } from 'types/site-names'
import { ERROR, IDLE, SUCCESS } from 'types/status-types'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { retry } from 'utils/promise-rety'

import './standard-layout.scss'

import { LocalStore } from 'utils/utils'

const availableMilliporeUrls: string[] = ['/', '/home', '/logout', '/login', '/register', '/forgot-password']
const Millipore = lazy(() => retry(() => import('components/millipore-logos/millipore-logos')))

const StandardLayout = () => {
	const isLoggedIn: boolean = useSelector(isLoggedInSelector)

	const { und3484 } = useFlags()
	const location: any = useLocation()
	const { organizationName, properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const hasBenefits: boolean = Boolean(properties.hasVoluntaryBenefits)
	const userToken = useAppSelector((s) => s.oidc.user?.id_token)
	const benefitPlansFetchStatus: StateMachineStatus = useSelector(selectBenefitPlansFetchStatus)
	const { isDiscountsOnly }: MyProfileUser = useSelector(myProfileSelector)
	const { errorMessage, shouldAutoHide, showError } = useSelector(errorStateSelector)
	const enrollmentsStatus: StateMachineStatus = useSelector(userEnrollmentOverviewStatusSelector)
	const onClose = (): void => dispatch(actions.setShowErrorMessage({ errorMessage: '', showError: false }))
	const dispatch = useCallbackDispatch()
	const shouldFetchBenefitsInfo: boolean = !isDiscountsOnly && hasBenefits

	useTitle()

	useIntercom()
	useAccessTravel()
	usePageTrack()
	useLdIdentify()
	useBenefitPlanInit(isLoggedIn)
	useSearchInit(isLoggedIn)

	/*
	 * We need the UserEnrollments call to take place after the benefit plans(v2)
	 * have been retrieved.
	 */
	useEffect(() => {
		const getEnrollmentsOverview = async (): Promise<void> => {
			await dispatch(getUserEnrollmentOverview({ und3484 }))
		}

		match({
			benefitPlansFetchStatus,
			enrollmentsStatus,
			idToken: userToken,
			isDiscountsOnly,
			isLoggedIn,
			shouldFetchBenefitsInfo,
		})
			.with(
				{
					benefitPlansFetchStatus: SUCCESS,
					enrollmentsStatus: IDLE,
					idToken: P.not(P.nullish),
					isDiscountsOnly: false,
					isLoggedIn: true,
					shouldFetchBenefitsInfo: true,
				},
				() => getEnrollmentsOverview(),
			)
			.otherwise(() => null)
	}, [
		benefitPlansFetchStatus,
		dispatch,
		enrollmentsStatus,
		isDiscountsOnly,
		isLoggedIn,
		shouldFetchBenefitsInfo,
		und3484,
		userToken,
	])

	const shouldRenderLogos: boolean =
		organizationName === SITE_NAMES.MILLIPORE && availableMilliporeUrls.includes(location.pathname)

	const usedEmailClaimForRegistration: null | string = LocalStore.get('usedEmailClaimForRegistration')

	return (
		<main>
			<SkipLink />
			<AppHeader />
			<main className={cx('wrapper')}>
				{und3484 && isLoggedIn && (usedEmailClaimForRegistration ?? false) ? <OptInModal /> : null}
				<ErrorBoundary>
					<ScrollToTop />
					{shouldRenderLogos && <Millipore shouldRenderLogos />}
					<Suspense
						fallback={
							<div
								// doing this on purpose to force us to take a longer look @ the loading experience
								style={{
									alignItems: 'center',
									display: 'flex',
									height: 'calc(100vh - var(--header-height))',
									justifyContent: 'center',
								}}
							>
								<Loading />
							</div>
						}
					>
						<Outlet />
					</Suspense>
				</ErrorBoundary>
			</main>
			<AppFooter />
			<IEModal />
			<Snackbar
				message={errorMessage}
				open={showError}
				shouldAutoHide={shouldAutoHide}
				status={ERROR}
				onClose={onClose}
			/>
			<CookiePolicy />
		</main>
	)
}

export default StandardLayout
