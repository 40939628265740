import { useCallback } from 'react'
import { BenefitPlanV2, EnrollmentImplementation } from 'store/benefit-plans/types'
import { match } from 'ts-pattern'

// Function to validate the plan's implementation
export function validateEnrollmentImplementation(bp: BenefitPlanV2, implementation: EnrollmentImplementation | null) {
	return bp.enrollmentImplementation === implementation
}

// Updated hook
export const useEnrollmentImplementation = () => {
	const checkExperience = useCallback((plan: BenefitPlanV2, implementation: EnrollmentImplementation | null) => {
		return validateEnrollmentImplementation(plan, implementation)
	}, [])

	const getExperience = useCallback((plan: BenefitPlanV2 | null) => {
		if (!plan) return null

		return match(plan)
			.when(
				(plan) => plan.enrollmentImplementation === EnrollmentImplementation.nwPet,
				() => EnrollmentImplementation.nwPet,
			)
			.when(
				(plan) => plan.enrollmentImplementation === EnrollmentImplementation.singleTier,
				() => EnrollmentImplementation.singleTier,
			)
			.when(
				(plan) => plan.enrollmentImplementation === EnrollmentImplementation.liberty,
				() => EnrollmentImplementation.liberty,
			)
			.when(
				(plan) => !plan.isNative,
				() => 'nonNative',
			)
			.otherwise(() => 'native')
	}, [])

	return {
		checkExperience,
		getExperience,
	}
}
