import { LocalStore } from 'utils/utils'

import { key } from './types'

const MAX_SIZE = 11

/**
 * Get View Set
 * @desc instantiate a Set of values or return a Set of values while
 * maintaining the desired order with the most recently viewed
 * at the top of the stack
 */
const getViewSet = (): Set<string> => {
	const views: null | Set<string> = LocalStore.get(key)

	if (!views || views.size === 0) {
		return new Set<string>()
	}

	const viewSet = new Set<string>()

	Array.from(views)
		.reverse()
		.forEach((objectID) => viewSet.add(objectID))

	return viewSet
}

/**
 * Track View
 * @desc Internally store a map of recently viewed offers while keeping
 * the most recently viewed at the front of the stack. Externally exposes
 * and saves an array
 */
export const trackView = ({ objectID }: { objectID: string }): void => {
	let viewSet = getViewSet()

	if (viewSet.has(objectID)) {
		// remove the offer so that it can be pushed to the top of the stack
		viewSet.delete(objectID)
	}

	if (viewSet.size >= MAX_SIZE) {
		const viewArray = [...viewSet.values()].reverse()
		viewArray.length = MAX_SIZE
		viewSet = new Set<string>()
		viewArray.reverse()
		viewArray.forEach((entry) => viewSet.add(entry))
	}

	viewSet.add(objectID)
	LocalStore.set(key, [...viewSet.values()].reverse())
}

export const retrieveViews = (): string[] => {
	const views: null | Set<string> = LocalStore.get(key)

	if (!views || views.size === 0) {
		return []
	}

	return Array.from(views)
}
