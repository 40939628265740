import { useBenefitPlans } from 'api/query-hooks'
import { useIsDecisionSupportEnabledForClient } from 'hooks/user-data/use-is-decision-support-enabled-for-client'

export const useSurveyData = () => {
	const { data: availablePlans, isFetching } = useBenefitPlans()
	const isEnabled = useIsDecisionSupportEnabledForClient()

	const uniqueBenefitPlanTypes = new Set(availablePlans?.map((plan) => plan.benefitPlanType))

	const uniqueBenefitPlanTypesArray = Array.from(uniqueBenefitPlanTypes)

	return {
		//EE Has to be eligible for at least 3 plans to take the survey
		currentlyEligibleToTakeSurvey: isEnabled && uniqueBenefitPlanTypesArray.length >= 3,
		hasPreviouslyCompletedSurvey: availablePlans?.some((x) => !!x.recommendation),
		isFetching,
	}
}
