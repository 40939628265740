import { combineReducers } from 'redux'
import { reducer as oidc } from 'redux-oidc-js'

import { autoquotes } from './autoquotes/slice'
import { benefitEnrollment } from './benefit-enrollment/slice'
import { benefitPlans } from './benefit-plans/slice'
import { cart } from './cart/slice'
import { enrollmentWizard } from './enrollment-wizard/slice'
import { enrollments } from './enrollments/slice'
import { error } from './error-slice'
import { externalStore } from './external-store/slice'
import { persons } from './persons/slice'
import { site } from './site/slice'
import { steps } from './steps/slice'
import { user } from './user/slice'

const rootReducer = combineReducers({
	autoquotes,
	benefitEnrollment,
	benefitPlans,
	cart,
	enrollments,
	enrollmentWizard,
	error,
	externalStore,
	oidc,
	persons,
	site,
	steps,
	user,
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
