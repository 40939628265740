import { alpha, CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function backdrop(): CssVarsThemeOptions['components'] {
	return {
		MuiBackdrop: {
			styleOverrides: {
				invisible: {
					background: 'transparent',
				},
				root: ({ theme }) => ({
					backgroundColor: alpha(theme.palette.grey[900], 0.8),
				}),
			},
		},
	}
}
