import { axiosInstance } from 'config/axios'

import UrlBuilder from './util/url-builder'

const urlBuilder = new UrlBuilder()
export type ReferralUrl = { marketid: string; name: string; njv_token: string } & ReferralObj

type ReferralObj = {
	njv_aid: string
	njv_cid: string
	njv_ip: string
	njv_time: string
	njv_ua: string
}

export default class DiscountOffersService {
	static getSamsungRef = async (): Promise<ReferralUrl> => {
		const res: ReferralUrl = await axiosInstance.post(urlBuilder.setEndpoint('DiscountOffers/Samsung/Token').url(), {
			userAgent: navigator.userAgent,
		})

		return res
	}
}
