import { useEnrollmentData } from 'hooks/user-data'

/**
 * @returns true if there is only one plan in the OE Window
 */
export const useIsSingleProductOE = () => {
	const { enrollmentPlansToDisplay } = useEnrollmentData()

	if (enrollmentPlansToDisplay.length === 1) {
		return { plan: enrollmentPlansToDisplay[0], result: true }
	}

	return { plan: null, result: false }
}
