//@index(['./*', '!./**.{test,stories}.*', '!./{__test__,test,_}*', '!./**.scss' ], f => `export * from '${f.path}'`)
export * from './deparam'
export * from './useIsPaychex'
export * from './useOpen'
//@endindex

import { createSearchParams, matchPath, useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

export const isValidEmail = (value: string): boolean => {
	const yupString = yup.string()

	return yupString.email().isValidSync(value)
}

export const buildUrl = (value: string | string[], paramName: string = 'category'): string => {
	const values = Array.isArray(value) ? [...value] : [value]
	const builtParams = values.map((paramValue) => `${paramName}=${encodeURIComponent(paramValue)}`)

	return builtParams.join('&')
}

/**
 * Update URL
 * @desc get the URL in sync when adding or removing a refinement. We want to keep the existing query or
 * category/brand if it is already in the URL. Build the string based on what the search string state is and
 * if no search then stay on the search page since a refinement was tweaked while the user is on the
 * search page
 */
export const useUpdateUrl = () => {
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()

	return (refinements: string[]) => {
		const searchObject: Record<string, string | string[]> = {}

		// Handle query param
		const query = searchParams.get('query')
		if (query) {
			searchObject.query = query
		}

		// Handle categories
		if (refinements.length > 0) {
			searchObject.category = refinements.map((refinement) => encodeURIComponent(refinement))
		}

		navigate(`/search${Object.keys(searchObject).length > 0 ? `?${createSearchParams(searchObject).toString()}` : ''}`)
	}
}

/**
 * 	Builds the url for a Deal Category's search page.
 *  @param cateogry category to build url for
 *  @returns url string for Deal Category's search page
 */
export const getDealCategorySearchUrl = (category: string) => {
	return `/search?category=${encodeURIComponent(category)}`
}

export const isPathWithinGroup = (path: string, group: string[]): boolean =>
	group.some((item) => matchPath({ end: false, path: item }, path))
