import { Properties, SiteInfoResponse, SiteStatus } from 'types/tenant-site-types'

export type SiteConfig = {
	hasAutoQuoting?: boolean
	hasBenefits?: boolean
	hasDiscounts?: boolean
	hasPayroll?: boolean
}

export type SiteState = {
	isSiteLoading: boolean
	siteError: Error | null
	siteInfo: SiteInfoResponse
}

export const initialState: SiteState = {
	isSiteLoading: true,
	siteError: null,
	siteInfo: {
		organizationId: '',
		organizationName: '',
		organizationUnitId: '',
		organizationUnitName: '',
		partnerId: '',
		partnerName: '',
		properties: new Properties(),
		status: SiteStatus.Active,
	},
}
