import { CardContent, CardHeader, Skeleton } from '@mui/material'

import styles from '../offer-modal.module.css'

export function OfferModalSkeleton() {
	return (
		<>
			<CardHeader
				action={<Skeleton height='2rem' variant='circular' width='2rem' />}
				avatar={<Skeleton className={styles['logo']} variant='rectangular' />}
				className={styles['header']}
				classes={{
					action: styles['action'],
					avatar: styles['avatar'],
				}}
			/>
			<CardContent className={styles['card']}>
				<div className={styles['content']}>
					<div className={styles['main']}>
						<div className={styles['top-content']}>
							<Skeleton variant='text' width='80%'>
								<p>C</p>
							</Skeleton>
							<Skeleton className={styles['title-']} variant='text' width='90%'>
								<h2>T</h2>
							</Skeleton>

							<div className={styles['body']}>
								<Skeleton variant='text' width='100%'>
									<p>lorem ipsum dolor sit amet consectetur adipiscing elit asdf sdf asdf asdf asdf s fdasdf asdf</p>
								</Skeleton>
								<Skeleton variant='text' width='90%'>
									<p>D</p>
								</Skeleton>
								<Skeleton variant='text' width='87%'>
									<p>D</p>
								</Skeleton>
							</div>
							<div className={styles['content-button']}>
								<Skeleton variant='rounded' width='40%' />
							</div>
						</div>

						<div className={styles['bottom-content']}>
							<Skeleton height='1.5rem' variant='text' width='100%' />
							<Skeleton height='1.5rem' variant='text' width='90%' />
							<Skeleton height='1.5rem' variant='text' width='95%' />
							<Skeleton height='1.5rem' variant='text' width='85%' />
						</div>
					</div>
				</div>
			</CardContent>
		</>
	)
}
