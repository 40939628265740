import type { User, UserManager } from 'oidc-client'

import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { NavigateFunction } from 'react-router-dom'
import { loadUser } from 'redux-oidc-js'
import { siteInfoSelector } from 'store/site/selectors'
import { store } from 'store/store'
import { getUserManagerSelector, MyProfileUser } from 'store/user/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { ldIdentify } from 'utils/launchdarkly/launch-darkly-utils'
import { LocalStore } from 'utils/utils'

import { useTrackDetailedPageEvent } from './analytics'
import { logErrorLR } from './analytics/useCaptureException'
import { useReturnUrl } from './use-return-url'

export const useSilentLogin = () => {
	const userManager: UserManager = useSelector(getUserManagerSelector)
	const navigate = useNavigate()
	const { handleReturnUrl } = useReturnUrl()
	const siteInfo: SiteInfoResponse = useSelector(siteInfoSelector)
	const ldClient = useLDClient()
	const track = useTrackDetailedPageEvent()

	return async () => {
		const user: undefined | User = await silentLogin(userManager, navigate, siteInfo, handleReturnUrl, track)
		if (user && user.profile) {
			const { employeeId, employmentGroup, employmentType, userId } = new MyProfileUser(user.profile)
			await ldIdentify(ldClient, siteInfo.organizationName, userId, employeeId, employmentGroup, employmentType)
		}
	}
}

/**
 * Silent login. Calls /connect/authorize in an iFrame
 * If error, we will need to recreate standard login flow
 * @param userManager from redux store
 * @param navigate function from react router
 * @returns void
 */
async function silentLogin(
	userManager: UserManager,
	navigate: NavigateFunction,
	siteInfo: SiteInfoResponse,
	handleReturnUrl: (user: User) => undefined | User,
	track: (event: any) => void,
): Promise<undefined | User> {
	const trackLogin = (user: User): void => {
		if (window.analytics) {
			window.analytics.identify(user?.profile?.sub, {
				anonymousId: LocalStore.get('ajs_anonymous_id'),
				csSessionId: user.profile?.sid,
				orgID: siteInfo.organizationId,
			})
			window.analytics.group(siteInfo.organizationId, {
				name: siteInfo.organizationName,
				siteUrl: siteInfo?.properties?.url,
			})
			track({
				actionType: 'on complete',
				elementName: 'user signed in',
				elementType: 'login',
				pageName: 'login',
			})
		}
	}

	try {
		await userManager.removeUser()
		await userManager.clearStaleState()
		await userManager.signinSilent()
		const user: User = await loadUser(store, userManager)

		trackLogin(user)
		handleReturnUrl(user)

		return user
	} catch (e) {
		const error: Error = e as Error
		logErrorLR(error, { Caller: 'silentLogin' })
		await userManager.removeUser()
		await userManager.clearStaleState()
		navigate('/login?error=true')
		window.location.reload()
	}
}
