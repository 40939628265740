import { GlobalContextProviders } from 'app/GlobalContextProviders'
import { AxiosInterceptor } from 'config/axios'
import { LogRocketComponent } from 'feature/admin/admin-widget/logRocket/log-rocket-initializer'
import { ModalProvider } from 'feature/modals/modal-context'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { loadUser, OidcProvider } from 'redux-oidc-js'
import { isSiteLoadingSelector } from 'store/site/selectors'
import { store } from 'store/store'
import { getUserManagerSelector, isLoggedInSelector } from 'store/user/selectors'

import StandardLayout from './standard-layout'

const AppLayout = () => {
	const userManager = useSelector(getUserManagerSelector)
	const isSiteLoading = useSelector(isSiteLoadingSelector)
	const isLoggedIn: boolean = useSelector(isLoggedInSelector)

	// /**
	//  * Load the user
	//  */
	useEffect(() => {
		if (!isSiteLoading) loadUser(store, userManager)
	}, [isSiteLoading, userManager])

	return (
		<>
			<LogRocketComponent isLoggedIn={isLoggedIn} />
			<OidcProvider store={store} userManager={userManager}>
				<AxiosInterceptor>
					<GlobalContextProviders isLoggedIn={isLoggedIn}>
						<ModalProvider>
							<StandardLayout />
						</ModalProvider>
					</GlobalContextProviders>
				</AxiosInterceptor>
			</OidcProvider>
		</>
	)
}

export default AppLayout
