import { BenefitPlanV2 } from 'store/benefit-plans/types'

export const BENEFIT_PLAN_CARD_ATTRIBUTES = [
	'benefitPlanGroupPrimaryId',
	'benefitPlanId',
	'benefitPlanType',
	'benefitProductName',
	'benefitProviderImage',
	'benefitProviderName',
	'corestreamProductId',
	'isEvergreen',
	'isNative',
	'effectiveMarketingContent.cardText',
	'modularMarketingContent.cardText',
] as const

export type BenefitPlanCardFieldResult = Pick<
	BenefitPlanV2,
	Exclude<BenefitPlanCardKeys, 'effectiveMarketingContent.cardText' | 'modularMarketingContent.cardText'>
>

type BenefitPlanCardKeys = (typeof BENEFIT_PLAN_CARD_ATTRIBUTES)[number]
