import { useAppContext } from 'app/contexts/app-context'
import { useCallback } from 'react'
export enum Environments {
	DEV = 'dev',
	INT = 'int',
	LOCAL = 'local',
	PROD = 'prod',
	UAT = 'test',
}

export function useGetEnv() {
	const { site } = useAppContext()

	return useCallback(() => site.env, [site.env])
}

export function useIsProd(): () => boolean {
	const { site } = useAppContext()

	return useCallback(() => site.env === Environments.PROD, [site.env])
}

export function useIsProdOrUat(): () => boolean {
	const { site } = useAppContext()

	return useCallback(() => site.env === Environments.PROD || site.env === Environments.UAT, [site.env])
}
