import { createSelector } from 'reselect'
import { RootState } from 'store/rootReducer'
import { getSingleId } from 'store/utils'
import { match } from 'ts-pattern'
import EnrollmentEvent from 'types/enrollment-event'
import { SavingsCalculatorRatesRequest } from 'types/rates'

import { AUTOHOME, BenefitPlansState, BenefitPlanV2, NATIVE, OEWindow, SplitPlans } from './types'

export const benefitPlansState = (state: RootState): BenefitPlansState => state.benefitPlans

const isParentPlanEnrolled = (grandfatheredPlan: BenefitPlanV2, bps: BenefitPlanV2[]) => {
	if (grandfatheredPlan.parentPlanId) {
		const parentPlan = bps.find((bp) => bp.benefitPlanId === grandfatheredPlan.parentPlanId)
		if (parentPlan && parentPlan.isEnrolled) {
			return true
		}
	}

	return false
}

const removeGrandfatheredOrParentPlans = (benefitPlans: BenefitPlanV2[] = []): BenefitPlanV2[] => {
	// We need to remove any that are of status 3 (grandfathered) if not currently enrolled in
	// If they are enrolled in one, we need to remove the one that is the parent of the grandfather plan
	const benefitPlansToRemove: number[] = []

	for (const benefitPlan of benefitPlans) {
		if (benefitPlan.status === 3) {
			// Handle case where currently enrolled in grandfathered plan
			if (
				benefitPlan.isEnrolled &&
				!benefitPlan.isOverriddenByParent &&
				!isParentPlanEnrolled(benefitPlan, benefitPlans)
			) {
				if (benefitPlan.parentPlanId) {
					benefitPlansToRemove.push(benefitPlan.parentPlanId)
				}
				// Handle case where not enrolled in grandfathered plan or are attempting to enroll in parent plan
			} else {
				benefitPlansToRemove.push(benefitPlan.benefitPlanId)
			}
		}
	}

	if (benefitPlansToRemove.length > 0) {
		return benefitPlans.filter((bp) => !benefitPlansToRemove.includes(bp.benefitPlanId))
	}

	return benefitPlans
}

const getAvailablePlansFunc = (state: BenefitPlansState) => removeGrandfatheredOrParentPlans(state.availablePlans)

export const selectAvailablePlans = createSelector(benefitPlansState, getAvailablePlansFunc)
export const selectAllPlans = createSelector(benefitPlansState, (state) => state.availablePlans)
export const selectBenefitPlansFetchStatus = createSelector(
	benefitPlansState,
	(state: BenefitPlansState) => state.requestStates.benefitPlansFetchStatus,
)

export const selectIsbenefitPlanInitLoading = createSelector(
	benefitPlansState,
	(state: BenefitPlansState) => state.requestStates.isbenefitPlanInitLoading,
)

export const selectCalculatedTokens = createSelector(
	benefitPlansState,
	(state: BenefitPlansState) => state.calculatedTokens,
)

export const selectEligiblePlanIds = createSelector(benefitPlansState, (state: BenefitPlansState) =>
	state.availablePlans.map((plan) => plan.benefitPlanId),
)
export const selectAllPlansEligibleForLifeChangeEvent = createSelector(benefitPlansState, (state: BenefitPlansState) =>
	state.availablePlans.filter((plan) => plan.isAvailableForLifeChangeEvent),
)
export const selectIsOE = createSelector(benefitPlansState, (state: BenefitPlansState) => state.isOE)
export const selectOEWindowEndDate = createSelector(
	benefitPlansState,
	(state: BenefitPlansState) => state.oeWindow?.endDate,
)
export const selectDisplayEndDate = createSelector(
	benefitPlansState,
	(state: BenefitPlansState) => state.oeWindow?.displayEndDate,
)
export const selectOEWindow = createSelector(
	benefitPlansState,
	(state: BenefitPlansState): null | OEWindow | undefined => state.oeWindow,
)
export const selectOEWindows = createSelector(benefitPlansState, (state: BenefitPlansState) => state.oeWindows)
export const selectOEWindowsFetchStatus = createSelector(
	benefitPlansState,
	(state: BenefitPlansState) => state.requestStates.oeWindowsFetchStatus,
)
export const selectPetPlanIds = createSelector(selectAvailablePlans, (availablePlans) =>
	availablePlans.flatMap(({ benefitPlanId, benefitPlanType, benefitProviderName }) =>
		benefitPlanType.toLowerCase().includes('pet') && benefitProviderName.toLowerCase().includes('nationwide')
			? benefitPlanId
			: [],
	),
)

export const selectSplitPlans = createSelector(
	[benefitPlansState, selectAvailablePlans],
	(state: BenefitPlansState, availablePlans: BenefitPlanV2[]) => {
		const splitPlans: SplitPlans = availablePlans.reduce(
			(acc: any, plan) => {
				if (state.oeWindow?.oeWindowPlans[plan.benefitPlanId]) {
					acc.oePlans.push(plan)
					// look for at least one native plan to enable oe hero CTA
					if (!acc.hasNativeInOe && plan.enrollmentMethod === NATIVE) acc.hasNativeInOe = true
				} else acc.anytimePlans.push(plan)

				return acc
			},
			{ anytimePlans: [], hasNativeInOe: false, oePlans: [] },
		)

		// Native plans appear first, followed by non-native plans.
		// Plans are then sorted by their rank.TODO: clean up when we migrate all to new wizard
		if (splitPlans.oePlans.length) {
			splitPlans.oePlans.sort((x, y) => {
				if (x.isNative && !y.isNative) {
					return -1
				} else if (!x.isNative && y.isNative) {
					return 1
				} else {
					return x.rank - y.rank
				}
			})
		}

		return splitPlans
	},
)
export const selectMoreForYouBenefitSuggestions = createSelector(
	benefitPlansState,
	getSingleId,
	(state, currentlyViewBenefitPlanId) =>
		removeGrandfatheredOrParentPlans(state.availablePlans).filter(
			(plan) => !plan.isEnrolled && plan.benefitPlanId !== currentlyViewBenefitPlanId,
		),
)

const getAutoHomeCount = (state: BenefitPlansState): number =>
	state.availablePlans.reduce((acc: number, curr: BenefitPlanV2) => {
		if (curr.benefitPlanType.toLowerCase() === AUTOHOME) {
			return ++acc
		}

		return acc
	}, 0)

export const getAutoHomeCountSelector = createSelector(benefitPlansState, getAutoHomeCount)
export const getByCategoryName = (state, categoryName) =>
	state.availablePlans.filter((plan) => plan.benefitPlanType === categoryName)
export const selectMenuBenefitCategories = createSelector(benefitPlansState, (state) => {
	const planTypeCounts: Record<string, number> = {}
	state.availablePlans.forEach((plan) => {
		const pType = plan.benefitPlanType
		if (planTypeCounts[pType]) planTypeCounts[pType]++
		else planTypeCounts[pType] = 1
	})
	const allPlanTypes = Object.keys(planTypeCounts)
	const duplicatePlanTypes = Object.keys(planTypeCounts).filter((pType) => planTypeCounts[pType] > 1)

	return { allPlanTypes, duplicatePlanTypes }
})
export const selectBenefitsByCategory = createSelector(benefitPlansState, getSingleId, getByCategoryName)
const autoHomeFilter = (plans: BenefitPlanV2[]) =>
	plans.filter((plan) => plan.benefitPlanType.toLowerCase() === AUTOHOME)

export const isEnrolledAutoHomeSelector = createSelector(
	benefitPlansState,
	(state) =>
		state.availablePlans.findIndex((plan) => plan.benefitPlanType.toLowerCase() === AUTOHOME && plan.isEnrolled) > -1,
)

export const getLowestRank = (plans: BenefitPlanV2[]): number =>
	plans.reduce((prev, curr) => Math.min(prev, curr.rank), 100)
export const selectLowestAutoHomeRank = createSelector(
	(state: RootState) => autoHomeFilter(state.benefitPlans.availablePlans),
	getLowestRank,
)

export const selectCategoriesWithTopPlans = createSelector(
	selectAvailablePlans,
	(availablePlans: BenefitPlanV2[]): Map<string, number> => {
		const results: Map<string, number> = new Map()

		for (const plan of availablePlans) {
			if (plan.benefitPlanType && !results.get(plan.benefitPlanType)) {
				results.set(plan.benefitPlanType, plan.benefitPlanId)
			}
		}

		return results
	},
)

export const selectTemplateTokensFetchStatus = createSelector(
	benefitPlansState,
	(state: BenefitPlansState) => state.requestStates.templateTokensFetchStatus,
)

export const findPlanById = (benefitPlans: BenefitPlanV2[], id: number): BenefitPlanV2 | undefined =>
	benefitPlans.find(({ benefitPlanId }) => benefitPlanId === id)

// Searches Available Plans for a plan with the given id, but will exclude any accessory plans
export const findPrimaryPlanById = (benefitPlans: BenefitPlanV2[], id: number): BenefitPlanV2 | undefined =>
	benefitPlans.filter((plan) => !plan.benefitPlanGroupPrimaryId).find(({ benefitPlanId }) => benefitPlanId === id)

export const findPlanByProgramId = (benefitPlans: BenefitPlanV2[], id: number): BenefitPlanV2 | undefined =>
	benefitPlans.find(({ programId }) => programId === id)

export const selectPlanById = createSelector(benefitPlansState, getSingleId, (state, id) =>
	findPlanById(state.availablePlans, id),
)
export const selectPlanByProgramId = createSelector(benefitPlansState, getSingleId, (state, id) =>
	findPlanByProgramId(state.availablePlans, id),
)

export const shouldUseOE = (state): boolean => state.isOE && state?.oeWindow != null

export const getPlanAsArray = (
	state,
	{ event, isSingleProductFlow, planId, shouldReOrder } = {
		event: EnrollmentEvent.SINGLE_PRODUCT,
		isSingleProductFlow: false,
		planId: -1,
		shouldReOrder: false,
	},
): BenefitPlanV2[] => {
	const availablePlans = getAvailablePlansFunc(state)
	const updatedPlanId = planId > -1 ? planId : state.activePlan?.benefitPlanId ?? -1
	const updatedEvent = state.event ?? event
	let tempArray = match({
		event: updatedEvent,
		hasPlanId: updatedPlanId > -1,
		isOE: shouldUseOE(state),
		isSingleProductFlow,
	})
		.with({ event: EnrollmentEvent.OPENENROLLMENT, isOE: true, isSingleProductFlow: false }, () =>
			availablePlans
				.filter(({ benefitPlanId }) => state.oeWindow != null && state.oeWindow.oeWindowPlans[benefitPlanId] != null)
				.sort((x, y) => x.rank - y.rank),
		)
		.with({ event: EnrollmentEvent.LIFECHANGE, isSingleProductFlow: false }, () =>
			availablePlans.filter((plan: BenefitPlanV2) => plan.isAvailableForLifeChangeEvent && !plan.isEvergreen),
		)
		.with({ event: EnrollmentEvent.NEWHIRE, isSingleProductFlow: false }, () =>
			availablePlans.filter(
				(plan: BenefitPlanV2) => plan.isAvailableForNewhires || (plan.isEvergreen && plan.isNative),
			),
		)
		.with({ hasPlanId: true }, () => [findPlanById(availablePlans, updatedPlanId)].filter((x) => x) as BenefitPlanV2[])
		.otherwise(() => [])

	if ((shouldReOrder || state.firstPlanInOrder > -1) && tempArray.length > 1) {
		const benefitPlanIndex = tempArray?.findIndex((bp) => bp.benefitPlanId === state.firstPlanInOrder)
		tempArray?.unshift(tempArray.splice(Number(benefitPlanIndex), 1)[0])
	}

	if (state.filterPlanIds?.length) {
		tempArray = tempArray.filter(({ benefitPlanId }) => !state.filterPlanIds.includes(benefitPlanId))
	}

	return tempArray.filter((plan) => plan.enrollmentMethod.toLowerCase() === 'native')
}

export const getFirstNativeOEPlanId = (state) => {
	const availablePlans = getAvailablePlansFunc(state)
	const oePlans = availablePlans
		.filter(
			({ benefitPlanId, isNative }) =>
				state.oeWindow != null && state.oeWindow.oeWindowPlans[benefitPlanId] != null && isNative,
		)
		.sort((x, y) => x.rank - y.rank)

	return oePlans[0]?.benefitPlanId
}

export const getPlanEffectiveDate = (state, id) => findPlanById(state.availablePlans, id)?.keyDate ?? ''
export const selectPlanEffectiveDateById = createSelector(benefitPlansState, getSingleId, getPlanEffectiveDate)

//Conditional logic to show QLE/New Hire card on generic home hero for V2 clients
export const hasNewHireQLESelector = createSelector(benefitPlansState, (state: BenefitPlansState) =>
	state.availablePlans.some(
		({ isAvailableForLifeChangeEvent, isAvailableForNewhires, isEvergreen }) =>
			(isAvailableForNewhires || isAvailableForLifeChangeEvent) && !isEvergreen,
	),
)

export const savingsCalculatorDtoSelector = createSelector(
	benefitPlansState,
	({ availablePlans }: BenefitPlansState) => {
		//Group plans by their Category(CorestreamProductId) and select their latest key dates.
		const reducedPlans = availablePlans
			.filter((plan) => plan.isNative)
			.reduce<Array<{ corestreamProductId: string; keyDate: Date | string }>>((acc, plan) => {
				if (!plan.keyDate) return acc

				const product = acc.find((element) => element.corestreamProductId === plan.corestreamProductId)
				if (product) {
					const existingDate = new Date(product.keyDate)
					const incomingDate = new Date(plan.keyDate)
					product.keyDate = existingDate > incomingDate ? existingDate : incomingDate
				} else {
					acc.push({ corestreamProductId: plan.corestreamProductId, keyDate: plan.keyDate })
				}

				return acc
			}, [])

		const request: SavingsCalculatorRatesRequest = {
			categories: reducedPlans,
		}

		return request
	},
)
