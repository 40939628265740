import { CircularProgress } from '@mui/material'
import { cx } from 'class-variance-authority'
import { FC } from 'react'

import styles from './loading.module.css'

interface LoadingProps {
	className?: string
	size?: number | string
}

const Loading: FC<LoadingProps> = ({ className, size }: LoadingProps) => {
	return (
		<div className={cx(styles['loading'], className)}>
			<CircularProgress color='primary' id='loading-indicator' size={size} />
		</div>
	)
}

export default Loading
