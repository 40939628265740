import { Navigate, Route, Routes } from 'react-router-dom'

import { Theme } from './theme'

export const Themes = () => {
	return (
		<Routes>
			<Route element={<Navigate replace to='/home' />} path='*' />
			<Route element={<Theme />} path={'/:themeName'} />
		</Routes>
	)
}
