import { LocalStorageExpiryItem } from './types'

// A list of tracked local storage items
export const LOCAL_STORAGE_KEYS = {
	CompletedPostLoginLogic: 'hasCompletedPostLoginLogic',
	IsImpersonation: 'isImpersonation',
	SeenWizardIntroStep: 'hasSeenWizardIntroStep',
} as const

// Clear all local storage items
export function clearAllLocalStorageItems(): void {
	Object.values(LOCAL_STORAGE_KEYS).forEach((key) => {
		localStorage.removeItem(key)
	})
}

// Get the value from local storage with expiry
export function getLocalStorageWithExpiry(key: string): null | string {
	const itemStr = localStorage.getItem(key)
	if (!itemStr) return null

	try {
		const item: LocalStorageExpiryItem = JSON.parse(itemStr)

		// Check if parsed item matches StorageItem shape
		if ('value' in item && 'expiry' in item) {
			if (Date.now() > item.expiry) {
				localStorage.removeItem(key)

				return null
			}

			return item.value
		}

		// If not a StorageItem format, return the original string
		return itemStr
	} catch {
		// If not valid JSON, return the original string
		return itemStr
	}
}

// Set the value in local storage with expiry
export function setLocalStorageWithExpiry(key: string, value: string, expiryDays: number) {
	const item: LocalStorageExpiryItem = {
		expiry: Date.now() + expiryDays * 24 * 60 * 60 * 1000,
		value,
	}
	localStorage.setItem(key, JSON.stringify(item))
}
