export enum Relationship {
	CHILD = 'Child',
	DEPENDENT = 'Dependent',
	PET = 'Pet',
	SPOUSE = 'Spouse',
}

export interface Dependent {
	birthDate: Date | string
	dependentId: string
	firstName: string
	gender: string
	id?: number
	isActive: boolean
	isDisabled?: boolean
	isOfAge?: boolean
	isStudent?: boolean
	isTobacco: boolean | undefined
	lastName: string
	middleName?: string
	nameSuffix?: string
	phone?: string
	relationship: Relationship
	socialSecurityNumber?: string
}

export type PartialDependent = Pick<
	Dependent,
	| 'birthDate'
	| 'dependentId'
	| 'firstName'
	| 'gender'
	| 'isDisabled'
	| 'isOfAge'
	| 'isStudent'
	| 'isTobacco'
	| 'lastName'
	| 'relationship'
	| 'socialSecurityNumber'
>

export interface PetDependentReceipt {
	amount: number
	coverage: string
	coverageName: string
	effectiveDate: string
	frequency: string
	isPushed: boolean
	petName: string
}

export interface PetDependentResponseDTO {
	frequency: string
	pendingTotalCost: number
	pets: PetDependentDTO[]
	pushedTotalCost: number
}

interface PetDependentBreedDTO {
	coat?: string
	code?: string
	name?: string
	size?: string
	type?: string
}

interface PetDependentDTO {
	age: number
	amount: number
	breed: PetDependentBreedDTO
	coverageCode: string
	coverageName: string
	effectiveDate: string
	isPushed: boolean
	nationwidePetId: string
	petName: string
	policyNumber?: string
	species: string
}
