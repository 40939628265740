import { Button, Typography } from '@mui/material'
import { cx } from 'class-variance-authority'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { generatePath } from 'react-router'
import { benefitRoutes } from 'routes/routes-map'

import { BenefitSectionProps } from '../../types'
import styles from './benefit-section.module.css'

export function BenefitSection({ benefit, benefitContent, variant = 'v1' }: BenefitSectionProps) {
	const track = useTrackDetailedPageEvent()

	const ctaUrlRoute = benefit.isNative
		? generatePath(benefitRoutes.benefit.plan(benefit.benefitPlanId))
		: benefit.effectiveMarketingContent?.ctaUrl

	const onBenefitClick = () => {
		track({
			actionType: 'click',
			benefitPlanId: benefit.benefitPlanId,
			elementName: 'benefit cta',
			elementType: 'button',
		})
	}

	return (
		<div className={cx(styles.benefit, styles[`benefit-${variant}`])}>
			<div className={styles['benefit-content']}>
				{variant === 'v1' && (
					<div className={styles['benefit-header']}>
						<img alt='img' className={styles['benefit-header-image']} src={benefitContent.imageLink} />
					</div>
				)}
				<div className={styles['benefit-content-wrapper']}>
					<div className={styles['benefit-body']}>
						<img alt='img' className={styles['benefit-image']} src={benefit?.benefitProviderImage} />
						<Typography className={styles['benefit-title']} variant='h2'>
							{benefitContent.title}
						</Typography>
					</div>
					<div className={styles['benefit-details']}>
						<div className={styles['benefit-details-copy']}>
							{benefitContent.subtitles.map((subtitle, index) => (
								<Typography key={index} className={styles['benefit-subtitle']} variant='h5-body'>
									{subtitle}
								</Typography>
							))}
							{benefitContent.code && benefitContent.codeMessage && (
								<Typography className={styles['benefit-subtitle']} variant='h5-body'>
									{formatCodeMessage(benefitContent.code, benefitContent.codeMessage)}
								</Typography>
							)}
						</div>
						<div className={styles['benefit-cta-container']}>
							{benefitContent.cta.visible && (
								<Button
									className={styles['benefit-cta']}
									color='inherit'
									href={ctaUrlRoute ?? ''}
									target={benefit.isNative ? '_self' : '_blank'}
									variant='outlined'
									onClick={onBenefitClick}
								>
									{benefitContent.cta.title}
								</Button>
							)}
						</div>
					</div>
				</div>
				{variant === 'v2' && (
					<div className={styles['benefit-header']}>
						<img alt='img' className={styles['benefit-header-image']} src={benefitContent.imageLink} />
					</div>
				)}
			</div>
		</div>
	)
}

function formatCodeMessage(code: string, codeMessage: string) {
	const [before, after] = codeMessage.split('${code}')

	return (
		<>
			{before}
			<strong>{code}</strong>
			{after}
		</>
	)
}
