import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { isAccessoryPlan } from 'utils/benefit-plans'

/**
 * Sort accessory plans by benefitPlanRank directly after their parent,
 * maintaining existing order for non-accessory plans
 */

export const applyAccessoryPlanSorting = (benefitPlans: BenefitPlanV2[]) => {
	// Group accessory plans by their primary ID and sort by rank descending
	const accessoryPlansByPrimary = benefitPlans.reduce((acc, plan) => {
		if (isAccessoryPlan(plan)) {
			const primaryId = plan.benefitPlanGroupPrimaryId!
			acc.set(primaryId, [...(acc.get(primaryId) || []), plan])
		}

		return acc
	}, new Map<number, BenefitPlanV2[]>())

	// Sort each group by rank ascending (lower numbers first since they are higher rank)
	for (const plans of accessoryPlansByPrimary.values()) {
		plans.sort((a, b) => a.benefitPlanRank - b.benefitPlanRank)
	}

	// Start with non-accessory plans
	const standardPlans = benefitPlans.filter((p) => !isAccessoryPlan(p))

	// Insert each group of accessory plans after their primary
	for (const [primaryId, plans] of accessoryPlansByPrimary) {
		const primaryIndex = standardPlans.findIndex((plan) => plan.benefitPlanId === primaryId)
		if (primaryIndex !== -1) {
			standardPlans.splice(primaryIndex + 1, 0, ...plans)
		}
	}

	return standardPlans
}
