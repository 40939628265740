import { chipClasses } from '@mui/material/Chip'
import { alpha, CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

// const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const

// NEW VARIANT
declare module '@mui/material/Chip' {
	interface ChipPropsVariantOverrides {
		soft: true
	}
}

// ----------------------------------------------------------------------

export function chip(): CssVarsThemeOptions['components'] {
	return {
		MuiChip: {
			styleOverrides: {
				root: ({ ownerState, theme }) => {
					const defaultColor = ownerState.color === 'default'

					const filledVariant = ownerState.variant === 'filled'

					const outlinedVariant = ownerState.variant === 'outlined'

					const softVariant = ownerState.variant === 'soft'

					const defaultStyle = {
						[`& .${chipClasses.deleteIcon}`]: {
							'&:hover': {
								color: 'currentColor',
								opacity: 1,
							},
							color: 'currentColor',
							opacity: 0.48,
						},

						...(defaultColor && {
							[`& .${chipClasses.avatar}`]: {
								color: theme.palette.text.primary,
							},
							// FILLED
							...(filledVariant && {
								'&:hover': {
									backgroundColor: theme.palette.grey[700],
								},
								[`& .${chipClasses.icon}`]: {
									color: theme.palette.common.white,
								},
								backgroundColor: theme.palette.text.primary,
								color: theme.palette.common.white,
							}),
							// OUTLINED
							...(outlinedVariant && {
								border: `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
							}),
							// SOFT
							...(softVariant && {
								'&:hover': {
									backgroundColor: alpha(theme.palette.grey[500], 0.32),
								},
								backgroundColor: alpha(theme.palette.grey[500], 0.16),
								color: theme.palette.text.primary,
							}),
						}),
					}

					// const colorStyle = COLORS.map((color) => ({
					// 	...(ownerState.color === color && {
					// 		[`& .${chipClasses.avatar}`]: {
					// 			backgroundColor: theme.palette[color].dark,
					// 			color: theme.vars.palette[color].lighter,
					// 		},
					// 		// SOFT
					// 		...(softVariant && {
					// 			'&:hover': {
					// 				backgroundColor: alpha(theme.palette[color].main, 0.32),
					// 			},
					// 			backgroundColor: alpha(theme.palette[color].main, 0.16),
					// 			color: theme.palette[color][lightMode ? 'dark' : 'light'],
					// 		}),
					// 	}),
					// }))

					const disabledState = {
						[`&.${chipClasses.disabled}`]: {
							[`& .${chipClasses.avatar}`]: {
								backgroundColor: theme.palette.action.disabledBackground,
								color: theme.palette.action.disabled,
							},
							[`& .${chipClasses.icon}`]: {
								color: theme.palette.action.disabled,
							},
							color: theme.palette.action.disabled,
							opacity: 1,
							// FILLED
							...(filledVariant && {
								backgroundColor: theme.palette.action.disabledBackground,
							}),
							// OUTLINED
							...(outlinedVariant && {
								borderColor: theme.palette.action.disabledBackground,
							}),
							// SOFT
							...(softVariant && {
								backgroundColor: theme.palette.action.disabledBackground,
							}),
						},
					}

					return [
						defaultStyle,
						// ...colorStyle,
						disabledState,
						{
							borderRadius: theme.shape.borderRadius,
							fontWeight: 500,
						},
					]
				},
			},
		},
	}
}
