import { Button, Typography } from '@mui/material'
import { cx } from 'class-variance-authority'
import { OfferModal } from 'components/offer/offer-modal'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { useState } from 'react'
import { routesMap } from 'routes/routes-map'

import { Deal, DealsListProps } from '../../types'
import styles from './deals-list.module.css'

export function DealsList({ className, cta, deals, limit, offerObjectIDs, title, variant = 'small' }: DealsListProps) {
	const [showModal, setShowModal] = useState<boolean>(() => false)
	const [selectedOfferId, setSelectedOfferId] = useState<null | string>(() => null)
	const track = useTrackDetailedPageEvent()
	let dealsCount = 0

	if (!deals) return null

	const onDealClick = (deal: Deal) => {
		track({
			actionType: 'click',
			discountOfferId: deal.offerId,
			elementName: 'offer view cta',
			elementType: 'button',
		})
		setSelectedOfferId(offerObjectIDs.find((o) => o.toLocaleLowerCase() === deal.offerId.toLocaleLowerCase()) ?? null)
		setShowModal(true)
	}

	const onAllDealsClick = () => {
		track({
			actionType: 'click',
			elementDetails: 'deals',
			elementName: 'see all deals',
			elementType: 'button',
		})
	}

	return (
		<>
			<div className={styles[className]}>
				<div className={styles[`${className}-content`]}>
					<Typography className={styles[`${className}-title`]} variant='h2'>
						{title}
					</Typography>
					<div className={styles.deals}>
						{deals.map((deal, index) => {
							if (
								dealsCount < limit &&
								offerObjectIDs.some((o) => o.toLocaleLowerCase() === deal.offerId.toLocaleLowerCase())
							) {
								dealsCount++

								return (
									<Button
										key={index}
										className={cx(styles.deal, styles[`deal-${variant}`])}
										color='inherit'
										variant='text'
										onClick={() => onDealClick(deal)}
									>
										<img alt={`deal ${index}`} className={styles['deal-image']} src={deal.imageLink} />
										<div className={styles['deal-content']}>
											<Typography className={styles['deal-heading']} variant='h3-bold'>
												{deal.heading}
											</Typography>
											<Typography
												className={styles['deal-description']}
												dangerouslySetInnerHTML={{ __html: deal.description }}
												variant='h5'
											/>
										</div>
									</Button>
								)
							}
						})}
					</div>
					{cta.visible && (
						<Button
							className={styles['deal-cta']}
							color='inherit'
							href={routesMap.search + '?category=' + encodeURIComponent(cta.category ?? '')}
							variant='outlined'
							onClick={onAllDealsClick}
						>
							{cta.title}
						</Button>
					)}
				</div>
			</div>
			{selectedOfferId && (
				<OfferModal
					offerObjectID={selectedOfferId}
					showModal={showModal}
					hideModal={(e: { stopPropagation: () => void }): void => {
						e.stopPropagation()
						setShowModal(false)
					}}
				/>
			)}
		</>
	)
}
