import { cx } from 'class-variance-authority'

import './block-ui.scss'

import { Loading } from 'components/loading'

type Props = {
	className?: string
	message?: string
	shouldBlock: boolean
	showSpinner?: boolean
}

export default function BlockUI({ className, message, shouldBlock = false, showSpinner = false }: Props) {
	if (!shouldBlock) {
		return null
	}

	const contentClassNames = cx('block-ui-content', { 'block-ui-content-with-message': message }, className)

	const getBlockUiContent = () => {
		return (
			<>
				<Loading className='block-ui-spinner' />
				{message && <div className='block-ui-message'>{message}</div>}
			</>
		)
	}

	return (
		<div className='block-ui-container'>
			<div className='block-ui-overlay' />
			<div className={contentClassNames}>{showSpinner && getBlockUiContent()}</div>
		</div>
	)
}
