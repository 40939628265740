type MetaVersionResponse =
	| {
			error: string
			version?: never
	  }
	| {
			error?: never
			version: string
	  }

const META_VERSION_DEFAULTS = {
	FETCH_ERROR: 'Failed to fetch version from meta.json',
	UNKNOWN_VERSION: 'unknown',
} as const

export async function fetchMetaVersion(options?: { showLogs?: boolean }): Promise<MetaVersionResponse> {
	const timestamp = new Date().getTime()
	const log = createLogger('[meta-version]', options?.showLogs)

	try {
		const response = await fetch(`/meta.json?${timestamp}`, { cache: 'no-cache' })

		const contentType = response.headers.get('content-type')
		if (!contentType?.includes('application/json')) {
			const error = 'Received non-JSON response from meta.json'
			log(error, true)

			return { error }
		}

		const { version } = await response.json()
		if (!version) {
			const error = 'No version found in meta.json'
			log(error, true)

			return { error }
		}

		log(`Successfully fetched version: ${version}`)

		return { version }
	} catch (error) {
		const errorMessage = error instanceof Error ? error.message : META_VERSION_DEFAULTS.FETCH_ERROR
		log(errorMessage, true)

		return { error: errorMessage }
	}
}

function createLogger(prefix: string, showLogs = false) {
	return (message: string, isError = false) => {
		if (!showLogs) return

		const prefixedMessage = `${prefix} ${message}`
		if (isError) {
			// eslint-disable-next-line no-console
			console.warn(prefixedMessage)
		} else {
			// eslint-disable-next-line no-console
			console.log(prefixedMessage)
		}
	}
}
