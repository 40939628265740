import { listClasses } from '@mui/material/List'
import { CssVarsThemeOptions } from '@mui/material/styles'

import { paper } from '../../css'

// ----------------------------------------------------------------------

export function popover(): CssVarsThemeOptions['components'] {
	return {
		MuiPopover: {
			styleOverrides: {
				paper: ({ theme }) => ({
					...paper({ dropdown: true, theme }),
					[`& .${listClasses.root}`]: {
						paddingBottom: 0,
						paddingTop: 0,
					},
				}),
			},
		},
	}
}
