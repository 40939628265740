import { useMemo } from 'react'
import { matchPath, useLocation, useMatches } from 'react-router'

export const useRouteSpec = (): null | string => {
	const location = useLocation()
	const matches = useMatches()

	const match = useMemo(() => {
		if (!location?.pathname) return null

		for (let i = 0; i < matches.length; i++) {
			const loop = matches[i]
			if (matchPath(loop.pathname, location.pathname)) {
				return loop.pathname
			}
		}

		return null
	}, [location?.pathname, matches])

	return match
}
