import { CssVarsThemeOptions } from '@mui/material/styles'

export function alertOverrides(): CssVarsThemeOptions['components'] {
	return {
		MuiAlert: {
			styleOverrides: {
				icon: {
					opacity: 1,
				},
				root: ({ ownerState }) => {
					switch (ownerState.severity) {
						case 'error':
							return {
								backgroundColor: 'var(--states-error)',
							}
						case 'info':
						case 'warning':
							return {
								backgroundColor: 'var(--states-pending)',
							}
						case 'success':
						default:
							return {
								backgroundColor: 'var(--states-enrolled)',
							}
					}
				},
			},
		},
		MuiAlertTitle: {
			styleOverrides: {
				root: ({ theme }) => ({
					fontWeight: theme.typography.fontWeightMedium,
					marginBottom: theme.spacing(0.5),
				}),
			},
		},
	}
}
