import { createContext, useContext } from 'react'
import { SiteInfoResponse } from 'types/tenant-site-types'

import { SiteEnvironment } from '../types'

export type AppContextState = {
	readonly site: SiteEnvironment
	readonly siteInfo: SiteInfoResponse
}

// private context - only exposed through hook and provider
const AppContext = createContext({} as AppContextState)

export type AppContextProviderProps = {
	children: React.ReactNode
	values: AppContextState
}

export function AppContextProvider({ children, values }: AppContextProviderProps) {
	return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

export function useAppContext() {
	const context = useContext(AppContext)
	if (!context) {
		throw new Error('useAppContext must be used within a <AppContextProvider> component')
	}

	return context
}
