import { List, ListItemButton, ListItemText, TypographyProps } from '@mui/material'
import { useMobileDisplay } from 'hooks/use-mobile-display'
import { useMemo } from 'react'
import { LinkBehavior } from 'theme/routing/LinkBehavior'

import styles from './mega-menu.module.scss'
import { MegaMenuItem } from './types'

type Props = {
	handleClose: (isSelectionMade: boolean) => void
	hideLeftBorder: boolean
	isSupplementalItems: boolean
	items: MegaMenuItem[]
	typographyProps: TypographyProps<'p', { component?: 'p' }>
}

export const MegaMenuColumn = ({ handleClose, hideLeftBorder, isSupplementalItems, items, typographyProps }: Props) => {
	const isMobile = useMobileDisplay()
	const listStyles = useMemo(
		() => {
			const styleString = isMobile ? styles['mega-menu-mobile-list'] : styles['mega-menu-list']

			if (isSupplementalItems) {
				return isMobile
					? styleString.concat(' ', styles['mega-menu-mobile-strong'])
					: styleString.concat(' ', styles['mega-menu-strong'])
			}

			return styleString
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isMobile],
	)

	const handleItemClicked = (item) => {
		if (item.onClick) {
			item.onClick()
			handleClose(true)
		}
	}

	return items.length < 1 ? null : (
		<List key={self.crypto.randomUUID()} className={listStyles} disablePadding={isSupplementalItems}>
			{items.map((item, index) => (
				<li key={`${item.label}${index}`}>
					<ListItemButton component={LinkBehavior} href={item.href} onClick={() => handleItemClicked(item)}>
						<ListItemText primary={item.label} primaryTypographyProps={typographyProps} />
					</ListItemButton>
				</li>
			))}
			{!hideLeftBorder && <div className={styles['mega-menu-vertical-divider']} role='presentation' />}
		</List>
	)
}
