// NOTE: UNKNOW CANNOT BE CHANGED TO UNKNOWN - DO NOT TOUCH
// IF DECLINED, SET TO DECLINED, OTHERWISE, DO NOT TOUCH

export enum ElectionStatus {
	CANCELLED = 'CANCELLED',
	DECLINED = 'DECLINED',
	ENROLLED = 'ENROLLED',
	//specific to product with EOI requirements(bp.shouldRequireEvidenceOfInsurability)
	PENDING = 'PENDING',
	TERMINATED = 'TERMINATED',
}

export enum EnrollmentStatus {
	CANCELED = 'Canceled',
	DECLINED = 'Declined',
	ENROLLED = 'Enrolled',
	INELIGIBLE = 'Ineligible',
	LEAVED = 'Leaved', // user revisited wizard and did not change existing selections
	NEW = 'New',
	//specific to product with EOI requirements(bp.shouldRequireEvidenceOfInsurability)
	PENDING = 'Pending',
	TERMINATED = 'Terminated',
	UNKNOWN = 'Unknow',
}
