import { filledInputClasses } from '@mui/material/FilledInput'
import { inputBaseClasses } from '@mui/material/InputBase'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'
import { alpha, CssVarsTheme, CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function textField(): CssVarsThemeOptions['components'] {
	const color = (theme: CssVarsTheme) => ({
		active: theme.vars.palette.text.secondary,
		focused: theme.vars.palette.text.primary,
		placeholder: theme.vars.palette.text.disabled,
	})

	const font = (theme: CssVarsTheme) => ({
		label: theme.typography.body1,
		value: theme.typography.body2,
	})

	return {
		// FILLED
		MuiFilledInput: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&:hover': {
						backgroundColor: alpha(theme.palette.grey[500], 0.16),
					},
					[`&.${filledInputClasses.disabled}`]: {
						[`& .${inputBaseClasses.input}`]: {
							'-webkit-text-fill-color': theme.palette.grey[700],
							color: theme.palette.grey[700], // Fallback
						},
						backgroundColor: theme.palette.grey[300],
					},
					[`&.${filledInputClasses.error}`]: {
						[`&.${filledInputClasses.focused}`]: {
							backgroundColor: alpha(theme.palette.error.main, 0.16),
						},
						backgroundColor: alpha(theme.palette.error.main, 0.08),
					},
					[`&.${filledInputClasses.focused}`]: {
						backgroundColor: alpha(theme.palette.grey[500], 0.16),
					},
					backgroundColor: alpha(theme.palette.grey[500], 0.08),
					borderRadius: theme.shape.borderRadius,
				}),
			},
		},

		// HELPER
		MuiFormHelperText: {
			styleOverrides: {
				root: ({ theme }) => ({
					marginTop: theme.spacing(1),
				}),
			},
		},

		// LABEL
		MuiFormLabel: {
			styleOverrides: {
				root: ({ theme }) => ({
					...font(theme).value,
					[`&.${inputLabelClasses.shrink}`]: {
						...font(theme).label,
						[`&.${inputLabelClasses.disabled}`]: {
							color: theme.palette.text.disabled,
						},
						[`&.${inputLabelClasses.error}`]: {
							color: theme.palette.error.main,
						},
						[`&.${inputLabelClasses.filled}`]: {
							transform: 'translate(12px, 6px) scale(0.75)',
						},
						[`&.${inputLabelClasses.focused}`]: {
							color: color(theme).focused,
						},
						color: color(theme).active,
						fontWeight: 600,
					},
					color: color(theme).placeholder,
				}),
			},
		},

		// STANDARD
		MuiInput: {
			styleOverrides: {
				underline: ({ theme }) => ({
					'&:after': {
						borderBottomColor: color(theme).focused,
					},
					'&:before': {
						borderBottomColor: alpha(theme.palette.grey[500], 0.32),
					},
				}),
			},
		},

		// BASE
		MuiInputBase: {
			styleOverrides: {
				input: ({ theme }) => ({
					...font(theme).value,
					'&::placeholder': {
						color: color(theme).placeholder,
						opacity: 1,
					},
				}),
				root: ({ theme }) => ({
					[`&.${inputBaseClasses.disabled}`]: {
						'& svg': {
							color: theme.palette.text.disabled,
						},
					},
				}),
			},
		},

		// OUTLINED
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: ({ theme }) => ({
					borderColor: alpha(theme.palette.grey[500], 0.2),
					transition: theme.transitions.create(['border-color'], {
						duration: theme.transitions.duration.shortest,
					}),
				}),
				root: ({ theme }) => ({
					[`&.${outlinedInputClasses.disabled}`]: {
						[`& .${outlinedInputClasses.notchedOutline}`]: {
							borderColor: theme.palette.action.disabledBackground,
						},
					},
					[`&.${outlinedInputClasses.error}`]: {
						[`& .${outlinedInputClasses.notchedOutline}`]: {
							borderColor: theme.palette.error.main,
						},
					},
					[`&.${outlinedInputClasses.focused}`]: {
						[`& .${outlinedInputClasses.notchedOutline}`]: {
							borderColor: color(theme).focused,
						},
					},
				}),
			},
		},
	}
}
