export const API_SUBDOMAIN = 'tenants'
export const API_FEATURE = 'Tracking'

export const endpoints = {
	BenefitElections: getBenefitElectionsEndpoints(),
	IntegrationPartners: getIntegrationPartnersEndpoints(),
	NewHire: getNewHireEndpoints(),
	Rates: getRatesEndpoints(),
}

function getBenefitElectionsEndpoints() {
	const beEndpoints = {
		all: 'BenefitElections' as const,
		getQuestions: (benefitPlanId: number) => `${beEndpoints.all}/${benefitPlanId}/Questions` as const,
	}

	return beEndpoints
}

function getIntegrationPartnersEndpoints() {
	const integrationPartnersEndpoints = {
		accessTravel: {
			all: 'AccessTravel' as const,
			getSessionToken: () =>
				`${integrationPartnersEndpoints.all}/${integrationPartnersEndpoints.accessTravel.all}/SessionToken` as const,
		},
		all: 'IntegrationPartners' as const,
		trueCar: {
			all: 'TrueCar' as const,
			getCarMakes: () =>
				`${integrationPartnersEndpoints.all}/${integrationPartnersEndpoints.trueCar.all}/CarMakes` as const,
		},
	}

	return integrationPartnersEndpoints
}

function getNewHireEndpoints() {
	const newHireEndpoints = {
		all: 'NewHire' as const,
		getEligibilityWindowInformation: () => `${newHireEndpoints.all}/EligibilityWindowInformation` as const,
	}

	return newHireEndpoints
}

function getRatesEndpoints() {
	const ratesEndpoints = {
		all: 'Rates' as const,
		coverageOptionsAndLowestRates: () => `${ratesEndpoints.all}/CoverageOptionsAndLowestRates` as const,
	}

	return ratesEndpoints
}
