import type { SearchFilters } from '../../types/search'

import { LocalAlgoliaConfig } from '../../types/algolia'
import { mergeFilters } from '../filters'
import { NumericFilter } from '../numeric-filters'

export function mergeSearchConfig(...configs: (Partial<LocalAlgoliaConfig> | undefined)[]): LocalAlgoliaConfig {
	return configs.reduce<LocalAlgoliaConfig>((acc, config) => {
		if (!config) return acc

		const result = { ...acc }

		// Merge simple arrays with deduplication
		const arrayProps = ['attributesToRetrieve', 'facets'] as const
		arrayProps.forEach((prop) => {
			if (config[prop]) {
				result[prop] = [...new Set([...(acc[prop] || []), ...(config[prop] || [])])]
			}
		})

		// Merge nested arrays
		const nestedArrayProps = ['facetFilters'] as const
		nestedArrayProps.forEach((prop) => {
			if (!config[prop]) return

			if (!result[prop]) {
				result[prop] = []
			}

			const currentValue = config[prop]
			const resultArray = result[prop] as (string | string[])[]

			if (Array.isArray(currentValue?.[0])) {
				resultArray.push(...(currentValue as string[][]))
			} else {
				resultArray.push(...(currentValue as string[]))
			}

			// Type assertion to satisfy the compiler
			result[prop] = resultArray as string[] | string[][]
		})

		// Handle numericFilters
		if (config.numericFilters?.length) {
			if (!result.numericFilters) {
				result.numericFilters = []
			}
			const resultArray = result.numericFilters as (NumericFilter | NumericFilter[])[]

			if (Array.isArray(config.numericFilters[0])) {
				resultArray.push(...(config.numericFilters as NumericFilter[][]))
			} else {
				resultArray.push(...(config.numericFilters as NumericFilter[]))
			}

			result.numericFilters = resultArray as NumericFilter[] | NumericFilter[][]
		}

		// Merge filters
		if (config.filters) {
			const currentFilters = config.filters

			const prevFilters = typeof acc.filters === 'object' ? (acc.filters as SearchFilters) : {}
			result.filters = mergeFilters(prevFilters, currentFilters)
		}

		// Merge other scalar properties
		Object.entries(config).forEach(([key, value]) => {
			if (
				!arrayProps.includes(key as any) &&
				!nestedArrayProps.includes(key as any) &&
				key !== 'filters' &&
				key !== 'numericFilters'
			) {
				result[key] = value
			}
		})

		return result
	}, {} as LocalAlgoliaConfig)
}
