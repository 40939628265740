import { _useCaptureEvent, RequiredTypes } from '../useCaptureEvent'

interface LoginTrackEvent extends RequiredTypes {}

/**
 * Used for generic events that have a benefit plan Id available
 */
export const useTrackLoginEvent = () => {
	const capture = _useCaptureEvent()

	return (eventData: LoginTrackEvent) => capture(eventData)
}
