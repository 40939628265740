import { intercomScript, intercomSettings } from 'config/intercom'
import { useScript } from 'hooks/use-script'
import { useCallback, useEffect, useRef } from 'react'
import { getCurrentSubdomain } from 'services/util/subdomain-parser'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { useIsProdOrUat } from 'utils/env'
import { LocalStore } from 'utils/utils'

/**
 * initial unauthenticated intercom setup
 *
 * 'update' is called when the user logs in and appends that data to the intercom object
 *
 * 'boot' in this case should ONLY be run once, so we use a ref to track that
 *
 * @param siteInfo
 */
export const useBootIntercom = (siteInfo: SiteInfoResponse) => {
	const hasBootedIntercom = useRef(false)
	const urlParams = new URLSearchParams(window.location.search)

	const isProdOrUat = useIsProdOrUat()

	const isImpersonation: null | string = LocalStore.get('isImpersonation') || urlParams.get('Impersonation')

	// Clear any existing Intercom state from localStorage
	const teardownIntercom = useCallback(() => {
		Object.keys(localStorage).forEach((key) => {
			if (key.startsWith('intercom.intercom-state')) {
				localStorage.removeItem(key)
			}
		})
	}, [])

	const setupIntercom = useCallback(() => {
		teardownIntercom()

		window['intercomSettings'] = {
			...intercomSettings,
			// company is the only object type allowed to be passed
			company: { company_id: siteInfo.organizationId, name: siteInfo.organizationName },
			company_id: siteInfo.organizationId,
			company_site: `${getCurrentSubdomain().split('.')[0]}.corestream.com`,
			name: siteInfo.organizationName,
			org_unit_id: siteInfo.organizationUnitId,
		}

		if (typeof window.Intercom === 'function') {
			window.Intercom('boot', window['intercomSettings'])
		}
	}, [siteInfo.organizationId, siteInfo.organizationName, siteInfo.organizationUnitId, teardownIntercom])

	// run when siteInfo is present, is prod, isn't an impersonation, and only run once
	useEffect(() => {
		if (
			siteInfo.organizationId &&
			siteInfo.organizationName &&
			!hasBootedIntercom.current &&
			isProdOrUat() &&
			!isImpersonation
		) {
			setupIntercom()

			hasBootedIntercom.current = true
		} else if (isImpersonation && !hasBootedIntercom.current) {
			teardownIntercom()
			hasBootedIntercom.current = true
		}
	}, [isImpersonation, isProdOrUat, setupIntercom, siteInfo, teardownIntercom])

	useScript(intercomScript, isProdOrUat())
}
