import { Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { useAvailableOfferIdsByIdsQueryOptions } from 'api/search/queryOptions/offers'
import { useAppSelector } from 'hooks/redux'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useMemo } from 'react'
import { Navigate, useParams } from 'react-router'
import { routesMap } from 'routes/routes-map'
import { selectAvailablePlans } from 'store/benefit-plans/selectors'

import { BenefitSection } from './components/benefit-section/benefit-section'
import { DealsList } from './components/deals-list/deals-list'
import styles from './theme.module.css'

/**
 * Existing components that are unused in the new year iteration:
 * - <TravelSection {...theme.travel} />
 * - <CategorySection {...theme.category} />
 */

export function Theme() {
	const { themeName } = useParams()
	const { b4b_17199_static_theme_page: theme } = useFlags()
	const availablePlans = useAppSelector(selectAvailablePlans)

	const topDealsOfferIds = theme.top_deals.deals.map((offer) => offer.offerId)
	const moreDealsOfferIds = theme.more_deals.deals.map((offer) => offer.offerId)
	const footerDealsOfferIds = theme.footer_deals.deals.map((offer) => offer.offerId)
	const availableOffersData = useQuery(
		useAvailableOfferIdsByIdsQueryOptions([...topDealsOfferIds, ...moreDealsOfferIds, ...footerDealsOfferIds]),
	)
	const availableOffers = availableOffersData.data?.map((offer) => offer.objectID) ?? []

	const activeBenefits = useMemo(
		() =>
			theme.benefits
				.map((benefit) => ({
					content: benefit,
					plan: availablePlans.find((plan) => plan.corestreamProductId === benefit.corestreamProductId),
				}))
				.filter(({ plan }) => plan)
				.slice(0, 2),
		[theme.benefits, availablePlans],
	)

	const active1 = activeBenefits[0] ?? null
	const active2 = activeBenefits[1] ?? null

	if (theme.name !== themeName) {
		return <Navigate replace to={routesMap.home} />
	}

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<img alt='' className={styles['header-image']} src={theme.header.imageLink} />
				<div className={styles['header-content']}>
					<h1 className={styles['header-title']}>{theme.header.title}</h1>
					<Typography className={styles['header-subtitle']} variant='h3'>
						{theme.header.subtitle}
					</Typography>
				</div>
			</div>
			<div className={styles.content}>
				<DealsList {...theme.top_deals} className='top-deals' offerObjectIDs={availableOffers} />
				{active1?.plan && <BenefitSection benefit={active1.plan} benefitContent={active1.content} />}
				<DealsList {...theme.more_deals} className='more-deals' offerObjectIDs={availableOffers} />
				{active2?.plan && <BenefitSection benefit={active2.plan} benefitContent={active2.content} variant='v2' />}
				<DealsList {...theme.footer_deals} className='footer-deals' offerObjectIDs={availableOffers} variant='large' />
			</div>
		</div>
	)
}
