import { useExperienceConfig } from 'hooks/experience/useExperienceConfig'
import { BenefitPlanV2 } from 'store/benefit-plans/types'

import { PipeFuncReturn } from './types'

export const usePipeATPFilter = (): PipeFuncReturn<BenefitPlanV2[][]> => {
	const { doesWizardHaveEvergreens } = useExperienceConfig()

	return {
		apply: (listsOfPlans) => {
			return listsOfPlans.map((list) =>
				list.filter((plan) => {
					if (!doesWizardHaveEvergreens) return !plan.isEvergreen

					return true
				}),
			)
		},
		subscription: { data: [], isLoading: false },
	}
}
