import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { Box, ButtonBase, ClickAwayListener, Link } from '@mui/material'
import { cx } from 'class-variance-authority'
import { useMegaMenuItems } from 'components/app-header/hooks/use-mega-menu-items'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { useAppSelector } from 'hooks/redux'
import { useEnrollmentData } from 'hooks/user-data'
import { useUserBenefitEligibility } from 'hooks/user-data/use-benefit-eligibility'
import { User } from 'oidc-client'
import { FC, MutableRefObject, useCallback, useContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { accountRoutes } from 'routes/routes-map'
import { siteInfoSelector } from 'store/site/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'

import { MegaMenuContext } from '../../app-header'
import { Tabs } from '../../types'
import MegaMenu from '../mega-menu'
import { DefaultTabs } from './default-tabs'
import styles from './navigation-tabs.module.css'

interface NavigationTabsProps {
	activeTab: any
	appHeaderContainerRef: MutableRefObject<HTMLElement | null>
	user: undefined | User
}

const NavigationTabs: FC<NavigationTabsProps> = ({ activeTab, appHeaderContainerRef, user }: NavigationTabsProps) => {
	const track = useTrackDetailedPageEvent()
	const [showDealsMegaMenu, setShowDealsMegaMenu] = useState<boolean>(false)
	const hasDiscounts = useAppSelector((s) => s.site.siteInfo.properties.hasDiscounts)
	const isBenefitsEligible = useUserBenefitEligibility()
	const { benefitMenuItems, dealsMenuItems, supplementalBenefitItems, supplementalDealsItems } = useMegaMenuItems()
	const { isAllDataLoaded, isOE } = useEnrollmentData()

	const handleDealsMegaMenuClose = useCallback(() => {
		if (showDealsMegaMenu) setShowDealsMegaMenu(false)
	}, [showDealsMegaMenu])

	const handleDealsClick = useCallback(() => {
		setShowDealsMegaMenu((prev) => !prev)
		track({
			actionType: 'click',
			elementName: 'deals dropdown cta',
			elementType: 'button',
		})
	}, [track])

	return (
		<MegaMenuContext.Provider
			value={useMemo(
				() => ({ benefitMenuItems, dealsMenuItems, supplementalBenefitItems, supplementalDealsItems }),
				[benefitMenuItems, dealsMenuItems, supplementalBenefitItems, supplementalDealsItems],
			)}
		>
			<DefaultTabs activeTab={activeTab} user={user}>
				{isAllDataLoaded && isBenefitsEligible && user && (
					<Benefits activeTab={activeTab} appHeaderContainerRef={appHeaderContainerRef} isOE={isOE} />
				)}
				{user && hasDiscounts && (
					<ClickAwayListener onClickAway={handleDealsMegaMenuClose}>
						<div className={styles['dropdown-button']}>
							<ButtonBase
								id='deals'
								className={cx(styles['tab'], {
									[styles['tab-active']]: activeTab === Tabs.Deals,
								})}
								onClick={handleDealsClick}
							>
								Deals
								<Box sx={{ marginTop: '5px' }}>{showDealsMegaMenu ? <ArrowDropUp /> : <ArrowDropDown />}</Box>
							</ButtonBase>
							<MegaMenu
								anchorRef={appHeaderContainerRef.current}
								dataTestId='categories-mega-menu'
								handleClose={handleDealsMegaMenuClose}
								incomingItems={dealsMenuItems ?? []}
								open={showDealsMegaMenu}
								supplementalItems={supplementalDealsItems}
							/>
						</div>
					</ClickAwayListener>
				)}
			</DefaultTabs>
		</MegaMenuContext.Provider>
	)
}

export default NavigationTabs

const Benefits = (props: {
	activeTab: Tabs
	appHeaderContainerRef: MutableRefObject<HTMLElement | null>
	isOE: boolean
}) => {
	const megaMenuItems = useContext(MegaMenuContext)
	const track = useTrackDetailedPageEvent()
	const { properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const isDirectBillOnly = !properties.hasPayroll
	const [showBenefitsMegaMenu, setShowBenefitsMegaMenu] = useState<boolean>(false)
	const handleBenefitMegaMenuClose = () => showBenefitsMegaMenu && setShowBenefitsMegaMenu(false)

	const handleBenefitsClick = useCallback(() => {
		setShowBenefitsMegaMenu((prev) => !prev)
		track({
			actionType: 'click',
			elementName: 'benefits dropdown cta',
			elementType: 'button',
		})
	}, [track])

	const handleEnrollmentsClick = useCallback(() => {
		track({
			actionType: 'click',
			elementName: 'app header enrollments cta',
			elementType: 'button',
		})
	}, [track])

	if (props.isOE) {
		if (isDirectBillOnly) return null

		return (
			<Link
				href={accountRoutes.enrollments}
				id='my benefits'
				className={cx(styles['tab'], {
					[styles['tab-active']]: props.activeTab === Tabs.Benefits,
				})}
				onClick={handleEnrollmentsClick}
			>
				Enrollments
			</Link>
		)
	}

	return (
		<ClickAwayListener onClickAway={handleBenefitMegaMenuClose}>
			<div className={styles['mega-menu-button']}>
				<ButtonBase
					id='categories'
					className={cx(styles['tab'], {
						[styles['tab-active']]: props.activeTab === Tabs.Benefits,
					})}
					onClick={handleBenefitsClick}
				>
					Benefits
					<Box sx={{ marginTop: '5px' }}>{showBenefitsMegaMenu ? <ArrowDropUp /> : <ArrowDropDown />}</Box>
				</ButtonBase>
				<MegaMenu
					anchorRef={props.appHeaderContainerRef.current}
					dataTestId='benefits-mega-menu'
					handleClose={handleBenefitMegaMenuClose}
					incomingItems={megaMenuItems.benefitMenuItems ?? []}
					open={showBenefitsMegaMenu}
					supplementalItems={megaMenuItems.supplementalBenefitItems}
				/>
			</div>
		</ClickAwayListener>
	)
}
