import './intercom.css'

const intercomSettings = {
	admin_link: '',
	app_id: 'dz17m8b8',
	company_site: '',
	employee_id: '',
	name: '',
	org_unit_id: '',
	worker_id: '',
	// Including email or user_id as empty strings here breaks intercom in the unauthed state
	// The intercom api interprets the presence of these key to mean we are in an authed state
	// but the empty string is an invalid value for an authed user
	// email: '',
	// user_id: '',
}

// We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/dz17m8b8'
const intercomScript = `(function () {
	var w = window
	var ic = w.Intercom
	if (typeof ic === 'function') {
		ic('reattach_activator')
		ic('update', w.intercomSettings)
	} else {
		var d = document
		var i = function () {
			i.c(arguments)
		}
		i.q = []
		i.c = function (args) {
			i.q.push(args)
		}
		w.Intercom = i
		var l = function () {
			var s = d.createElement('script')
			s.type = 'text/javascript'
			s.async = true
			s.src = 'https://widget.intercom.io/widget/dz17m8b8'
			var x = d.getElementsByTagName('script')[0]
			x.parentNode.insertBefore(s, x)
		}
		if (document.readyState === 'complete') {
			l()
		} else if (w.attachEvent) {
			w.attachEvent('onload', l)
		} else {
			w.addEventListener('load', l, false)
		}
	}
})()`

export { intercomScript, intercomSettings }
