import { PreviewEnvironmentSelectorForm } from 'app/PreviewEnvironmentSelectorForm'
import { setupApp } from 'app/setupApp'
import { SiteEnvironment } from 'app/types'
import { parseEnvAndDomainFromHost } from 'app/utils/parseEnvAndDomainFromHost'
import { createRoot, Root } from 'react-dom/client'
import { authRedirectRoutes } from 'routes/routes-map'
import { getCookies, removeCookies, storeCookiesInSessionStorage } from 'utils/cookies/utm-tracking'
import { Environments } from 'utils/env'

import './assets/styles/index.css'
import './index.scss'
import * as serviceWorker from './serviceWorker'

declare global {
	interface Window {
		analytics: any
		Intercom: any
		travelClient: any
		Upscope: any
	}
}

// Handle the UTM params from cookies and store them into session storage
const cookies = getCookies() // Get all cookies
storeCookiesInSessionStorage(cookies) // Store UTM cookies in sessionStorage
removeCookies(cookies) // Remove UTM cookies

let container: HTMLElement | null = null

document.addEventListener('DOMContentLoaded', function () {
	if (!container) {
		container = document.getElementById('root')
		load(container)
	}
})

const site = parseEnvAndDomainFromHost(window.location.host)

/**
 * coordinates tenant configuration and app setup
 * @param container the HTML element to render the app into
 */
function load(container: HTMLElement | null) {
	const root = createRoot(container!)

	if (site.env === 'preview') {
		return previewEnvironmentApp(root)
	}

	setupApp(site, root)
}

function previewEnvironmentApp(root: Root) {
	const isNonAuthFlow = authRedirectRoutes.every((route) => !window.location.toString().includes(route))

	// when the user is not logged in, we want to render the app with the preview environment selector
	if (isNonAuthFlow) {
		root.render(
			<PreviewEnvironmentSelectorForm
				onSubmit={(previewEnvironmentConfig) => {
					// env/subdomain cookies are used to expose data in iframe without postMessages
					setPreviewEnvAuthCookies(previewEnvironmentConfig)

					setupApp(previewEnvironmentConfig, root)
				}}
			/>,
		)

		return
	}

	// when logging in the iframe
	// - remove the cookies set by the preview environment selector
	// - pass cookie values through to the auth flow when rendering the app
	if (!isNonAuthFlow) {
		const previewEnv: SiteEnvironment = {
			env: document.cookie
				.split('; ')
				.find((row) => row.startsWith('env='))
				?.split('=')[1] as Environments,
			subdomain: document.cookie
				.split('; ')
				.find((row) => row.startsWith('subdomain='))
				?.split('=')[1] as string,
		}

		// clear cookies after value has been read, so multiple tabs can be open
		document.cookie = 'env=;expires=Thu, 01 Jan 1970 00:00:00 UTC;'
		document.cookie = 'subdomain=;expires=Thu, 01 Jan 1970 00:00:00 UTC;'

		// TODO - perf improvement - postMessage to parent in iframe auth flow
		// could avoid rendering the whole app when in /callback /saml
		// by setting up userManager here and posting the success message to the parent window
		return setupApp(previewEnv, root)
	}

	function setPreviewEnvAuthCookies(previewEnvironmentConfig: SiteEnvironment) {
		document.cookie = `env=${previewEnvironmentConfig.env}; domain=${window.location.host}; path=/; Secure; SameSite=None;`

		document.cookie = `subdomain=${previewEnvironmentConfig.subdomain}; domain=${window.location.host}; path=/; Secure; SameSite=None;`
	}
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
