import { useEffect, useState } from 'react'

type WindowSize = {
	height: number
	width: number
}

export function useWindowSize(): WindowSize {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		height: window.innerHeight,
		width: window.innerWidth,
	})

	useEffect(() => {
		function handleResize() {
			setWindowSize({
				height: window.innerHeight,
				width: window.innerWidth,
			})
		}

		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowSize
}
