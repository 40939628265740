export type NumericComparison = `${string} ${NumericOperator} ${number}`
export type NumericFilter = NumericComparison | NumericRange

export type NumericRange = `${string}:${number} TO ${number}`

type NumericOperator = '!=' | '<' | '<=' | '=' | '>' | '>='

export function after(field: string, value: number): NumericComparison {
	return `${field} >= ${value}`
}

export function before(field: string, value: number): NumericComparison {
	return `${field} <= ${value}`
}

export function equals(field: string, value: number): NumericComparison {
	return `${field} = ${value}`
}

export function notEquals(field: string, value: number): NumericComparison {
	return `${field} != ${value}`
}

export function range(field: string, min: number, max: number): NumericRange {
	return `${field}:${min} TO ${max}`
}

export const numericSearchPresets = {
	/**
	 * Returns a numeric filter for the active timeframe
	 * @param currentDate - The current date in SECONDS *default*: `Math.trunc(Date.now() / 1000)`
	 * @returns Numeric filters
	 */
	activeTimeframe: (currentDate: number = Math.trunc(Date.now() / 1000)): NumericFilter[] => [
		before('startDate', currentDate),
		after('endDate', currentDate),
	],
	// Add more common filters as needed
} as const
