export const OFFER_FILTERS = {
	BLACKLISTED_TENANT_IDS: 'blacklistedTenantIDs',
	CATEGORY: 'category',
	END_DATE: 'endDate',
	LEVEL: 'level',
	MEMBER_EXCLUSIVE: 'isMemberExclusiveOffer',
	OFFER_TYPE: 'offerType',
	PROVIDER: 'provider',
	START_DATE: 'startDate',
	STATUS: 'status',
	TAGS: 'tags',
	TENANT_IDS: 'tenantIDs',
	VENDOR_DISCOUNTER_ID: 'vendor.discounterId',
	VENDOR_NAME: 'vendor.name',
} as const

export type OfferFilterKeys = (typeof OFFER_FILTERS)[keyof typeof OFFER_FILTERS]

/**
 * Creates a negated filter key
 */
export function not(key: OfferFilterKeys | string): string {
	return `NOT_${key}`
}

export const BENEFIT_PLAN_FILTERS = {
	ORGANIZATION_ID: 'organizationId',
} as const

export type BenefitPlanFilterKeys = (typeof BENEFIT_PLAN_FILTERS)[keyof typeof BENEFIT_PLAN_FILTERS]

export type OfferType = 'All' | 'Benefit' | 'Discount'
