import { _useCaptureEvent, RequiredTypes } from './useCaptureEvent'

interface TrackOffer extends RequiredTypes {
	discountOfferId: string // unique identifier for the offer
	elementDetails?: string
	offerBrand: string // hotwire, truecar, ticketsatwork, ge appliances, etc.
	offerName: string // “10% off!”, “save on flights + hotels”, “preferred pricing”, etc.
	offerType: 'benefits' | 'discounts'
}

/**
 * Requires the offer properties
 */
export const useTrackOffer = () => {
	const capture = _useCaptureEvent()

	return (eventData: TrackOffer) => capture(eventData)
}
