import { CssVarsThemeOptions } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import { tableRowClasses } from '@mui/material/TableRow'

// ----------------------------------------------------------------------

export function table(): CssVarsThemeOptions['components'] {
	return {
		MuiTableCell: {
			styleOverrides: {
				head: ({ theme }) => ({
					backgroundColor: theme.vars.palette.background.default,
					color: theme.vars.palette.text.secondary,
					fontSize: 14,
					fontWeight: theme.typography.fontWeightSemiBold,
				}),
				paddingCheckbox: ({ theme }) => ({
					paddingLeft: theme.spacing(1),
				}),
				root: {
					borderBottomStyle: 'dashed',
				},
				stickyHeader: ({ theme }) => ({
					backgroundColor: theme.palette.background.paper,
					backgroundImage: `linear-gradient(to bottom, ${theme.vars.palette.background.default} 0%, ${theme.vars.palette.background.default} 100%)`,
				}),
			},
		},
		MuiTableContainer: {
			styleOverrides: {
				root: {
					position: 'relative',
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				actions: {
					marginRight: 8,
				},
				root: {
					width: '100%',
				},
				select: ({ theme }) => ({
					'&:focus': {
						borderRadius: theme.vars.shape.borderRadius,
					},
					paddingLeft: 8,
				}),
				selectIcon: {
					height: 16,
					right: 4,
					top: 'calc(50% - 8px)',
					width: 16,
				},
				toolbar: {
					height: 64,
				},
			},
		},
		MuiTableRow: {
			styleOverrides: {
				root: ({ theme }) => ({
					'&:last-of-type': {
						[`& .${tableCellClasses.root}`]: {
							borderColor: 'transparent',
						},
					},
					[`&.${tableRowClasses.selected}`]: {
						'&:hover': {
							backgroundColor: `rgba(${theme.vars.palette.primary.darkChannel} / 0.08)`,
						},
						backgroundColor: `rgba(${theme.vars.palette.primary.darkChannel} / 0.04)`,
					},
				}),
			},
		},
	}
}
