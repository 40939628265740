import { axiosInstance as axios, petAxiosInstance } from 'config/axios'
import { DependentList, PartialDependentList } from 'types/dependent'
import { PetData, PetPostRes } from 'types/pet'
import { UserEnrollmentRes } from 'types/user-enrollment'

import UrlBuilder from './util/url-builder'

const enrollmentsSubdomain: string = 'enrollments'
const urlBuilder: UrlBuilder = new UrlBuilder(enrollmentsSubdomain)
export class WorkerService {
	/**
	 *
	 * @param workerId - users id
	 * @returns
	 */
	static fetchPetEnrollments(): Promise<any> {
		const saveEndpoint: string = `api/worker/dependent/pets`

		return axios.get(urlBuilder.setEndpoint(saveEndpoint).url())
	}

	/**
	 * TODO: Finish this service
	 * @param petData - Array of pet information
	 * @returns unknown at this time... rates?
	 */
	static fetchPetRates(pets: Array<PetData>, programEnrollmentId: string, postalCode: string): Promise<PetPostRes> {
		const ratesEndpoint: string = `api/worker/dependent/pets`
		const trimmedPets = pets.map((pet) => ({
			...pet,
			name: pet.petName.trim(),
		}))

		return petAxiosInstance.post(urlBuilder.setEndpoint(ratesEndpoint).url(), {
			pets: trimmedPets,
			postalCode: postalCode ?? '',
			programEnrollmentId: programEnrollmentId ?? '',
		})
	}

	static getDependents(): Promise<DependentList> {
		return axios.get(urlBuilder.setEndpoint(`api/worker/dependent`).url())
	}

	static async getRecentUserEnrollments(): Promise<UserEnrollmentRes[]> {
		try {
			return axios.get(urlBuilder.setEndpoint('api/worker/UserEnrollments/Recent').url())
		} catch (e) {
			throw new Error(e as string)
		}
	}

	static async getUserEnrollments(): Promise<Array<UserEnrollmentRes>> {
		try {
			const userEnrollments: Array<UserEnrollmentRes> = await axios.get(
				urlBuilder.setEndpoint('api/worker/UserEnrollments').url(),
			)

			const sortDeps = (deps): PartialDependentList =>
				deps.sort((a, b) => {
					if (b.relationship > a.relationship) return 1
					if (b.relationship < a.relationship) return -1

					return 0
				})

			return userEnrollments.map((enrollment) => ({
				...enrollment,
				dependents: enrollment.dependents ? sortDeps(enrollment.dependents) : [],
			}))
		} catch (e) {
			throw new Error(e as string)
		}
	}

	static async getUserEnrollmentSummary(): Promise<Array<UserEnrollmentRes>> {
		try {
			const userEnrollments: Array<UserEnrollmentRes> = await axios.get(
				urlBuilder.setEndpoint('api/worker/UserEnrollmentSummary').url(),
			)

			const sortDeps = (deps): PartialDependentList =>
				deps.sort((a, b) => {
					if (b.relationship > a.relationship) return 1
					if (b.relationship < a.relationship) return -1

					return 0
				})

			return userEnrollments.map((enrollment) => ({
				...enrollment,
				dependents: enrollment.dependents ? sortDeps(enrollment.dependents) : [],
			}))
		} catch (e) {
			throw new Error(e as string)
		}
	}
}
