import { lighten } from 'polished'

import { Properties } from './tenant-site-types'

export type Theme = 'primary' | 'secondary'

export class ThemeEngine {
	alertColor: string
	backgroundColor: string
	backgroundImage: string
	ctaColor: string
	focusBackgroundColor: string
	focusBorderColor: string
	isPrimaryTheme: boolean
	logo: string
	primaryColor: string
	secondaryColor: string

	constructor(data: Properties) {
		this.backgroundColor = data.backgroundColor
		this.backgroundImage = data.backgroundImage
		this.isPrimaryTheme = data.theme === 'primary'
		this.logo = data.logoImage
		this.primaryColor = data.brandColor
		this.secondaryColor = 'var(--color-gray-9)'
		this.focusBorderColor = this.primaryColor ? lighten(0.2, this.primaryColor) : ''
		this.focusBackgroundColor = this.primaryColor ? lighten(0.45, this.primaryColor) : ''
		this.ctaColor = data.ctaColor ?? 'var(--client-colors-cta)'
		this.alertColor = data.alertColor ?? 'var(--color-green-success)'
	}
}
