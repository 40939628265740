import type { BenefitPlanV2 } from 'store/benefit-plans/types'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { QueryClient } from '@tanstack/react-query'
import { BenefitElectionsService as BenefitElectionsAPI } from 'api/benefit-elections/services'
import { StateMachineStatus } from 'hooks/use-async-fetch'
import { EnrollmentStatus } from 'pages/enrollment-wizard/types'
import { EnrollmentService } from 'services/enrollments.service'
import { WorkerService } from 'services/worker.service'
import { actions as enrollmentActions } from 'store/enrollments/slice'
import { RootState } from 'store/rootReducer'
import { match, P } from 'ts-pattern'
import {
	EnrolledPet,
	EnrolledPetData,
	PET_MISSING_ZIP,
	PET_PLAN_ID,
	PetBreed,
	PetData,
	petEnrollmentsInitState,
	PetPostRes,
	PetSpecies,
	SplitPets,
} from 'types/pet'
import { ERROR, IDLE, LOADING, SUCCESS } from 'types/status-types'
import { UserEnrollmentRes } from 'types/user-enrollment'

import { EnrollmentWizardState, WizardPlanData } from './types'

/**
 * The current version of the Enrollment Wizard slice.
 * If changes are made to the slice or how data is stored in the slice, we will need to update this number.
 * This prevents old continuations from being loaded in with incompatible data structures.
 *
 * CHANGELOG
 * 1.1.0
 * - slimmed down this slice to only include necessary data for remaining features
 *      - those feature(s) are: pet enrollment
 */
export const ENROLLMENT_WIZARD_SLICE_VERSION: string = '1.1.0'

export const initialState: EnrollmentWizardState = {
	activePlan: {} as BenefitPlanV2,
	petDisclaimer: '',
	petEnrollmentFetchError: null,
	petEnrollmentFetchStatus: IDLE,
	petEnrollments: { ...petEnrollmentsInitState },
	petFrequency: '',
	petInfo: [],
	petRatesFetchStatus: IDLE,
	petSelections: {},
	petSubmissionInfo: [],
	petTotal: null,
	petZip: '',
	planSelections: {},
	saveEnrollmentError: [],
	savePetError: null,
	savePetStatus: IDLE,
	version: ENROLLMENT_WIZARD_SLICE_VERSION,
	wrp2596: false,
}

export const buildEmptyPlanSelections = (): WizardPlanData => ({
	isHighlightingRequiredFields: false,
	programEnrollmentId: '',
})

const formPet = (): PetData => ({
	age: '',
	coverage: '',
	coverageName: '',
	frequency: '',
	isCancelled: false,
	isCovered: false,
	petGuid: self.crypto.randomUUID(),
	petName: '',
	rates: [],
	species: PetSpecies.NONE,
})

export const enrollmentWizardSlice = createSlice({
	extraReducers: {
		[enrollmentActions.resetSubmitStatus.type]: (state: EnrollmentWizardState): void => {
			// This will keep the previous date of birth seletions if they exist for a safety net
			state.activePlan = {} as BenefitPlanV2
			state.petEnrollments = initialState.petEnrollments
			state.petEnrollmentFetchStatus = initialState.petEnrollmentFetchStatus
			state.petFrequency = ''
			state.petInfo = []
			state.petRatesFetchStatus = IDLE
			state.petSelections = {}
			state.petSubmissionInfo = []
			state.petTotal = null
			state.petZip = ''
			state.planSelections = {}
		},
	},
	initialState: { ...initialState },
	name: 'enrollmentWizard',
	reducers: {
		/**
		 * Instead of calling this action, consider calling declineOrCancelBenefitPlan.
		 *
		 * declineOrCancelBenefitPlan will automatically handle choosing declined or canceled for a plan.
		 */
		addCanceledPlan: (state: EnrollmentWizardState, action: PayloadAction<number>): void => {
			state.planSelections[action.payload] = {
				...state.planSelections[action.payload],
				enrollmentStatus: EnrollmentStatus.CANCELED,
				isHighlightingRequiredFields: false,
			}
		},
		/**
		 * Instead of calling this action, consider calling declineOrCancelBenefitPlan.
		 *
		 * declineOrCancelBenefitPlan will automatically handle choosing declined or canceled for a plan.
		 */
		addDeclinedPlan: (state: EnrollmentWizardState, action: PayloadAction<number>): void => {
			// TODO: Figure out if they are declined or cancelled
			state.planSelections[action.payload] = {
				...state.planSelections[action.payload],
				enrollmentStatus: EnrollmentStatus.DECLINED,
				isHighlightingRequiredFields: false,
			}
		},
		addExistingPets: (state: EnrollmentWizardState, action: PayloadAction<PetData[]>) => {
			const oldPetInfo = action.payload.filter(
				(fetchedPet) => !state.petInfo.find((alreadyIncludedPet) => fetchedPet.petGuid === alreadyIncludedPet.petGuid),
			)
			state.petInfo = [...state.petInfo, ...oldPetInfo]
		},
		addPet: (state: EnrollmentWizardState) => {
			state.petInfo.push(formPet())
		},
		// Coverage and tier are populated to bypass validation in Step 2 for Pet and Trustmark
		addSelectedPlan: (state: EnrollmentWizardState, action: PayloadAction<number>) => {
			state.planSelections[action.payload] = {
				...state.planSelections[action.payload],
				enrollmentStatus: EnrollmentStatus.NEW,
			}
		},
		clearSelectionsByPlan: (state, action) => {
			state.planSelections[action.payload] = {
				...buildEmptyPlanSelections(),
			}
		},
		fetchingPetEnrollments: (state: EnrollmentWizardState): void => {
			state.petEnrollmentFetchStatus = LOADING
		},
		fetchPetEnrollmentsError(state, action: PayloadAction<{ error: string[] }>) {
			state.petEnrollmentFetchError = new Error(action.payload.error.join(' '))
			state.petEnrollmentFetchStatus = ERROR
		},
		fetchPetEnrollmentsSuccess(state, action: PayloadAction<{ enrolledPetData: EnrolledPetData; splitPets: any }>) {
			state.petEnrollmentFetchStatus = SUCCESS
			// frequency strings may contain numbers at the end, we only care about the first 2 letters
			state.petEnrollments = {
				...action.payload.enrolledPetData,
				frequency: action.payload.enrolledPetData.frequency.substring(0, 2),
				splitPets: action.payload.splitPets,
			}
		},
		loadPetData: (state: EnrollmentWizardState, action: PayloadAction<PetData[]>) => {
			state.petInfo = action.payload
		},
		populateSliceFromExternalStore: (
			state: EnrollmentWizardState,
			action: PayloadAction<Partial<EnrollmentWizardState>>,
		): EnrollmentWizardState => {
			if (state.version !== action.payload.version) {
				return state
			}

			return { ...state, ...action.payload }
		},
		removeAllPets: (state: EnrollmentWizardState): void => {
			state.petInfo = initialState.petInfo
			state.petEnrollments = initialState.petEnrollments
		},
		removePets: (
			state: EnrollmentWizardState,
			{ payload }: PayloadAction<{ index: number; numToRemove?: number }>,
		): void => {
			state.petInfo.splice(payload.index, payload.numToRemove ?? 1)
		},
		resetPetEnrollments: (state: EnrollmentWizardState): void => {
			state.petEnrollments = initialState.petEnrollments
			state.petEnrollmentFetchStatus = initialState.petEnrollmentFetchStatus
		},
		resetPlanBackToEnrolled: (state: EnrollmentWizardState, action: PayloadAction<number>): void => {
			state.planSelections[action.payload] = {
				...state.planSelections[action.payload],
				enrollmentStatus: EnrollmentStatus.ENROLLED,
				isHighlightingRequiredFields: false,
			}
		},
		saveEnrollmentError(state: EnrollmentWizardState, action: PayloadAction<string[]>): void {
			state.saveEnrollmentError = action.payload
		},
		savePetError(state: EnrollmentWizardState, action: PayloadAction<{ error: Error }>): void {
			state.savePetError = action.payload.error
			state.savePetStatus = ERROR
		},
		savePetSuccess(state, action: PayloadAction<any>) {
			state.savePetStatus = SUCCESS
			state.petInfo = state.petInfo.map((pet) => {
				const apiPet = action.payload.pets.find(({ petGuid }) => petGuid === pet.petGuid)
				if (apiPet) {
					// update amount for pets that already had coverage(rate can change for selected coverage)
					const hasCoverage = pet.coverage ? apiPet.rates.find((rate) => rate.coverage === pet.coverage) : null

					return {
						...pet,
						coverageAmount: hasCoverage ? hasCoverage.amount : 0,
						rates: apiPet.rates,
					}
				}

				return pet
			})
			// frequency strings may contain numbers at the end, we only care about the first 2 letters
			state.petFrequency = action.payload.frequency.substring(0, 2)
			state.petDisclaimer = action.payload.disclaimer
		},
		setActivePlan: (state: EnrollmentWizardState, action: PayloadAction<BenefitPlanV2>): void => {
			if (action.payload && action.payload.benefitPlanId) {
				const plan = { ...action.payload }
				state.activePlan = plan

				state.planSelections[plan.benefitPlanId] = {
					...buildEmptyPlanSelections(),
					...(state.planSelections?.[plan.benefitPlanId] ?? {}),
					programEnrollmentId: state.planSelections?.[plan.benefitPlanId]?.programEnrollmentId ?? '',
				}
			}
		},
		setIsHighlightingRequiredFields: (state: EnrollmentWizardState, action: PayloadAction<boolean>): void => {
			state.planSelections[state.activePlan.benefitPlanId] = {
				...state.planSelections[state.activePlan.benefitPlanId],
				isHighlightingRequiredFields: action.payload,
			}
		},
		setPetCoverage: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ amount: number; coverage: string; coverageName: string; index: number }>,
		) => {
			const { amount, coverage, coverageName, index } = action.payload
			if (state.petInfo[index]) {
				state.petInfo[index].coverage = coverage
				state.petInfo[index].coverageAmount = amount
				state.petInfo[index].coverageName = coverageName
			}
		},
		setPetData: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ field: string; index: number; value: null | string }>,
		) => {
			const { field, index, value } = action.payload
			if (state.petInfo[index]) {
				state.petInfo[index][field] = value
			}
		},
		setPetRatesFetchStatus: (state: EnrollmentWizardState, action: PayloadAction<StateMachineStatus>) => {
			state.petRatesFetchStatus = action.payload
		},
		setPetTotal: (state: EnrollmentWizardState, action: PayloadAction<null | number>) => {
			state.petTotal = action.payload
		},
		setPetZip: (state: EnrollmentWizardState, action: PayloadAction<string>) => {
			state.petZip = action.payload
		},
		setPlanSelectionEnrollmentStatus: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ id: number; status: EnrollmentStatus | undefined }>,
		) => {
			state.planSelections[action.payload.id] = {
				...state.planSelections[action.payload.id],
				enrollmentStatus: action.payload.status,
			}
		},

		setWrp2596: (state: EnrollmentWizardState, action: PayloadAction<boolean>) => {
			state.wrp2596 = action.payload
		},
		updatePetWellness: (
			state: EnrollmentWizardState,
			action: PayloadAction<{ amount: number; coverage: null | string; coverageName: string; index: number }>,
		) => {
			const { amount, coverage, coverageName, index } = action.payload
			if (state.petInfo[index]) {
				state.petInfo[index].hasWellness = !state.petInfo[index].hasWellness
				// need to update amount and coverage selections
				state.petInfo[index].coverage = coverage ?? ''
				state.petInfo[index].coverageAmount = amount
				state.petInfo[index].coverageName = coverageName
			}
		},
	},
})

const { actions, reducer } = enrollmentWizardSlice
export { actions, reducer as enrollmentWizard }

const getLastEnrollmentFromOverview = (id, enrollment): UserEnrollmentRes =>
	enrollment.userEnrollmentOverview[id]?.[enrollment.userEnrollmentOverview[id].length - 1]

/**
 * We only want to mark as cancelled plans that are currently effective. Previously
 * the data from the UserEnrollmentSummary call didnt include plans that had a
 * future effective date. Data from the UserEnrollmentOverview call does, so here
 * make sure to only return a plan if it is currently effective.
 * @param id benefit plan id
 * @param enrollment enrollment slice state
 * @returns benefit plan or null
 */
const getLastEffectiveEnrollmentFromOverview = (id, enrollment): null | UserEnrollmentRes => {
	const lastEnrollment = getLastEnrollmentFromOverview(id, enrollment)

	return match(lastEnrollment)
		.with(P.not(P.nullish), () => (new Date(lastEnrollment.planEffectiveDate) > new Date() ? null : lastEnrollment))
		.otherwise(() => lastEnrollment)
}

export const declineOrCancelBenefitPlan = (benefitPlanId: number) => (dispatch, getState) => {
	const { benefitPlans, enrollments: enrollment }: RootState = getState()
	const programId = benefitPlans.availablePlans.find((bp) => bp.benefitPlanId === benefitPlanId)?.programId

	const currentEnrollment = getLastEffectiveEnrollmentFromOverview(benefitPlanId, enrollment)

	const isEnrolled =
		currentEnrollment?.statusCode === EnrollmentStatus.LEAVED ||
		currentEnrollment?.statusCode === EnrollmentStatus.ENROLLED

	if (isEnrolled && programId !== PET_PLAN_ID) {
		return dispatch(actions.addCanceledPlan(benefitPlanId))
	}

	return dispatch(actions.addDeclinedPlan(benefitPlanId))
}

export const fetchPetRates = () => async (dispatch, getState) => {
	const state: RootState = getState()

	try {
		const progEnrollmentIds =
			state.enrollmentWizard.planSelections?.[state.enrollmentWizard.activePlan.benefitPlanId]?.programEnrollmentId ??
			''

		const updatedInfo: PetPostRes = await WorkerService.fetchPetRates(
			state.enrollmentWizard.petInfo,
			progEnrollmentIds,
			state.enrollmentWizard.petZip,
		)
		// TODO: probably need a better check here
		// these are the error codes we have seen returned from Nationwide
		if (updatedInfo.messageCode === '500' || updatedInfo.messageCode === '400') {
			throw new Error(updatedInfo.userMessage)
		}
		dispatch(actions.savePetSuccess(updatedInfo))
	} catch (e) {
		const error = e as any
		if (error?.message?.toLowerCase() !== PET_MISSING_ZIP) dispatch(actions.savePetError({ error }))
		throw error
	}
}

const reduceEnrolledPetData = (enrolledPetData: EnrolledPetData): SplitPets =>
	enrolledPetData.pets.reduce(
		(acc: { existing: EnrolledPet[]; newlyEnrolled: EnrolledPet[] }, val: EnrolledPet) => {
			if (!val.programEnrollment?.statusCode) {
				return acc
			}

			if (val.isCovered) {
				acc.existing.push(val)

				return acc
			}
			/*
			 * Edge case where user started adding pets, left, and then
			 * started adding pets on a different profile. We don't want
			 * these pets to show up on different profiles. They will show
			 * up in data from the GET /pets call. So we need to filter them
			 * if they have a status of New and DO NOT have a matching
			 * programEnrollmentId from the UserEnrollmentsResponse for the
			 * active profileId
			 */
			// const plan: BenefitPlanV2 = state.enrollmentWizard.activePlan
			// const { recentUserEnrollment, userEnrollment, userEnrollmentSummary } = state.enrollments
			// const recentEnrollment = recentUserEnrollment[plan.programId]
			// const enrollment = userEnrollment[plan.programId]
			// const enrollmentSummary = userEnrollmentSummary[plan.programId]
			const newAndNotMatching = val.programEnrollment?.statusCode === EnrollmentStatus.NEW

			// only include pets that were not previously marked as declined
			if (val.programEnrollment?.statusCode !== EnrollmentStatus.DECLINED && !newAndNotMatching)
				acc.newlyEnrolled.push(val)

			return acc
		},
		{ existing: [], newlyEnrolled: [] },
	)
const breedsQueryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 60 * 60 * 60,
		},
	},
})

// Matches pet breed object based on breedCode from db
const matchPetBreed = (breedCode, breedArray) => {
	if (!breedCode) return null
	const codeStr = breedCode.toString()

	return breedArray.find((b) => b.code === codeStr) ?? null
}

const fetchEnrollmentsPetData = async (dispatch, getState) => {
	const state: RootState = getState()
	try {
		dispatch(actions.fetchingPetEnrollments())
		const enrolledPetData: EnrolledPetData = await WorkerService.fetchPetEnrollments()

		if (state.enrollmentWizard.wrp2596) enrolledPetData.pets = enrolledPetData.pets.filter((x) => !x.isCancelled)

		const canineBreedData = await breedsQueryClient.fetchQuery<PetBreed[] | undefined>({
			queryFn: () => EnrollmentService.getNationwidePetBreeds('C'),
			queryKey: ['nationwidepetbreeds', 'C'],
		})
		const felineBreedData = await breedsQueryClient.fetchQuery<PetBreed[] | undefined>({
			queryFn: () => EnrollmentService.getNationwidePetBreeds('F'),
			queryKey: ['nationwidepetbreeds', 'F'],
		})
		const splitPets = reduceEnrolledPetData(enrolledPetData)
		const filteredNewlyEnrolled: Array<EnrolledPet> = [
			...splitPets.newlyEnrolled,
			...enrolledPetData.pets.filter(
				(pet) => !pet.statusCode || (pet.statusCode === EnrollmentStatus.NEW && !pet?.isPushed && !pet.isCovered),
			),
		].filter((pet, idx, arr) => arr.findIndex((x) => x.petGuid === pet.petGuid) === idx)
		const mappedPets: any = filteredNewlyEnrolled.map((pet) => {
			const targetBreedArray = pet.species === PetSpecies.DOG ? canineBreedData : felineBreedData

			return {
				age: pet.age,
				amount: pet.amount,
				breed: pet.breed ? pet.breed : matchPetBreed(pet.breedCode, targetBreedArray),
				breedCode: pet.breedCode,
				coverage: pet.coverage,
				coverageName: pet.coverageName,
				hasWellness: pet.hasWellness,
				isCancelled: pet.isCancelled,
				isCovered: pet.isCovered,
				petGuid: pet.petGuid,
				petName: pet.petName,
				rates: [],
				species: pet.species,
			}
		})
		dispatch(actions.addExistingPets(mappedPets))
		dispatch(actions.fetchPetEnrollmentsSuccess({ enrolledPetData, splitPets }))
	} catch (error) {
		dispatch(actions.fetchPetEnrollmentsError({ error: getErrorMessage(error) }))
	}
}

const fetchBenniesPetData = async (dispatch) => {
	try {
		dispatch(actions.fetchingPetEnrollments())
		const data = await BenefitElectionsAPI.GetPets()
		const getCoverageName = (cc: string): string =>
			cc.toLowerCase().startsWith('vbw') ? 'My Pet Protection With Wellness Plan' : 'My Pet Protection Plan'

		const pets: any = data.pets.map((pet) => ({
			age: pet.age,
			amount: pet.amount,
			breed: pet.breed,
			breedCode: pet.breed.code,
			coverage: pet.coverageCode,
			coverageName: pet.coverageName.length ? pet.coverageName : getCoverageName(pet.coverageCode),
			effectiveDate: pet.effectiveDate,
			isPushed: pet.isPushed,
			petGuid: pet.nationwidePetId,
			petName: pet.petName,
			policyNumber: pet.policyNumber,
			rates: [],
			species: pet.species,
		}))

		const enrolledPetData: any = {
			...data,
			mappedPets: pets,
		}

		dispatch(
			actions.fetchPetEnrollmentsSuccess({
				enrolledPetData,
				splitPets: { existing: [], newlyEnrolled: [] }, //splitPets is ignored with bennies
			}),
		)
	} catch (error) {
		dispatch(actions.fetchPetEnrollmentsError({ error: getErrorMessage(error) }))
	}
}

export const fetchEnrolledPetData = (und3484 = false) => (und3484 ? fetchBenniesPetData : fetchEnrollmentsPetData)

const getErrorMessage = (error): string[] => {
	const errorMessage: string[] = []
	if (error.response?.data && Array.isArray(error.response.data))
		errorMessage.push(...error.response.data.map((x) => x.text))
	else if (error.response?.data) errorMessage.push(error.response.data)
	else
		errorMessage.push(`An error occurred while processing your selection, please try again.
	If this error continues, please contact Customer Care.`)

	return errorMessage
}
