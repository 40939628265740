import { lazy } from 'react'

export const modalRegistry = {
	WaiveAccessoryModal: {
		module: lazy(() =>
			import('../../components/modals/waive-accessory-modal').then((module) => ({
				default: module.WaiveAccessoryModal,
			})),
		),
	},
	WaiveRestOfPlansModal: {
		module: lazy(() =>
			import('../../components/modals/waive-rest-of-plans-modal').then((module) => ({
				default: module.WaiveRestOfPlansModal,
			})),
		),
	},
} as const

export type ModalName = keyof typeof modalRegistry

export type ModalProps<T extends ModalName> = LazyComponentProps<(typeof modalRegistry)[T]['module']>

export type ModalPropsUnion = LazyComponentProps<(typeof modalRegistry)[keyof typeof modalRegistry]['module']>

// get defined prop types, or void if undefined
type LazyComponentProps<T> = T extends React.LazyExoticComponent<React.ComponentType<infer P>>
	? P extends object
		? P
		: void
	: never
