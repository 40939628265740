import { BenefitPlanV2 } from 'store/benefit-plans/types'
import EnrollmentEvent from 'types/enrollment-event'

export enum EnrollmentType {
	closed = 'closed',
	newHire = 'new hire',
	oe = 'open',
}

export type EnrollmentData = {
	anytimePlans: BenefitPlanV2[]
	displayEndDate: string | undefined
	enrollmentEndDate: string
	enrollmentEvent: EnrollmentEvent | undefined
	enrollmentPlans: BenefitPlanV2[]
	enrollmentPlansToDisplay: BenefitPlanV2[]
	enrollmentStartDate: string
	enrollmentType: EnrollmentType | undefined
	hasNative: boolean
	hasNonNative: boolean
	isAllDataLoaded: boolean
	isNewHire: boolean
	isOE: boolean
}

export type PipeFuncReturn<T> = {
	apply: (data: T) => T
	subscription: {
		data: any
		isFetched?: boolean
		isLoading: boolean
	}
}

// User Roles
export const CORESTREAM_PROD_QA_ROLE = 'Corestream Prod QA'
