export const API_SUBDOMAIN = 'enrollments'

export const endpoints = {
	BenefitElections: getBenefitElectionsEndpoints(),
	Dependents: getDependentsEndpoints(),
	Questions: getQuestionsEndpoints(),
	Trustmark: getTrustmarkEndpoints(),
	Worker: getWorkerEndpoints(),
}

// todo move this over to funcitonal style
export enum Endpoints {
	BenefitElections = 'BenefitElections',
	BenefitPlans = 'BenefitPlans',
	ClearEnrollments = 'ClearEnrollments',
	ClearEnrollmentsAndDependents = 'ClearEnrollmentsAndDependents',
	EnrollmentData = 'EnrollmentData',
	EnrollmentProfiles = 'EnrollmentProfiles',
	EnrollmentReporting = 'EnrollmentReporting',
	NationwidePetBreeds = 'NationwidePetBreeds',
	Questions = 'Questions',
	Rates = 'Rates',
	SaveEnrollment = 'SaveEnrollment',
	SendThirdPartyConfirmationEmail = 'SendThirdPartyConfirmationEmail',
	SubmitEnrollment = 'SubmitEnrollment',
	UserAddressInfo = 'UserAddressInfo',
	VPI = 'VPI',
}

function getBenefitElectionsEndpoints() {
	const beEndpoints = {
		all: 'BenefitElections' as const,
		deleteByProgramEnrollmentId: (programEnrollmentId: string) => `${beEndpoints.all}/${programEnrollmentId}`,
		overview: () => `${beEndpoints.all}/Overview`,
		saveEnrollment: {
			all: () => `${beEndpoints.all}/SaveEnrollment`,
			persons: () => `${beEndpoints.saveEnrollment.all()}/Persons`,
			pets: () => `${beEndpoints.saveEnrollment.all()}/Pets`,
		},
		startEnrollment: () => `${beEndpoints.all}/StartEnrollment`,
		submitEnrollment: () => `${beEndpoints.all}/SubmitEnrollment`,
		userNewHireInfo: () => `${beEndpoints.all}/UserNewHireInfo`,
		verifyNewHireDate: () => `${beEndpoints.all}/VerifyNewHireDateForStartingEnrollment`,
	}

	return beEndpoints
}

function getDependentsEndpoints() {
	const dEndpoints = {
		all: 'api/worker' as const,
		getDependents: () => `${dEndpoints.all}/dependent`,
		getPetEnrollments: () => `${dEndpoints.all}/dependent/pets`,
	}

	return dEndpoints
}

function getQuestionsEndpoints() {
	const qEndpoints = {
		all: 'Questions' as const,
		getAnsweredQuestionsForProgramEnrollment: (programEnrollmentId: string) =>
			`${qEndpoints.all}/Answered/${programEnrollmentId}`,
	}

	return qEndpoints
}

function getTrustmarkEndpoints() {
	const tEndpoints = {
		all: 'LTC' as const,
		getEnrollmentData: () => `${tEndpoints.all}/EnrollmentData`,
		getTrustmarkClientToken: () => `${tEndpoints.all}`,
		getWorkerAddress: () => `${tEndpoints.all}/UserAddressInfo`,
	}

	return tEndpoints
}
function getWorkerEndpoints() {
	const wEndpoints = {
		all: 'api/worker' as const,
		getDeductionHistory: () => `${wEndpoints.all}/DeductionHistory`,
		getRecentEnrollments: () => `${wEndpoints.all}/UserEnrollments/Recent`,
		getUserEnrollments: () => `${wEndpoints.all}/Enrollments`,
	}

	return wEndpoints
}
