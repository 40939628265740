import { UserManagerSettings, WebStorageStateStore } from 'oidc-client'
import UrlBuilder from 'services/util/url-builder'

/**
 * why function? UrlBuilder pulls from sessionStorage
 */
export const oidcSettings = (): UserManagerSettings => {
	const csAuthUrl = new UrlBuilder('auth').url()
	const tenantUrl = `${window.location.protocol}//${window.location.hostname}${
		window.location.port ? `:${window.location.port}` : ''
	}`

	return {
		acr_values: 'tenant:corestream',
		authority: csAuthUrl,
		automaticSilentRenew: true,
		client_id: import.meta.env.VITE_CLIENT_ID,
		includeIdTokenInSilentRenew: true,
		loadUserInfo: true,
		metadata: {
			authorization_endpoint: `${csAuthUrl}/connect/authorize`,
			end_session_endpoint: `${csAuthUrl}/connect/endsession`,
			issuer: csAuthUrl,
			jwks_uri: `${csAuthUrl}/.well-known/openid-configuration/jwks`,
			registration_endpoint: `${tenantUrl}/register`,
			token_endpoint: `${csAuthUrl}/connect/token`,
			userinfo_endpoint: `${csAuthUrl}/connect/userinfo`,
		},
		post_logout_redirect_uri: `${tenantUrl}/logout`,
		prompt: 'none',
		redirect_uri: `${tenantUrl}/callback`,
		response_mode: 'query',
		response_type: 'code',
		revokeAccessTokenOnSignout: true,
		scope: 'openid tenants_api profile enrollments_api autoquoting_api offline_access benefitElections_api billing_api',
		silent_redirect_uri: `${tenantUrl}/callback`,
		// silentRequestTimeout: 10000,
		silentRequestTimeout: 60000,
		userStore: new WebStorageStateStore({ store: localStorage }),
	}
}

export const setDynamicOidcSettings = (
	oidcSettings: UserManagerSettings,
	updatedValues: Partial<UserManagerSettings>,
): UserManagerSettings => ({
	...oidcSettings,
	...updatedValues,
})
