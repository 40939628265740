import { createStore, StoreApi } from 'zustand'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { shallow } from 'zustand/shallow'
import { useStoreWithEqualityFn } from 'zustand/traditional'

import { selectSearch } from './selectors'
import { createSearchSlice, SearchSlice } from './slices/search'
import { createSnackbarSlice, SnackbarSlice } from './slices/snackbar/snackbar.slice'

export type ZustandStore = {
	search: SearchSlice
	snackbar: SnackbarSlice
}

// vanilla store without hooks
export const vanillaStore = createStore<ZustandStore>()(
	devtools(
		subscribeWithSelector(
			immer((set, get, store) => ({
				search: createSearchSlice(set, get, store),
				snackbar: createSnackbarSlice(set, get, store),
			})),
		),
		{
			enabled: true,
			name: 'client-ui zustand',
			trace: true,
		},
	),
)

type EqualityFn<T> = (a: T, b: T) => boolean

// tooling to create the hooks - first level of selectors DO have shallow equality optimizations
const createBoundedUseStore = ((store) =>
	<T = any>(selector: (state: ExtractState<typeof store>) => T, equalityFn: EqualityFn<T> = shallow) =>
		useStoreWithEqualityFn(store, selector, equalityFn)) as <S extends StoreApi<unknown>>(
	store: S,
) => {
	<T>(selector: (state: ExtractState<S>) => T, equalityFn?: EqualityFn<T>): T
}

// Utility type to extract state
type ExtractState<S> = S extends { getState: () => infer X } ? X : never

// Export the hooks
export const useZustandStore = createBoundedUseStore(vanillaStore)

// Make useSearchSlice generic to allow dynamic selectors with custom equality functions
export const useSearchSlice = <T = SearchSlice>(
	selector: (state: ZustandStore) => T = selectSearch as unknown as (state: ZustandStore) => T,
	equalityFn?: EqualityFn<T>,
) => useZustandStore(selector, equalityFn)
