import { Draft } from 'immer'
import { StateCreator } from 'zustand'

type SetFunction<T> = (fn: (sliceState: Draft<T>) => void, actionName?: string) => void

type SliceStateMapper<T, S> = (state: Draft<S>) => Draft<T>

export function createSliceHelper<TState>() {
	return function withSlice<TSlice extends Record<string, unknown>, TActions extends Record<string, unknown>>(
		path: SliceStateMapper<TSlice, TState>,
		createActions: (set: SetFunction<TSlice>, getSlice: () => Draft<TSlice>) => TActions,
		initialState: TSlice,
		namespace?: string,
	): StateCreator<
		TState,
		[['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
		[],
		TActions & TSlice
	> {
		return (set, get) => {
			const setSlice: SetFunction<TSlice> = (fn, actionName) => {
				// Try to get the property name from the Error stack
				const stack = new Error().stack
				const callerLine = stack?.split('\n')[2] ?? ''
				const match = callerLine.match(/\.(\w+)\s/)
				const propertyName = match?.[1]

				const definedActionName = actionName ? (namespace ? `${namespace}/${actionName}` : actionName) : undefined
				const autoActionName = namespace && propertyName ? `${namespace}/${propertyName}` : undefined

				set(
					(state) => {
						fn(path(state))
					},
					false,
					definedActionName || autoActionName,
				)
			}

			const getSlice = () => path(get() as Draft<TState>) as Draft<TSlice>

			const actions = createActions(setSlice, getSlice)

			return {
				...initialState,
				...actions,
			}
		}
	}
}

export function createZustandSlice<
	TState,
	TSlice extends Record<string, unknown>,
	TActions extends Record<string, unknown>,
>(
	storeKey: keyof Draft<TState> & keyof TState,
	createActions: (set: SetFunction<TSlice>, getSlice: () => Draft<TSlice>) => TActions,
	initialState: TSlice,
): StateCreator<
	TState,
	[['zustand/devtools', never], ['zustand/subscribeWithSelector', never], ['zustand/immer', never]],
	[],
	TActions & TSlice
> {
	return createSliceHelper<TState>()(
		(state) => state[storeKey] as Draft<TSlice>,
		createActions,
		initialState,
		storeKey as string,
	)
}
