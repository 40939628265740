import { createContext, createElement, Suspense, useContext, useState } from 'react'

import { ModalName, ModalProps, ModalPropsUnion, modalRegistry } from './modal-registry'

type ModalContextType = {
	closeModal: () => void
	openModal: <T extends ModalName>(
		name: T,
		...modalProps: ModalProps<T> extends void ? [] : [props: ModalProps<T>]
	) => void
}

export const ModalContext = createContext<ModalContextType>({
	closeModal: () => {},
	openModal: () => {},
})

export function ModalProvider({ children }: { children: React.ReactNode }) {
	const [currentModal, setCurrentModal] = useState<{ name: ModalName; props: {} | ModalPropsUnion } | null>(null)

	const openModal = <T extends ModalName>(
		name: T,
		...modalProps: ModalProps<T> extends void ? [] : [props: ModalProps<T>]
	) => {
		setCurrentModal({ name, props: modalProps[0] ?? {} })
	}

	const closeModal = () => {
		setCurrentModal(null)
	}

	return (
		<ModalContext.Provider value={{ closeModal, openModal }}>
			{children}
			<Suspense>
				{currentModal != null && createElement(modalRegistry[currentModal.name].module, currentModal.props as any)}
			</Suspense>
		</ModalContext.Provider>
	)
}

export function useCloseModal() {
	const context = useContext(ModalContext)
	if (context === undefined) {
		throw new Error('useCloseModal must be used within a ModalProvider')
	}

	const closeModal = () => {
		context.closeModal()
	}

	return closeModal
}

export function useOpenModal() {
	const context = useContext(ModalContext)
	if (context === undefined) {
		throw new Error('useOpenModal must be used within a ModalProvider')
	}

	/**
	 *
	 * @param modalName modal name as it appears as a key of modalRegistry
	 * @param modalProps type safe props of modal
	 */
	function openModal<T extends ModalName>(
		modalName: T,
		...modalProps: ModalProps<T> extends void ? [] : [props: ModalProps<T>]
	) {
		context.openModal(modalName, ...modalProps)
	}

	return openModal
}
