import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function appBar(): CssVarsThemeOptions['components'] {
	return {
		MuiAppBar: {
			styleOverrides: {
				root: ({ theme }) => ({
					alignItems: 'center',
					backgroundColor: theme.vars.palette.background.default,
					boxShadow: '0 0.188rem 0.375rem rgba(0, 0, 0, 0.08)',
					display: 'flex',
					flex: 1,
					flexDirection: 'row',
					flexWrap: 'wrap',
					height: 'var(--header-height)',
					justifyContent: 'space-between',
					maxHeight: 'var(--header-height)',
					position: 'sticky',
					width: '100%',
				}),
			},
		},
	}
}
