import { useFlags } from 'launchdarkly-react-client-sdk'
import { useCallback, useMemo } from 'react'
import { BenefitPlanV2 } from 'store/benefit-plans/types'

import { PipeFuncReturn } from '.'

/**
 * This will ensure that only the grandfathered plan or the parent plan will be returned,
 *   based on if there is a grandfathered plan and if it is enrolled in
 * @param listsOfPlans The list of plans to filter out grandfathered/parents plans
 * @returns A filtered list of plans
 */
export const useConsolidateGrandfatherAndParentPlans = (listsOfPlans: BenefitPlanV2[][]) => {
	const results = listsOfPlans.map((plans) => {
		return plans.filter((plan) => {
			// Has no grandfathered and parent plan
			if (!plan.parentPlanId && !plan.grandfatheredPlanId) return true

			// Is the grandfathered plan and is enrolled and the parent plan is NOT enrolled
			if (
				!!plan.parentPlanId &&
				plan.isEnrolled &&
				!plans.find((p) => p.benefitPlanId === plan.parentPlanId)?.isEnrolled
			)
				return true

			// Is the parent plan and (is enrolled OR the grandfathered plan is NOT enrolled)
			if (
				!!plan.grandfatheredPlanId &&
				(plan.isEnrolled || !plans.find((p) => p.benefitPlanId === plan.grandfatheredPlanId)?.isEnrolled)
			)
				return true

			return false
		})
	})

	return { results }
}

// convert this based off usePipeThirdPartyData
export const usePipeConsolidateGrandfatherAndParentPlans = (): PipeFuncReturn<BenefitPlanV2[][]> => {
	const { b4b_7639_grandfathered_plans } = useFlags()

	const callback = useCallback(
		(data: BenefitPlanV2[][]) => {
			if (!b4b_7639_grandfathered_plans) {
				return data
			} else {
				return data.map((plans) => {
					return plans.filter((plan) => {
						// Has no grandfathered and parent plan
						if (!plan.parentPlanId && !plan.grandfatheredPlanId) return true

						// Is the grandfathered plan and is enrolled and the parent plan is NOT enrolled
						if (
							!!plan.parentPlanId &&
							plan.isEnrolled &&
							!plans.find((p) => p.benefitPlanId === plan.parentPlanId)?.isEnrolled
						)
							return true

						// Is the parent plan and (is enrolled OR the grandfathered plan is NOT enrolled)
						if (
							!!plan.grandfatheredPlanId &&
							(plan.isEnrolled || !plans.find((p) => p.benefitPlanId === plan.grandfatheredPlanId)?.isEnrolled)
						)
							return true

						return false
					})
				})
			}
		},
		[b4b_7639_grandfathered_plans],
	)

	return useMemo(
		() => ({
			apply: callback,
			subscription: { data: b4b_7639_grandfathered_plans, isLoading: false },
		}),
		[b4b_7639_grandfathered_plans, callback],
	)
}
