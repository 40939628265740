import { queryOptions } from '@tanstack/react-query'
import { algoliaClient } from 'config/search-config'
import { ConfiguredIndices } from 'state/slices/search'

import { searchKeys } from '../keys'
import { SearchFilters } from '../types/search'
import { createSearchConfig, CreateSearchConfigOptions } from '../utils/searchConfig/create-search-config'

export interface CategoryFacetHit extends FacetHits {
	isMemberExclusive: boolean
}

import { FacetHits } from 'algoliasearch'

import { searchPresets } from '../utils'

/**
 * @param attribute - Attribute to facet on in the search index @default 'categoryNames'
 * @param maxFacetHits - Maximum number of facet values to return @default 100
 * @param sortFacetValuesBy - How to sort the facet values @default 'alpha'
 * @param meta.isDiscountsOnly - When true, excludes facets containing 'benefits' in their value
 */
export type FacetParams = {
	/** Attribute to facet on in the search index @default 'categoryNames' */
	attribute?: string
	filters?: SearchFilters
	/** Maximum number of facet values to return @default 100 */
	maxFacetHits?: number
	/**
	 * @param isDiscountsOnly - When true, excludes facets containing 'benefits' in their value
	 */
	meta?: {
		/** When true, excludes facets containing 'benefits' in their value */
		isDiscountsOnly?: boolean
	}
	/** How to sort the facet values @default 'alpha' */
	sortFacetValuesBy?: 'alpha' | 'count'
}

/**
 * Facets are the cateogries that offers are put into
 * - here we set up a shared query with the base filters and params for fetching them
 */
export function getFacetsQuery(
	index: ConfiguredIndices,
	params: FacetParams = { meta: {} },
	opts: CreateSearchConfigOptions = { algoliaIndexName: '' },
) {
	const {
		attribute = 'categoryNames',
		filters,
		maxFacetHits = 100,
		meta: { isDiscountsOnly = false } = {},
		sortFacetValuesBy = 'alpha',
	} = params

	return queryOptions({
		queryFn: async (): Promise<({ [key: string]: boolean } & FacetHits)[]> => {
			const searchConfig = createSearchConfig(
				index,
				{
					filters: filters || {},
					maxFacetHits,
					sortFacetValuesBy,
				},
				opts,
			)

			const response = await algoliaClient.searchForFacets({
				requests: [
					{
						facet: attribute,
						indexName: opts.algoliaIndexName,
						type: 'facet',
						...searchConfig,
					},
				],
			})

			const facetHits = response.results[0].facetHits.filter((facet) => {
				if (isDiscountsOnly && facet.value.toLowerCase().includes('benefits')) {
					return false
				}

				return facet.count > 0
			})

			return facetHits as ({ [key: string]: boolean } & FacetHits)[]
		},
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: searchKeys.facets.all(),
		staleTime: 5 * 60 * 1000, // 5 minutes
	})
}

/**
 * Member exclusive facets
 * eg: Wawa exclusive offers
 */
export function getMemberExclusiveFacetsQuery(
	params?: FacetParams,
	opts: CreateSearchConfigOptions = { algoliaIndexName: '' },
) {
	return queryOptions({
		...getFacetsQuery(
			'offers',
			{
				...params,
				filters: {
					...params?.filters,
					...searchPresets.memberExclusive(true),
				},
				meta: {
					...params?.meta,
				},
			},
			{
				...opts,
				omit: {
					facets: true,
					query: true,
				},
			},
		),
		// @ts-expect-error not assignable to the target type
		queryKey: searchKeys.facets.memberExclusive(),
		select: (data: ({ [key: string]: boolean } & FacetHits)[]) => {
			return data.filter((facet) => facet.count > 0 && facet.isMemberExclusive)
		},
	})
}

/**
 * Non-member exclusive facets
 * Auto and Home, Health and Wellness, etc
 */
export function getNonExclusiveFacetsQuery(
	params?: FacetParams,
	opts: CreateSearchConfigOptions = { algoliaIndexName: '' },
) {
	return queryOptions({
		...getFacetsQuery(
			'offers',
			{
				...params,
				filters: {
					...params?.filters,
					...searchPresets.memberExclusive(false),
				},
				meta: {
					...params?.meta,
				},
			},
			{
				...opts,
				omit: {
					facets: true,
					query: true,
				},
			},
		),
		// @ts-expect-error not assignable to the target type
		queryKey: searchKeys.facets.nonMemberExclusive(),
		select: (data) => {
			return data.filter((facet) => facet.count > 0 && !facet.isMemberExclusive)
		},
	})
}
