import { useSelector } from 'react-redux'
import { getUserManagerSelector } from 'store/user/selectors'

/**
 * This component is solely for handling redirects after authentication
 */
const Callback = () => {
	const userManager = useSelector(getUserManagerSelector)
	userManager.signinCallback()

	return <div />
}

export default Callback
