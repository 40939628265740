// Function to clear all UTM related data from session storage
export function clearUTMFromSessionStorage() {
	const keysToRemove: string[] = []
	// Collect all keys that start with 'utm_'
	for (let i = 0; i < sessionStorage.length; i++) {
		const key = sessionStorage.key(i)
		if (key && key.startsWith('utm_')) {
			keysToRemove.push(key)
		}
	}
	// Remove the collected keys
	keysToRemove.forEach((key) => sessionStorage.removeItem(key))
}

// Function to get all cookies as an object
export function getCookies() {
	return document.cookie.split(';').reduce((cookies, cookie) => {
		const [name, value] = cookie.trim().split('=')
		cookies[name] = value

		return cookies
	}, {})
}

// Function to get UTM related data from session storage
export function getUTMParamsFromSessionStorage() {
	const utmParams = {
		utm_campaign: '',
		utm_content: '',
		utm_medium: '',
		utm_source: '',
		utm_term: '',
	}

	Object.keys(utmParams).forEach((key) => {
		const value = sessionStorage.getItem(key)
		utmParams[key] = value?.toLowerCase() ?? ''
	})

	return utmParams
}

// Function to remove specified cookies
export function removeCookies(cookies) {
	const domain = 'corestream.com' // Specify the domain to ensure correct cookie removal
	Object.keys(cookies).forEach((key) => {
		if (key.startsWith('utm_')) {
			// Check if the cookie is a UTM parameter
			document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Domain=${domain}; Secure; SameSite=None`
		}
	})
}

// Function to store specified cookies in sessionStorage
export function storeCookiesInSessionStorage(cookies) {
	Object.entries(cookies).forEach(([key, value]) => {
		if (key.startsWith('utm_')) {
			// Check if the cookie is a UTM parameter
			sessionStorage.setItem(key, decodeURIComponent(value as string))
		}
	})
}
