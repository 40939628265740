import { BenefitPlanV2 } from 'store/benefit-plans/types'

export function isAccessoryPlan(benefitPlan: BenefitPlanV2): boolean {
	return benefitPlan.benefitPlanGroupPrimaryId !== null
}

export function isAccessoryToPrimaryPlan(benefitPlan: BenefitPlanV2, primaryBenefitPlanId: number): boolean {
	return benefitPlan.benefitPlanGroupPrimaryId === primaryBenefitPlanId
}

export function isEnrolledInNativePlan(enrollmentPlans: BenefitPlanV2[]): boolean {
	return enrollmentPlans.some((plan) => plan.isNative && plan.isEnrolled)
}

export function isNotAccessoryPlan(benefitPlan: BenefitPlanV2): boolean {
	return benefitPlan.benefitPlanGroupPrimaryId === null
}
