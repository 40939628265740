import { CircularProgress } from '@mui/material'
import { useExperienceConfig } from 'hooks/experience/useExperienceConfig'
import { useIsSingleProductOE } from 'hooks/oe'
import useRecentOECompletion, { useRecentNewHireCompletion } from 'hooks/use-recent-oe-completion'
import { useEnrollmentData } from 'hooks/user-data'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { Suspense, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { userEnrollmentOverviewStatusSelector } from 'store/enrollments/selectors'
import { SUCCESS } from 'types/status-types'
import { LOCAL_STORAGE_KEYS } from 'utils/storage/local-storage'

import styles from './post-login-feature-router.module.css'
import { determinePostLoginRoute } from './post-login-util'

type PostLoginProps = {
	children: any
}

// Component to handle post-login logic and rendering
export const PostLoginFeatureRouter = ({ children }: PostLoginProps) => {
	const [hasCompletedPostLoginLogic, setHasCompletedPostLoginLogic] = useState<boolean>(
		!!localStorage.getItem(LOCAL_STORAGE_KEYS.CompletedPostLoginLogic),
	)

	// Handle the completion of post-login logic
	const handleCompletion = useCallback(() => {
		setHasCompletedPostLoginLogic(true)
	}, [])

	// If post-login logic has been completed, render children immediately
	if (hasCompletedPostLoginLogic) return children

	// Otherwise, render PostLoginLogic component with a loading fallback
	return (
		<Suspense fallback={<FullPageLoader />}>
			<PostLoginLogic onComplete={handleCompletion} />
			{!hasCompletedPostLoginLogic ? <FullPageLoader /> : children}
		</Suspense>
	)
}

const FullPageLoader = () => (
	<div className={styles['loading-container']}>
		<CircularProgress />
	</div>
)

interface PostLoginLogicProps {
	onComplete: () => void
}

// Component to handle the actual post-login logic
function PostLoginLogic({ onComplete }: PostLoginLogicProps) {
	const { isAllDataLoaded, isNewHire, isOE } = useEnrollmentData()
	const hasCompletedOE = useRecentOECompletion()
	const hasCompletedNewHire = useRecentNewHireCompletion()
	const { result: isSingleProductOE } = useIsSingleProductOE()
	const userEnrollmentOverviewStatus = useSelector(userEnrollmentOverviewStatusSelector)
	const { landingPageIfNotDeepLink } = useExperienceConfig()
	const ldClient = useLDClient()
	const navigate = useNavigate()
	const [route, setRoute] = useState<null | string>(null)

	useEffect(() => {
		if (isAllDataLoaded && userEnrollmentOverviewStatus === SUCCESS) {
			new Promise<void>((resolve) => {
				const context = ldClient?.getContext()
				if (context && 'user' in context && context.user.employeeId !== 'DEFAULT') {
					setRoute(
						determinePostLoginRoute({
							hasCompletedNewHire,
							hasCompletedOE,
							isNewHire,
							isOE,
							isSingleProductOE,
							landing: landingPageIfNotDeepLink,
						}),
					)
					resolve()
				}
			})
		} else {
			new Promise<void>(() => {})
		}
	}, [
		landingPageIfNotDeepLink,
		hasCompletedNewHire,
		hasCompletedOE,
		isAllDataLoaded,
		isNewHire,
		isOE,
		isSingleProductOE,
		ldClient,
		userEnrollmentOverviewStatus,
	])

	useEffect(() => {
		// Once a route is determined, navigate to it and mark post-login logic as completed
		if (route) {
			navigate(route)
			localStorage.setItem(LOCAL_STORAGE_KEYS.CompletedPostLoginLogic, 'true')
			// Delay the onComplete call to ensure navigation has finished
			setTimeout(onComplete, 100)
		}
	}, [navigate, onComplete, route])

	return null
}
