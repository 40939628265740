import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'

import { configuredLogRocketMiddleware, errorMiddleware } from './middlewares'
import rootReducer from './rootReducer'

const middleware = [thunk, errorMiddleware, configuredLogRocketMiddleware]

export const store = configureStore({ middleware, reducer: rootReducer })

// Inferred type
export type AppDispatch = typeof store.dispatch
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
