import { Hit } from 'algoliasearch'

import { OfferCardFieldResult } from '../views/offers'

export type MappedOffer<T extends OfferCardFieldResult> = {
	description: string
	isMemberExclusive: boolean
} & Omit<Hit<T>, 'description'>

export function mapOfferHits<T extends OfferCardFieldResult>(
	hits: Hit<{ isMemberExclusiveOffer?: boolean | undefined } & T>[],
): MappedOffer<T>[] {
	return hits.map((hit) => {
		const { isMemberExclusiveOffer, ...rest } = hit

		return {
			...rest,
			description: rest.description ?? '',
			isMemberExclusive: Boolean(isMemberExclusiveOffer),
		} as MappedOffer<T>
	})
}
