import { createContext, useContext } from 'react'

export type AlgoliaEventType = 'click' | 'conversion' | 'view'

export type AlgoliaTrackingProps = {
	eventType?: AlgoliaEventType
	objectId?: string
	position?: number
	queryId?: string
	searchIndex: string
}

/**
 * A read heavy context, used for adding shared context around algolia click tracking / recommendations / ai / etc
 */
export const AlgoliaTracking = createContext({} as AlgoliaTrackingProps)

export const useAlgoliaTrackingContext = () => {
	const context = useContext(AlgoliaTracking)

	return context
}
