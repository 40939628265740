import { capitalize } from '@mui/material'
import { AsyncProviderConfig, asyncWithLDProvider, LDContext } from 'launchdarkly-react-client-sdk'
import { StrictMode } from 'react'
import { Provider } from 'react-redux'
import UrlBuilder from 'services/util/url-builder'
import { store } from 'store/store'
import { Properties, SiteInfoResponse } from 'types/tenant-site-types'
import { Environments } from 'utils/env'

import { fetchMetaVersion } from '../utils/meta-version'
import { App } from './app'
import { AppContextProvider } from './contexts/app-context'
import { SiteEnvironment } from './types'
import { parseEnvAndDomainFromHost } from './utils/parseEnvAndDomainFromHost'

/**
 * Fetches data related to tenant, sets up LaunchDarkly syncronously, and renders the app afterwards
 */
export async function setupApp(site: SiteEnvironment, root) {
	const siteInfo = await getSiteInfo(site)

	if ('error' in siteInfo) {
		// eslint-disable-next-line no-console
		console.trace(siteInfo.error)

		return root.render(
			<div style={{ alignItems: 'center', display: 'flex', gap: '1rem', height: '100vh', justifyContent: 'center' }}>
				<h1>
					An Error Has Occured Retrieving {capitalize(parseEnvAndDomainFromHost(window.location.host).subdomain)}&apos;s
					Tenant Properties:
				</h1>
				<p>{String(siteInfo.error)}</p>
			</div>,
		)
	}

	const LDProvider = await asyncWithLDProvider(await configureLaunchDarkly(site.env as Environments, siteInfo))

	root.render(
		<StrictMode>
			<Provider store={store}>
				<LDProvider>
					<AppContextProvider values={{ site, siteInfo }}>
						<App />
					</AppContextProvider>
				</LDProvider>
			</Provider>
		</StrictMode>,
	)
}

// ********************************************************************************************************************

/**
 * Setup the LaunchDarkly contexts for the user and organization
 */
async function configureLaunchDarkly(env: Environments, siteInfo: SiteInfoResponse): Promise<AsyncProviderConfig> {
	const ldClientId = getLdClientIdForEnv(env)
	const organizationName = siteInfo?.organizationName ?? ''
	const key = organizationName?.trim()?.toUpperCase() ?? 'DEFAULT'

	const userContext: LDContext = {
		anonymous: true,
		employeeId: 'DEFAULT',
		kind: 'user',
		organization: organizationName,
	}

	const orgContext: LDContext = {
		key: key.replace(/ /g, '_'),
		kind: 'organization',
		name: organizationName,
	}

	const { version = 'unknown' } = await fetchMetaVersion()

	return {
		clientSideID: ldClientId,
		context: {
			kind: 'multi',
			organization: orgContext,
			user: userContext,
		},
		options: {
			application: {
				id: 'cs-client-ui',
				version,
			},
			eventCapacity: 500,
		},
		reactOptions: { useCamelCaseFlagKeys: false },
	}
}

// quick utility function to generate the tenant url
function generateTenantUrl(object: { env: string; tenant: string }): string {
	return `${object.tenant}.${object.env}.corestream.com`
}

/**
 * From the environment, get the correct LD client id configured in the .env files
 */
function getLdClientIdForEnv(env: Environments) {
	const ldClientIds = {
		[Environments.DEV]: import.meta.env.VITE_LD_ID_DEV,
		[Environments.INT]: import.meta.env.VITE_LD_ID_INT,
		[Environments.UAT]: import.meta.env.VITE_LD_ID_UAT,
	}

	const ldClientId = ldClientIds[env] || import.meta.env.VITE_LD_ID

	return ldClientId
}

async function getSiteInfo(site: SiteEnvironment): Promise<{ error: Error | null } | SiteInfoResponse> {
	const url = generateTenantUrl({
		env: site.env,
		tenant: site.subdomain,
	})

	// UrlBuilder setHost() sets in sessionStorage
	// when app loads, auth is rebuilt and checks for this host value in sessionStorage
	// yes, this is a hack, but it works

	const response = await fetch(new UrlBuilder().setHost(site.env).setEndpoint('TenantSites', url).url())
		.then((response) => {
			if (!response.ok) throw new Error(`Network response was not ok: ${response.statusText}`)

			return response.json()
		})
		.then((data) => {
			const result = data as SiteInfoResponse

			result.properties = new Properties(data.properties)

			return result
		})
		.catch((error: Error | null) => ({ error }))

	return response
}
