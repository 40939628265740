import { Link, List, ListItemButton, ListItemText } from '@mui/material'
import { cx } from 'class-variance-authority'
import { useTrackDetailedPageEvent } from 'hooks/analytics'
import { useUserBenefitEligibility } from 'hooks/user-data/use-benefit-eligibility'
import { User } from 'oidc-client'
import { ReactNode } from 'react'
import { routesMap } from 'routes/routes-map'
import { LinkBehavior } from 'theme/routing/LinkBehavior'

import { Tabs } from '../../types'
import mobileStyles from '../mobile-menu/mobile-menu.module.scss'
import desktopStyles from './navigation-tabs.module.css'

/**
 * Theses are the default tabs that will show regardless of if a user is logged in or not
 * These tabs will not call any hooks that require user authentication
 */
export const DefaultTabs = (props: {
	activeTab?: any
	callback?: () => void
	children?: ReactNode
	mobileView?: boolean
	user: undefined | User
}) => {
	const isBenefitsEligible = useUserBenefitEligibility()
	const track = useTrackDetailedPageEvent()
	const styles = props.mobileView ? mobileStyles : desktopStyles

	const handleHomeClick = (): void => {
		track({
			actionType: 'click',
			elementName: 'mega menu home cta',
			elementType: 'button',
		})
		if (props.callback) props.callback()
	}
	const handleHelpClick = (): void => {
		track({
			actionType: 'click',
			elementName: 'mega menu help cta',
			elementType: 'button',
		})
		if (props.callback) props.callback()
	}

	if (props.mobileView) {
		return (
			<List>
				<li>
					<ListItemButton component={LinkBehavior} href={routesMap.home} onClick={handleHomeClick}>
						<ListItemText
							id='mobile-home-menu'
							primary='Home'
							primaryTypographyProps={{
								component: 'p',
								variant: 'h2',
							}}
						/>
					</ListItemButton>
				</li>
				{props.children}
				<li>
					<ListItemButton component={LinkBehavior} href={routesMap.faq} onClick={handleHelpClick}>
						<ListItemText
							id='mobile-help-menu'
							primary='Help'
							primaryTypographyProps={{
								component: 'p',
								variant: 'h2',
							}}
						/>
					</ListItemButton>
				</li>
			</List>
		)
	}

	return (
		<nav className={cx(styles['container'], { [styles['has-user']]: props.user })}>
			{isBenefitsEligible && (
				<Link
					href={props.user ? routesMap.home : routesMap.start}
					id='home'
					className={cx(styles['tab'], {
						[styles['tab-active']]: props.activeTab === Tabs.Home,
					})}
					onClick={handleHomeClick}
				>
					Home
				</Link>
			)}
			{props.children}
			<Link
				href={routesMap.faq}
				id='help'
				className={cx(styles['tab'], {
					[styles['tab-active']]: props.activeTab === Tabs.Help,
				})}
				onClick={handleHelpClick}
			>
				Help
			</Link>
		</nav>
	)
}
