import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { hexToFilter } from 'feature/benefit-plan/modules/utils/get-color-filter'
import { ReactNode } from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { ThemeProvider as CSMuiThemeProvider } from 'theme'
import { Properties } from 'types/tenant-site-types'
import { ThemeEngine } from 'types/theme'
import { hexToHSLA } from 'utils/utils'

import { getTextColor } from './utils/colors'

const GlobalStyles = createGlobalStyle<{ globalElements: Properties }>`
	:root {
		--color-alert: ${(props) => props.globalElements.alertColor};
		--color-alert-filter: ${(props) => hexToFilter(props.globalElements.alertColor ?? '')};
		--color-alert-text: ${(props) => getTextColor(props.globalElements.alertColor)};
		--color-background: ${(props) => props.globalElements.backgroundColor};
		--color-background-tinted: ${(props) => hexToHSLA(props.globalElements.backgroundColor ?? '', 0.6, 1.4, 0.6)};
		--color-cta: ${(props) => props.globalElements.ctaColor};
		--color-foreground-text: '#333';
		--color-primary: ${(props) => props.globalElements.brandColor};
		--color-cta-desaturated: ${(props) => hexToHSLA(props.globalElements.ctaColor ?? '', 0.3)};
		--color-cta-tinted: ${(props) => hexToHSLA(props.globalElements.ctaColor ?? '', 0.6, 1.4, 1)};
		--image-background: url(${(props) => props.globalElements.backgroundImage});
		._hj_feedback_container {
			display: none;
		}
	}
`

export const ThemeRegistration = (props: { children: ReactNode; properties: Properties }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<CSMuiThemeProvider properties={props.properties}>
				<StyledEngineProvider injectFirst>
					<ThemeProvider theme={new ThemeEngine(props.properties)}>
						<CssBaseline />
						<GlobalStyles globalElements={props.properties} />
						{props.children}
					</ThemeProvider>
				</StyledEngineProvider>
			</CSMuiThemeProvider>
		</LocalizationProvider>
	)
}
