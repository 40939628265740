import { useQuery } from '@tanstack/react-query'
import { useGetEnvIndex } from 'config/search-config'

import { getMarketingContentByIdQuery } from '../queryOptions/marketing-content'

export function useMarketingContentById(id: string, options: { enabled?: boolean } = {}) {
	const indexName = useGetEnvIndex('marketing_content')

	return useQuery({
		...getMarketingContentByIdQuery(id, indexName),
		...options,
	})
}
