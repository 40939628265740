import { alpha, CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function paper(): CssVarsThemeOptions['components'] {
	return {
		MuiPaper: {
			styleOverrides: {
				outlined: ({ theme }) => ({
					borderColor: alpha(theme.palette.grey[500], 0.16),
				}),
				root: {
					backgroundImage: 'none',
				},
			},
		},
	}
}
