import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Beneficiary, Relationship } from 'api/benefit-elections'
import omit from 'lodash-es/omit'
import { actions as enrollmentActions } from 'store/enrollments/slice'
import { OverviewSuccessData } from 'store/enrollments/types'
import { upsertWithoutImmer } from 'store/utils'
import { isEqualViaPropertyChecks } from 'utils/equality'

import { Dependent, PersonsState, Worker } from './types'
import { dependentInitialState, personsInitialState } from './types/initialStates'

const personsSlice = createSlice({
	extraReducers: (builder) => {
		builder.addCase(
			enrollmentActions.getUserElectionsSuccess,
			(state: PersonsState, action: PayloadAction<OverviewSuccessData>) => {
				//populate beneficiaries from overview data
				state.beneficiaries = action.payload.beneficiaries ?? []

				if (action.payload.workerEmailAddress) {
					state.worker.email = action.payload.workerEmailAddress
				}
			},
		)
	},
	initialState: personsInitialState,
	name: 'persons',
	reducers: {
		/**
		 * Adds an empty dependent object to the dependents array
		 */
		addEmptyDependent: (state: PersonsState, action: PayloadAction<Relationship>) => {
			state.dependents.push({
				...dependentInitialState,
				relationship: action.payload,
			})
		},
		/**
		 * Set the persons state
		 */
		initialize: (state: PersonsState, action: PayloadAction<PersonsState>) => {
			const beneficiaries = action.payload?.beneficiaries?.map((b) => ({
				...b,
				clientSideId: b.clientSideId ? b.clientSideId : self.crypto.randomUUID(),
			}))

			return { ...personsInitialState, ...action.payload, beneficiaries }
		},
		/**
		 * Adds the given beneficiaries to the array
		 */
		loadBeneficiaries: (
			state: PersonsState,
			action: PayloadAction<{ benefitPlanId?: number; formBeneficiaries: Beneficiary[] }>,
		) => {
			if (!state.beneficiaries) {
				state.beneficiaries = []
			}

			// remove all assignments that match benefit plan id arg
			state.beneficiaries = state.beneficiaries.map((b) => ({
				...b,
				assignments: b.assignments.filter((a) => a.benefitPlanId !== action.payload.benefitPlanId),
			}))

			// remove beneficiaries with no assignments
			// not necessary but cleans up store
			state.beneficiaries = state.beneficiaries.filter((b) => b.assignments?.length)

			action.payload.formBeneficiaries.forEach((b) => {
				state.beneficiaries = [...state.beneficiaries, b]
			})
		},
		/**
		 * Adds the given dependents to the array
		 */
		loadDependents: (state: PersonsState, action: PayloadAction<Dependent[]>) => {
			// get all ways to lookup an array item. 2D array supports composite keys
			const dependentLookupMethods: string[][] = [['id']]

			action.payload.forEach((item) => {
				if (!state.dependents) {
					state.dependents = []
				}

				state.dependents = upsertWithoutImmer(
					state.dependents as unknown as any[],
					omit(item, 'socialSecurityNumber'),
					isEqualViaPropertyChecks(dependentLookupMethods),
				)
			})
		},
		/**
		 * Adds any given WorkerInfo
		 */
		loadWorkerInfo: (state: PersonsState, action: PayloadAction<Partial<Worker>>) => {
			state.worker = {
				...state.worker,
				...omit(action.payload, 'socialSecurityNumber'),
			}
		},
		/**
		 * Removes all beneficiaries
		 */
		removeAllBeneficiaries: (state: PersonsState) => {
			state.beneficiaries = []
		},
		/**
		 * Removes all dependents
		 */
		removeAllDependents: (state: PersonsState) => {
			state.dependents = []
		},
		/**
		 * Removes the beneficiary at the given index
		 */
		removeBeneficiary: (state: PersonsState, action: PayloadAction<number>) => {
			state.beneficiaries.splice(action.payload, 1)
		},
		/**
		 * Removes the dependent at the given index
		 */
		removeDependent: (state: PersonsState, action: PayloadAction<number>) => {
			state.dependents.splice(action.payload, 1)
		},
		/**
		 * Removes new beneficiaries prior to populating slice to prevent dupes
		 * Remove this with und_12005_beneficiaries
		 */
		removeNewBeneficiaries: (state: PersonsState) => {
			state.beneficiaries = state.beneficiaries.filter((b) => !!b.beneficiaryId)
		},
		/**
		 * Removes all dependents without an id
		 */
		removeNewDependents: (state: PersonsState) => {
			state.dependents = state.dependents.filter((d) => !!d.id)
		},
		/**
		 * Update beneficiaries by their clientSideId property instead of standard identifying fields
		 */
		updateBeneficiariesByClientSideId: (state: PersonsState, action: PayloadAction<Beneficiary[]>) => {
			if (!state.beneficiaries) {
				state.beneficiaries = []
			}

			action.payload.forEach((b) => {
				const index = state.beneficiaries.findIndex((beneficiary) => beneficiary.clientSideId === b.clientSideId)

				if (index !== -1) {
					state.beneficiaries = [...state.beneficiaries.slice(0, index), b, ...state.beneficiaries.slice(index + 1)]
				}
			})
		},
		/**
		 * Updates the given fields of the Dependent with the given values.
		 */
		updateDependentField: (
			state: PersonsState,
			action: PayloadAction<{ dependent: Partial<Dependent>; index: number }>,
		) => {
			if (action.payload.index in state.dependents) {
				Object.keys(action.payload.dependent).forEach((key) => {
					if (key in state.dependents[action.payload.index])
						state.dependents[action.payload.index][key] = action.payload.dependent[key]
				})
			}
		},
		/**
		 * Updates the given fields of the Worker with the given values.
		 */
		updateWorkerField: (state: PersonsState, action: PayloadAction<Partial<Worker>>) => {
			if (!state.worker) state.worker = {} as Worker

			Object.keys(action.payload).forEach((key) => {
				if (state.worker?.[key]) state.worker[key] = action.payload[key]
			})
		},
	},
})

const { actions, reducer } = personsSlice
export { reducer as persons, actions as personsActions }
