import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function list(): CssVarsThemeOptions['components'] {
	return {
		MuiListItemAvatar: {
			styleOverrides: {
				root: ({ theme }) => ({
					marginRight: theme.spacing(2),
					minWidth: 'auto',
				}),
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: ({ theme }) => ({
					color: 'inherit',
					marginRight: theme.spacing(2),
					minWidth: 'auto',
				}),
			},
		},
		MuiListItemText: {
			styleOverrides: {
				multiline: {
					margin: 0,
				},
				root: {
					margin: 0,
				},
			},
		},
	}
}
