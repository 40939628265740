import { alpha, CssVarsTheme, Theme } from '@mui/material/styles'
import { CSSProperties } from '@mui/material/styles/createMixins'
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles/CssVarsProvider'
import experimental_extendTheme from '@mui/material/styles/experimental_extendTheme'
import { Properties } from 'types/tenant-site-types'

import { customShadows } from './custom-shadows'
import { componentsOverrides } from './overrides'
import { shadows } from './shadows'
import { typography } from './typography'

type Props = {
	children: React.ReactNode
	properties: Properties
}

export function ThemeProvider({ children, properties }: Props) {
	const colors = getColors(properties)

	const theme = experimental_extendTheme({
		colorSchemes: {
			light: {
				palette: {
					background: {
						default: getComputedStyle(document.documentElement).getPropertyValue('--neutral-neutral-100'),
						paper: getComputedStyle(document.documentElement).getPropertyValue('--neutral-neutral-100'),
					},
					error: {
						main: getComputedStyle(document.documentElement).getPropertyValue('--states-error'),
					},
					primary: {
						main: colors.primary,
					},
					secondary: {
						main: colors.secondary,
					},
					text: {
						primary: colors.text,
					},
				},
			},
		},
		components: componentsOverrides(),
		customShadows: {
			...customShadows(),
			primary: `0px 1px 2px 0px ${alpha(colors.primary, 0.1)}, 0px 4px 4px 0px ${alpha(
				colors.primary,
				0.09,
			)}, 0px 9px 5px 0px ${alpha(colors.primary, 0.05)}, 0px 15px 6px 0px ${alpha(
				colors.primary,
				0.01,
			)}, 0px 24px 7px 0px ${alpha(colors.primary, 0.0)}`,
			// primary: `0 8px 16px 0 ${alpha(colors.primary, 0.24)}`,
			secondary: `0px 1px 2px 0px ${alpha(colors.secondary, 0.1)}, 0px 4px 4px 0px ${alpha(
				colors.secondary,
				0.09,
			)}, 0px 9px 5px 0px ${alpha(colors.secondary, 0.05)}, 0px 15px 6px 0px ${alpha(
				colors.secondary,
				0.01,
			)}, 0px 24px 7px 0px ${alpha(colors.secondary, 0.0)}`,
		},
		shadows: shadows(),
		shape: { borderRadius: 8 },
		typography,
	})

	const themeWithCssFontBaseline: CssVarsTheme & Omit<Theme, 'palette'> = {
		...theme,
		components: {
			...theme.components,
			MuiCssBaseline: {
				...theme.components?.MuiCssBaseline,
				styleOverrides: (theme) => {
					const result = [
						cssPropertiesToString('h1', theme.typography.h1),
						cssPropertiesToString('h2', theme.typography.h2),
						cssPropertiesToString('h3', theme.typography.h3),
						cssPropertiesToString('h4', theme.typography.h4),
						cssPropertiesToString('h5', theme.typography.h5),
						cssPropertiesToString('h6', theme.typography.h6),
						cssPropertiesToString('body', theme.typography.body1),
						cssPropertiesToString('p', theme.typography.body1),
						// cssPropertiesToString('span', theme.typography.body1),
						cssPropertiesToString('body2', theme.typography.body2),
						cssPropertiesToString('caption', theme.typography.caption),
						cssPropertiesToString('button', theme.typography.button),
						cssPropertiesToString('subtitle1', theme.typography.subtitle1),
						cssPropertiesToString('subtitle2', theme.typography.subtitle2),
						cssPropertiesToString('overline', theme.typography.overline),
					].join('\n')

					return result
				},
			},
		},
	}

	return <CssVarsProvider theme={themeWithCssFontBaseline}>{children}</CssVarsProvider>
}

/**
 * utility function to help parse CSSProperties into a string
 */
function cssPropertiesToString(element: string, styles: CSSProperties): string {
	let cssString = `${element} {\n`
	let mediaQueries = ''

	Object.entries(styles).forEach(([key, value]) => {
		if (key.startsWith('@media')) {
			// Handle media queries
			mediaQueries += `  ${key} {\n    ${element} {\n`
			Object.entries(value as CSSProperties).forEach(([innerKey, innerValue]) => {
				mediaQueries += `      ${toKebabCase(innerKey)}: ${innerValue};\n`
			})
			mediaQueries += '    }\n  }\n'
		} else {
			// Regular CSS properties
			cssString += `  ${toKebabCase(key)}: ${value};\n`
		}
	})

	cssString += `}\n\n${mediaQueries}`

	return cssString
}

function getColors(properties: Properties) {
	return {
		primary: properties.ctaColor ?? getComputedStyle(document.documentElement).getPropertyValue('--client-colors-cta'),
		secondary:
			properties.alertColor ?? getComputedStyle(document.documentElement).getPropertyValue('--client-colors-accent'),
		text: getComputedStyle(document.documentElement).getPropertyValue('--neutral-neutral-0'),
	}
}

function toKebabCase(str: string): string {
	return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
}
