import { fetchMetaVersion } from './meta-version'

export async function runCacheBuster(): Promise<void> {
	const currentVersion = localStorage.getItem('appVersion')
	const { error, version: metaVersion } = await fetchMetaVersion({ showLogs: false })

	if (error || !metaVersion) {
		// eslint-disable-next-line no-console
		console.warn(`[cache-buster] Failed to fetch meta version\n\nvalue: ${metaVersion}\nerror:\n${error}`)

		return
	}

	const shouldForceRefresh = isThereNewVersion(metaVersion, currentVersion)

	if (shouldForceRefresh) {
		await refreshCacheAndReload(metaVersion)
	} else {
		localStorage.setItem('appVersion', metaVersion)
	}
}

function isThereNewVersion(metaVersion: string, currentVersion: null | string): boolean {
	if (!currentVersion) return false

	return metaVersion !== currentVersion
}

async function refreshCacheAndReload(newVersion: string): Promise<void> {
	try {
		localStorage.setItem('appVersion', newVersion)
		window.location.hash = `#${new Date().getTime()}`
		window.location.reload()
	} catch (error) {
		// eslint-disable-next-line no-console
		console.warn(`[cache-buster] Failed to refresh cache\n\nerror:\n${error}`)
	}
}
