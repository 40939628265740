import { BenefitPlanV2 } from 'store/benefit-plans/types'
import { WizardPlanData } from 'store/enrollment-wizard/types'
import { QLESubmissionProps } from 'types/enrollment-profile'
import { EnrolledPet, PetData } from 'types/pet'

import { CartItem } from './cartItemTypes'

export const initialState: CartState = {
	items: [],
	lifeEvent: {},
	meta: {
		acknowledgedFinalDisclaimer: undefined,
		newlyEnrolled: false,
		showAcknowledgeDisclaimerError: undefined,
	},
}

export type CartState = {
	items: CartItem[]
	lifeEvent: Partial<QLESubmissionProps>
	meta: Meta
}

export type EnrolledPetToCart = {
	disclaimer: string
	pet: EnrolledPet
	petFrequency: string
	plan: BenefitPlanV2
	postal: string
}

// The idea is to encapsulate volatility around new properties / recognize that this
// isn't necessarily cart data and could be categorized into another slice to keep this really lean.
export type Meta = {
	acknowledgedFinalDisclaimer?: boolean
	newlyEnrolled?: boolean
	showAcknowledgeDisclaimerError?: boolean
}

export type MetaField = {
	name: MetaNames
	value: any
}

export type PetToCart = {
	disclaimer: string
	effectiveDate?: Date
	pet: PetData
	petFrequency: string
	plan: BenefitPlanV2
	planSelection: WizardPlanData
	postal: string
}

type MetaNames = 'acknowledgedFinalDisclaimer' | 'enrollmentEvent' | 'newlyEnrolled' | 'showAcknowledgeDisclaimerError'
