const ERROR = 'error'
const IDLE = 'idle'
const LOADING = 'loading'
const SUCCESS = 'success'
const INFO = 'info'
const WARNING = 'warning'

export { ERROR, IDLE, INFO, LOADING, SUCCESS, WARNING }

export type StatusTypes = typeof ERROR | typeof IDLE | typeof INFO | typeof LOADING | typeof SUCCESS | typeof WARNING
