import { CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

export function timeline(): CssVarsThemeOptions['components'] {
	return {
		//@ts-ignore
		MuiTimelineConnector: {
			styleOverrides: {
				root: ({ theme }) => ({
					backgroundColor: theme.vars.palette.divider,
				}),
			},
		},
		//@ts-ignore
		MuiTimelineDot: {
			styleOverrides: {
				root: {
					boxShadow: 'none',
				},
			},
		},
	}
}
