import { BenefitPlanV2 } from 'store/benefit-plans/types'

import { BenefitContentVersion } from '../contexts'

export function getBenefitContentVersion(
	bp: BenefitPlanV2 | null,
	{ b4b_pdpv3 }: { b4b_pdpv3: any },
	isPreview?: boolean,
): BenefitContentVersion {
	const moduleInstances = bp?.modularMarketingContent?.moduleInstances

	// Preview is only available for pdpv3
	if (isPreview && moduleInstances) {
		return 'pdpv3'
	}

	if (b4b_pdpv3 && !moduleInstances) {
		return 'legacy'
	}

	if (b4b_pdpv3 && moduleInstances) {
		// check dates and return the correct version
		const now = new Date()
		const epoch = new Date(8.64e15)

		const startDate = new Date(
			bp?.modularMarketingContent?.startDate ? bp?.modularMarketingContent?.startDate * 1000 : epoch,
		)
		const endDate = new Date(bp?.modularMarketingContent?.endDate ? bp?.modularMarketingContent?.endDate * 1000 : epoch)

		// if these are blank - epoch will be returned
		if (startDate === epoch || endDate === epoch) {
			return 'legacy'
		}

		// non-native uses current timestamp while the natives uses `keyDate` on the plan itself
		if (!bp.isNative && now >= startDate && now <= endDate) {
			return 'pdpv3'
		}

		if (
			bp.isNative &&
			new Date(bp.keyDate ? bp.keyDate : epoch) >= startDate &&
			new Date(bp.keyDate ? bp.keyDate : epoch) <= endDate
		) {
			return 'pdpv3'
		}

		// fallback to legacy if not in date range
		return 'legacy'
	}

	return 'legacy'
}
