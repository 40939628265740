import { useAppSelector } from 'hooks/redux'
import { useScriptSrc } from 'hooks/use-script'
import { isLoggedInSelector } from 'store/user/selectors'
import { useGetEnv } from 'utils/env'

const useAccessTravelSource = () => {
	const env = useGetEnv()
	switch (env()) {
		case 'dev':
		case 'int':
		case 'test':
			return import.meta.env.VITE_ACCESSTRAVEL_SCRIPT_DEV
		default:
			return import.meta.env.VITE_ACCESSTRAVEL_SCRIPT_PROD
	}
}

export const useAccessTravel = () => {
	const isLoggedIn = useAppSelector(isLoggedInSelector)

	useScriptSrc(useAccessTravelSource(), isLoggedIn)
}
