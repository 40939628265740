import { paginationItemClasses } from '@mui/material/PaginationItem'
import { alpha, CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const

// NEW VARIANT
declare module '@mui/material/Pagination' {
	interface PaginationPropsColorOverrides {
		error: true
		info: true
		success: true
		warning: true
	}

	interface PaginationPropsVariantOverrides {
		soft: true
	}
}

// ----------------------------------------------------------------------

export function pagination(): CssVarsThemeOptions['components'] {
	return {
		MuiPagination: {
			styleOverrides: {
				root: ({ ownerState, theme }) => {
					const defaultColor = ownerState.color === 'standard'

					const filledVariant = ownerState.variant === 'text'

					const outlinedVariant = ownerState.variant === 'outlined'

					const softVariant = ownerState.variant === 'soft'

					const defaultStyle = {
						[`& .${paginationItemClasses.root}`]: {
							...(outlinedVariant && {
								borderColor: alpha(theme.palette.grey[500], 0.24),
							}),

							[`&.${paginationItemClasses.selected}`]: {
								fontWeight: theme.typography.fontWeightSemiBold,
								...(outlinedVariant && {
									borderColor: 'currentColor',
								}),

								...(defaultColor && {
									backgroundColor: alpha(theme.palette.grey[500], 0.08),
									...(filledVariant && {
										'&:hover': {
											backgroundColor: theme.palette.grey[700],
										},
										backgroundColor: theme.palette.text.primary,
										color: theme.palette.common.white,
									}),
								}),
							},
						},
					}

					const colorStyle = COLORS.map((color) => ({
						...(ownerState.color === color && {
							[`& .${paginationItemClasses.root}`]: {
								[`&.${paginationItemClasses.selected}`]: {
									...(ownerState.color === color && {
										// SOFT
										...(softVariant && {
											'&:hover': {
												backgroundColor: alpha(theme.palette[color].main, 0.16),
											},
											backgroundColor: alpha(theme.palette[color].main, 0.08),
											color: theme.palette[color]['dark'],
										}),
									}),
								},
							},
						}),
					}))

					return [defaultStyle, ...colorStyle]
				},
			},
		},
	}
}
