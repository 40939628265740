import { blacklistedFields } from 'hooks/logRocket/networkSanitizer'

import { ignoredAutoQuotesActions, ignoredEnrollmentWizardActions, ignoredOidcActions } from './config'

const ignoredActions = [...ignoredOidcActions, ...ignoredAutoQuotesActions, ...ignoredEnrollmentWizardActions]

export const actionSanitizer = (action) => {
	// ignore actions with blacklisted types
	if (ignoredActions.includes(action.type)) {
		return null
	}

	// ignore actions with blacklisted fields
	if (ignoreActionsWithBlacklistedFields(blacklistedFields, action.payload)) {
		return null
	}

	return action
}

/**
 * utility function for finding redux actions that contain blacklisted fields
 *
 * **NOTE - does not modify the original object**
 * @param blacklistedFields
 * @param body
 * @returns true if found
 */
export const ignoreActionsWithBlacklistedFields = (blacklistedFields: string[], body: any = {}) => {
	let found = false

	// recursive dfs function to search arrays and objects for blacklisted fields
	function recurse(body: any = {}) {
		// early exit
		if (found) return

		// iterate over collection of objects ex. [{}, ...]
		if (body && body.constructor === Array) {
			body.forEach((item) => recurse(item))
		} else {
			for (const key in body) {
				// eslint-disable-next-line no-prototype-builtins
				if (body.hasOwnProperty(key)) {
					const value = body[key]

					// recurse if value is an object
					if (typeof value === 'object') {
						recurse(value)
					}

					// otherwise check if key is blacklisted
					if (isKeyBlacklisted(blacklistedFields, key)) {
						found = true
					}
				}
			}
		}
	}

	recurse(body)

	return found
}

const isKeyBlacklisted = (blacklistedFields: string[], keyName: string): boolean => {
	const normalizedKeyName = keyName.toLowerCase()

	return blacklistedFields.some((field) => normalizedKeyName.indexOf(field.toLowerCase()) > -1)
}
