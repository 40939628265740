import { axiosInstance as axios } from 'config/axios'
import UrlBuilder from 'services/util/url-builder'

import { API_FEATURE, API_SUBDOMAIN } from '../../constants'
import { TrackedClickOffers } from '../../types/types'

const urlBuilder: UrlBuilder = new UrlBuilder(API_SUBDOMAIN)

/**
 * Service for tracking the view state of benefits / offers
 */
export class TrackingService {
	static fetchClickedOffers(): Promise<TrackedClickOffers> {
		return axios.get(urlBuilder.setEndpoint(API_FEATURE).url())
	}

	/**
	 * @param offerId - id of benefit plan / offer to be tracked and persisted for later
	 */
	static putClickedOffer(offerId: string) {
		return axios.put(urlBuilder.setEndpoint(`${API_FEATURE}/${offerId}`).url())
	}

	static removedClickedOffers(): Promise<void> {
		return axios.delete(urlBuilder.setEndpoint(API_FEATURE).url())
	}
}
