import LogRocket from 'logrocket'
import { useCallback } from 'react'

export type LogRocketExtra = Record<string, boolean | number | string>
export type LogRocketTags = Record<string, boolean | number | string>

/**
 * @returns a function to log an error to LogRocket
 */
export const useLogErrorLR = () => {
	return useCallback(logErrorLR, [])
}

/**
 * Log an error to LogRocket
 */
export function logErrorLR(error: Error, tags?: LogRocketTags, extra?: LogRocketExtra) {
	LogRocket.captureException(error, {
		extra,
		tags,
	})
}
