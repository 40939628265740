import { Campaign } from 'feature/home/layouts/product-values-home/variants/default/default-with-campaigns'
import { ThemePage } from 'feature/themes/types'
import { LDClient } from 'launchdarkly-js-client-sdk'
import { LDContext } from 'launchdarkly-react-client-sdk'

import { getUTMParamsFromSessionStorage } from '../cookies/utm-tracking'
import { ExperienceConfig } from './types'

export const ldIdentify = async (
	ldClient: LDClient | undefined,
	organizationName: string,
	userId: string,
	employeeId: string,
	employmentGroup?: string,
	employmentType?: string,
	isNewHire: boolean = false,
) => {
	const userContext: LDContext = {
		anonymous: false,
		employeeId,
		employmentGroup: employmentGroup ?? '',
		employmentType: employmentType ?? '',
		key: userId,
		kind: 'user',
		organization: organizationName, // remove at future date once LD 3.0 is working (this is now handled in orgContext)
		...getUTMParamsFromSessionStorage(),
		isNewHire,
	}

	const orgContext: LDContext = {
		key: userId,
		kind: 'organization',
		name: organizationName,
	}

	const multiContext: LDContext = {
		kind: 'multi',
		organization: orgContext,
		user: userContext,
	}

	await ldClient?.identify(multiContext)
}

declare module 'launchdarkly-react-client-sdk' {
	export interface LDFlagSet {
		b4b_17199_static_theme_page: ThemePage
		b4b_19026_experience_config: ExperienceConfig
		b4b_homepagecampaign: {
			campaigns: Campaign[]
			enabled: boolean | undefined
		}
	}
}
