export enum ApplicationStatus {
	Active = 'Active',
	// this is only used when user quits mid enrollment. Since it's not a 'final' state, we can ignore for UI purposes
	Canceled = 'Canceled',
	Completed = 'Completed',
	// used for shopping cart flow triggered when `customFunctionality` is enabled
	PendingCompletion = 'Pending Completion',
	Waived = 'Waived',
}

export const ActiveTrustmarkApplicationStatuses = [ApplicationStatus.Active]

export const EnrolledTrustmarkStatuses = [ApplicationStatus.Active, ApplicationStatus.Completed]
