import { FacetConfig } from 'state/slices/search'

export type AlgoliaFacetConfig = {
	limit?: number
	name: string
	sortBy?: 'count' | 'value'
}

export type FacetFilter = {
	activeFilters: string[]
	combineWithOr?: boolean
	facetName: string
}

export type FacetFilterOptions = {
	/**
	 * When true, items matching ANY of the values within a facet will be returned
	 * When false, items must match ALL values within a facet
	 * Default: false (AND behavior)
	 */
	combineWithOr?: boolean
}

/**
 * Converts an array of FacetFilter objects into Algolia's facetFilters format
 * @param facetFilters - Array of facet filters to process
 * @returns Array of facet filter strings in Algolia format
 *
 * @example
 * buildFacetFilters([
 *   { facetName: 'category', activeFilters: ['book', 'movie'], combineWithOr: true },
 *   { facetName: 'author', activeFilters: ['John'] }
 * ])
 *
 * result:
 * [
 *   ['category:book', 'category:movie'],
 *   'author:John'
 * ]
 */
export function buildFacetFilters(facetFilters: FacetFilter[]): (string | string[])[] {
	return facetFilters
		.filter((filter) => filter.activeFilters?.length > 0)
		.map((filter) => createFacetFilters(filter))
		.flat()
}

/**
 * Creates facet filters from a simple Record of facet names to values
 * @param facetRecord - Object where keys are facet names and values are arrays of filter values
 * @param facetConfig - Object where keys are facet names and values are booleans
 * indicating whether to combine filters with OR
 * @returns Array of facet filter strings in Algolia format
 *
 * @example
 * createFacetFiltersFromRecord({
 *   categoryNames: ['home', 'garden'],
 *   status: ['active']
 * }, { categoryNames: { combineWithOr: true } })
 *
 * result:
 * [
 *   ['categoryNames:home', 'categoryNames:garden'],
 *   'status:active'
 * ]
 *
 * @example
 * createFacetFiltersFromRecord({
 *   categoryNames: ['home', 'garden'],
 *   status: ['active']
 * })
 *
 * result:
 * [
 *   'categoryNames:home',
 *   'categoryNames:garden',
 *   'status:active'
 * ]
 */
export function createFacetFiltersFromRecord(
	facetRecord: Record<string, string[]>,
	facetConfig: Record<string, FacetConfig> = {},
): (string | string[])[] {
	return buildFacetFilters(
		Object.entries(facetRecord)
			.filter(([, values]) => values?.length > 0)
			.map(([facetName, values]) => ({
				activeFilters: values,
				combineWithOr: facetConfig[facetName]?.combineWithOr ?? false,
				facetName,
			})),
	)
}

/**
 * Creates a facet filter string in Algolia's 'attribute:value' format
 */
export function createFacetFilterString(facetName: string, value: string): string {
	return `${facetName}:${value}`
}

/**
 * Generates an array of facet filter strings for a single facet
 */
function createFacetFilters(facetFilter: FacetFilter): string[] | string[][] {
	if (!facetFilter.activeFilters?.length) return []

	const filters = facetFilter.activeFilters.map((filter) => createFacetFilterString(facetFilter.facetName, filter))

	if (facetFilter.combineWithOr) {
		return [filters]
	}

	return filters
}
