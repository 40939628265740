import { RouteObject } from 'react-router'

export function preparePath(path: string, options?: { stripLeading: string }) {
	const { stripLeading = '/' } = options || {}

	return stripLeading ? (path.startsWith(stripLeading) ? path.slice(stripLeading.length) : path) : path
}

export function prepareRoutes(breadcrumbRoutes: RouteObject[], { stripLeading = '/' }) {
	return breadcrumbRoutes.map((route) => ({
		...route,
		path: stripLeading
			? route?.path?.startsWith(stripLeading)
				? route?.path.slice(stripLeading.length)
				: route.path
			: route.path,
	}))
}
