interface Categories {
	benefits: string
	discounts: string
	profile: string
	registration: string
}

interface Events {
	benefit: {
		learnMoreClicked: string
		viewAllClicked: string
	}
	benefitEnrollment: {
		cancelled: string
		submission: {
			error: string
			success: string
		}
		trustmark: {
			enrollmentDataFetched: string
		}
	}
	benefitsWizard: {
		addedPet: string
		cancelModal: {
			cancel: string
			close: string
			nevermind: string
			next: string
			opened: string
		}
		getStartedClicked: string
		navigateAwayPopup: {
			continueClicked: string
			leaveClicked: string
		}
		planTypeClicked: string
		removedPet: string
		saveAndContinue: string
		steps: {
			confirmation: {
				exploreMoreBenefitsClicked: string
			}
			stepOne: {
				back: string
				cancel: string
				continue: string
				learnMoreClicked: string
				next: string
				undo: string
				waive: string
			}
			stepThree: {
				back: string
				submit: string
			}
			stepTwo: {
				back: string
				complete: string
				dependentAdded: string
				dependentInfoModified: string
				dependentRemoved: string
				next: string
				personalInfoChanged: string
			}
		}
		trustmark: {
			closedTrustmarkModal: string
			declinedTrustmarkCoverage: string
			enrollmentCompleted: string
			enrollmentPendingCompletion: string
			openedTrustmarkModal: string
		}
	}
	category: {
		autoQuote: string
		viewed: string
	}
	error: {
		login: string
		registration: string
	}
	header: {
		helpClicked: string
		homeClicked: string
		myProfileClicked: string
		orgLogoClicked: string
	}
	loginAttempt: string
	logoutAttempt: string
	megaMenu: {
		benefits: {
			allAvailableBenefits: string
			benefitsHome: string
			category: string
			enrolledBenefits: string
			recommendations: string
			reviewDeductions: string
		}
		deals: {
			category: string
			dealsHome: string
		}
	}
	product: {
		clicked: string
		learnMoreClicked: string
		list: {
			filtered: string
			viewed: string
		}
		searched: string
		viewed: string
	}
	productPage: {
		abandoned: string
		enrollment: {
			cancelledCoverage: string
			enrollNowClicked: string
			modifiedCoverage: string
		}
		fullRateTable: {
			interactionAttempted: string
			linkClicked: string
		}
	}
	qleAction: string
	registration: {
		clicked: {
			benefitIcon: string
			providerLogo: string
		}
		emailVerified: {
			match: string
			noMatch: string
		}
	}
	standard: {
		learnMore: string
		startEnrollmentOrMakeChanges: string
	}
	topTileClicked: string
	unauthHome: {
		clicked: {
			benefitIcon: string
			providerLogo: string
		}
	}
	user: {
		abandoned: {
			login: string
			passwordReset: string
			registration: string
		}
		input: {
			email: string
		}
		registered: string
		reset: {
			password: string
			receivedErrorResettingPassword: string
			requestToResendToken: string
			tokenSent: string
		}
		sent: {
			verificationEmail: string
		}
		signedIn: string
		signInFailed: string
		updated: {
			password: string
			profile: string
		}
	}
}

export const PRODUCTION = 'fpJWnH4V4Dpkx6UAFiIJHIQ7fvzucMdE'
export const DEV = '9uSqwmqwL9BDDwnRua2XV9VCuQ3tUGCY'

export const categories: Categories = {
	benefits: 'Benefits',
	discounts: 'Discounts',
	profile: 'Profile',
	registration: 'Registration',
}

export const events: Events = {
	benefit: {
		learnMoreClicked: 'Benefit Card - Learn More Clicked',
		viewAllClicked: 'Benefit Card - View All Clicked',
	},
	benefitEnrollment: {
		cancelled: 'Benefit Enrollment Cancelled',
		submission: {
			error: 'Benefit Enrollment Submission Failed in Error',
			success: 'Benefit Enrollment Submission Success',
		},
		trustmark: {
			enrollmentDataFetched: 'Fetched Trustmark Enrollment Data',
		},
	},
	benefitsWizard: {
		addedPet: 'Wizard - User Added Pet',
		cancelModal: {
			cancel: 'Wizard Cancellation Modal - Cancel Clicked',
			close: 'Wizard Cancellation Modal - Modal Closed',
			nevermind: 'Wizard Cancellation Modal - Nevermind Clicked',
			next: 'Wizard Cancellation Modal - Next Clicked',
			opened: 'Wizard Cancellation Modal - Opened',
		},
		getStartedClicked: 'Wizard Launch - Get Started Clicked',
		navigateAwayPopup: {
			continueClicked: 'Wizard - Navigate Away Modal Continue Clicked',
			leaveClicked: 'Wizard - Navigate Away Modal Leave Clicked',
		},
		planTypeClicked: `Wizard Launch - Clicked`,
		removedPet: 'Wizard - User Removed Pet',
		saveAndContinue: 'Wizard - Save and Continue Later Clicked',
		steps: {
			confirmation: {
				exploreMoreBenefitsClicked: 'Wizard Confirmation - Explore More Benefits Clicked',
			},
			stepOne: {
				back: `Wizard Step 1 - Go Back Clicked`,
				cancel: `Wizard Step 1 - Cancel Clicked`,
				continue: `Wizard Step 1 - Continue Clicked`,
				learnMoreClicked: 'Wizard Step 1 - Learn More Clicked',
				next: `Wizard Step 1 - Next Clicked`,
				undo: `Wizard Step 1 - Undo Cancellation Clicked`,
				waive: `Wizard Step 1 - Waived Clicked`,
			},
			stepThree: { back: 'Wizard Step 3 - Go Back Clicked', submit: 'Wizard Step 3 - Submit Clicked' },
			stepTwo: {
				back: 'Wizard Step 2 - Go Back Clicked',
				complete: 'Wizard Step 2 - Complete Clicked',
				dependentAdded: 'Wizard Step 2 - Dependent Added',
				dependentInfoModified: 'Wizard Step 2 - Dependent Info Modified',
				dependentRemoved: 'Wizard Step 2 - Dependent Removed',
				next: 'Wizard Step 2 - Next Clicked',
				personalInfoChanged: 'Wizard Step 2 - Personal Info Changed',
			},
		},
		trustmark: {
			closedTrustmarkModal: 'Trustmark - Trustmark Modal Closed',
			declinedTrustmarkCoverage: 'Trustmark - Declined Coverage',
			enrollmentCompleted: 'Trustmark - Enrollment is in Status Completed',
			enrollmentPendingCompletion: 'Trustmark - Enrollment is in Status Pending Completion',
			openedTrustmarkModal: 'Trustmark - Opened Trustmark Modal',
		},
	},
	category: {
		autoQuote: 'Comparison Tool Clicked',
		viewed: 'Category Viewed',
	},
	error: {
		login: 'An error occurred logging the user in',
		registration: 'An error occurred registering the user',
	},
	header: {
		helpClicked: 'Header - Help Clicked',
		homeClicked: 'Header - Home Clicked',
		myProfileClicked: 'Header - My Profile Clicked',
		orgLogoClicked: 'Header - Org Logo Clicked',
	},
	loginAttempt: `User has attempted to login`,
	logoutAttempt: 'User has attempted to logout',
	megaMenu: {
		benefits: {
			allAvailableBenefits: 'User selected All Available Benefits from the mega menu',
			benefitsHome: "User selected 'Benefits Home' from the mega menu",
			category: 'User selected a benefit category from the mega menu',
			enrolledBenefits: "User selected 'View Enrolled Benefits' from the mega menu",
			recommendations: "User selected 'Recommendations' from the mega menu",
			reviewDeductions: "User selected 'Review Deductions' from the mega menu",
		},
		deals: {
			category: 'User selected a deal category from the mega menu',
			dealsHome: "User has selected 'Deals Home' from the mega menu",
		},
	},
	product: {
		clicked: 'Offer Clicked',
		learnMoreClicked: 'Offer Learn More Clicked',
		list: { filtered: 'Offer List Filtered', viewed: 'Offer List Viewed' },
		searched: 'Offer Searched',
		viewed: 'Offer Viewed',
	},
	productPage: {
		abandoned: 'Product Page Abandoned',
		enrollment: {
			cancelledCoverage: 'Product Page - Cancelled Coverage',
			enrollNowClicked: 'Product Page - Enroll Now Clicked',
			modifiedCoverage: 'Product Page - Modified Coverage',
		},
		fullRateTable: {
			interactionAttempted: 'Full Rate Table Interaction Attempted',
			linkClicked: 'Full Rate Table Link Clicked',
		},
	},
	qleAction: 'QLE Action',
	registration: {
		clicked: {
			benefitIcon: 'User clicked benefit icon on registration page',
			providerLogo: 'User clicked provider logo on registration page',
		},
		emailVerified: {
			match: 'User provided email matches a PPDS worker record',
			noMatch: 'User provided email matches no PPDS worker records',
		},
	},
	standard: {
		learnMore: 'Standard - Learn More Clicked',
		startEnrollmentOrMakeChanges: 'Standard - Start/Make Changes',
	},
	topTileClicked: 'Top Tile Clicked',
	unauthHome: {
		clicked: {
			benefitIcon: 'User clicked benefit icon on unauth home page',
			providerLogo: 'User clicked provider logo on unauth home page',
		},
	},
	user: {
		abandoned: {
			login: 'User Abandoned Login',
			passwordReset: 'User Abandoned Password Reset',
			registration: 'User Abandoned Registration',
		},
		input: { email: 'User Input Email' },
		registered: 'User Registered',
		reset: {
			password: 'User Reset Password',
			receivedErrorResettingPassword: 'User received an error resetting password',
			requestToResendToken: 'User requested to resend the verification token',
			tokenSent: 'User requested the verification token to be sent',
		},
		sent: { verificationEmail: 'User Sent Verification Email' },
		signedIn: 'User Signed In',
		signInFailed: 'User Login Failed',
		updated: { password: 'User Updated Password', profile: 'User Updated Profile' },
	},
}
