import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getCurrentSubdomain } from 'services/util/subdomain-parser'
import { selectIsOE } from 'store/benefit-plans/selectors'
import { SilentRenewSelector, silentRenewSelector } from 'store/combined-selectors'
import { siteInfoSelector } from 'store/site/selectors'
import { isLoggedInSelector, myProfileSelector, MyProfileUser } from 'store/user/selectors'
import { SiteInfoResponse } from 'types/tenant-site-types'

export function useIntercom() {
	const { und3484 } = useFlags()
	const isLoggedIn = useSelector(isLoggedInSelector)
	const userProfile: MyProfileUser = useSelector(myProfileSelector)
	const { siteInfo }: SilentRenewSelector = useSelector(silentRenewSelector)
	const isOE = useSelector(selectIsOE)
	const { properties }: SiteInfoResponse = useSelector(siteInfoSelector)
	const hasBenefits = properties.hasVoluntaryBenefits
	/**
	 * Effect to start intercom widget
	 * If logged in, updates with user data.
	 */
	useEffect(() => {
		if (isLoggedIn && typeof window['Intercom'] === 'function') {
			const subdomain = getCurrentSubdomain().split('.')[0]
			const intercomUserInfo = {
				...window['intercomSettings'],
				admin_link:
					und3484 && userProfile.constituentId
						? `https://admin.corestream.com/constituent-search/tenant/${subdomain}/profile/${userProfile.constituentId}/summary`
						: '',
				// company is the only object type allowed to be passed
				company: { company_id: siteInfo.organizationId, name: siteInfo.organizationName },
				company_site: `${subdomain}.corestream.com`,
				email: userProfile.email,
				employee_id: userProfile.employeeId,
				is_oe: !hasBenefits ? false : isOE,
				name: `${userProfile.firstName} ${userProfile.lastName}`,
				org_unit_id: siteInfo.organizationUnitId,
				user_id: userProfile.userId,
				worker_id: userProfile.workerId,
			}
			window['intercomSettings'] = intercomUserInfo
			window.Intercom('update', intercomUserInfo)
			if (window.Upscope && typeof window.Upscope === 'function') {
				window.Upscope('updateConnection', {
					// Identities property takes an array of items, we want to use the user name and the user's GUID.
					identities: [`${userProfile.firstName} ${userProfile.lastName}`, userProfile.userId],
					// Set the user ID below. If you don't have one, set to undefined.
					uniqueId: userProfile.userId,
				})
			}
		}
	}, [
		hasBenefits,
		isLoggedIn,
		isOE,
		siteInfo.organizationId,
		siteInfo.organizationName,
		siteInfo.organizationUnitId,
		und3484,
		userProfile,
	])
}
