import { PrimaryDriverDTO } from 'pages/autoquotes/model/DriverTypes'
import { QuotingSessionDTO } from 'pages/autoquotes/model/QuoteSessionTypes'

const emptyQuoteSession: QuotingSessionDTO = {
	agencyId: 0,
	coverage: {
		boatYachInsurance: null,
		boatYachPolicy: null,
		condoInsurance: null,
		condoPolicy: null,
		continuousInsuranceDuration: null,
		currentExpirationDate: null,
		currentInsuranceStatus: null,
		currentLiabilityCoverage: null,
		currentlyHasInsurance: false,
		currentProvider: null,
		desiredCoverage: '',
		desiredDuration: 12,
		desiredDurationUnit: 'M',
		desiredStartDate: '',
		fields: [],
		hadAutoInsuranceForPast6Months: true,
		hasOtherInsurances: false,
		homeownersInsurance: null,
		homeownersPolicy: null,
		id: '',
		personalArticlesInsurance: null,
		personalArticlesPolicy: null,
		quotingSession_Id: '',
		rentalPropertyInsurance: null,
		rentalPropertyPolicy: null,
		rentersInsurance: null,
		rentersPolicy: null,
		umbrelaInsurance: null,
		umbrelaPolicy: null,
	},
	createdOn: '',
	drivers: [],
	employeeUniqueId: '',
	employerAccountCode: '',
	id: '',
	lastSubmittedOn: null,
	vehicles: [],
}

export const emptyPrimaryDriver: PrimaryDriverDTO = {
	$type: 'Corestream.Service.Autoquoting.Model.DTOs.PrimaryDriverDTO, Corestream.Service.Autoquoting',
	accidentsLast5Years: true,
	age: 0,
	ageFistLicence: 0,
	canUseSSNToCredit: false,
	childrenLivingWith: false,
	currentLicenceStatus: null,
	dateOfBirth: null,
	discriminator: 'PrimaryDriver',
	driverLicenseState: null,
	education: null,
	email: null,
	fields: [],
	firstName: null,
	gender: null,
	graduatedInTheLastYear: false,
	hasAdditionalDriver: null,
	hasApprovedAcidentPrevention: false,
	hasDriverTrainingCourse: false,
	id: '',
	incidents: [],
	isResidenceInsured: true,
	lastName: null,
	mailAddress: {
		city: null,
		county: null,
		fullAddress: null,
		id: '',
		isPOBOX: false,
		line1: null,
		line2: null,
		state: null,
		stateFromZip: null,
		zip: null,
	},
	mailDifferentAsPrimary: false,
	maritalStatus: null,
	middleName: null,
	monthsOnPreviousAddress: 0,
	movedInLast6Months: false,
	needFinantionResponsibilityCertification: false,
	numberId: 0,
	occupation: null,
	previousAddress: {
		city: null,
		county: null,
		fullAddress: null,
		id: '',
		isPOBOX: false,
		line1: null,
		line2: null,
		state: null,
		stateFromZip: null,
		zip: null,
	},
	primaryAddress: {
		city: null,
		county: null,
		fullAddress: null,
		id: '',
		isPOBOX: false,
		line1: null,
		line2: null,
		state: null,
		stateFromZip: null,
		zip: null,
	},
	primaryPhone: null,
	primaryResidence: null,
	quotingSession_Id: '',
	residenceOwnedRented: null,
	ssn: null,
	suffix: null,
	yearsEmployed: 0,
	yearsOnPreviousAddress: 0,
} as PrimaryDriverDTO

export type QuoteState = {
	hasPreviousData: boolean
	isLoading: boolean
	isQuoteLoading: boolean
	isQuotingAvailable: boolean
	quote: QuotingSessionDTO
	quoteError: Error | null
}

export const initialState: QuoteState = {
	hasPreviousData: false,
	isLoading: false,
	isQuoteLoading: true,
	isQuotingAvailable: false,
	quote: emptyQuoteSession,
	quoteError: null,
}
