import { useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query'
import { queryKeys, UserRecommendations, UserRecommendationService } from 'api/user-insights'
import { useIsDecisionSupportEnabledForClient } from 'hooks/user-data/use-is-decision-support-enabled-for-client'

/**
 * Gets recommendation by formSubmissionId and updates the latest 'get' query with the result
 * @param params
 * @returns
 */
export const usePolledGetUserRecommendation = (params: {
	formSubmissionId?: string
}): UseQueryResult<UserRecommendations, unknown> => {
	const isEnabled = useIsDecisionSupportEnabledForClient()
	const queryClient = useQueryClient()

	return useQuery({
		enabled: isEnabled,
		queryFn: () =>
			UserRecommendationService.getUserRecommendation(params.formSubmissionId).then((res) => {
				// Set the data for the GET key, which is what the rest of the app uses for recommendation data
				queryClient.setQueryData(queryKeys.recommendations.userRecommendation.getLatest(), res)

				return res
			}),
		queryKey: queryKeys.recommendations.userRecommendation.getById(params.formSubmissionId ?? ''),
	})
}

export const useGetUserRecommendation = (): UseQueryResult<UserRecommendations, unknown> => {
	const isEnabled = useIsDecisionSupportEnabledForClient()

	return useQuery({
		enabled: isEnabled,
		queryFn: () => UserRecommendationService.getUserRecommendation(),
		queryKey: queryKeys.recommendations.userRecommendation.getLatest(),
		retry: false,
	})
}
