import { useQuery } from '@tanstack/react-query'
import { queryKeys } from 'api/enrollments/keys'
import { UserNewHireInfo } from 'api/enrollments/types'
import { NewHireService } from 'api/tenants/services/new-hire-service'

const getCorestreamUserFakeResponse = (): UserNewHireInfo => {
	const response = {
		isNewHire: false,
		newHireQualificationEndDate: '2000-01-01T00:00:00',
		newHireQualificationStartDate: '2000-01-01T00:00:00',
	}

	return response
}

const getQueryFunction = (isEnabled: boolean) => {
	if (!isEnabled) {
		return getCorestreamUserFakeResponse
	}

	return NewHireService.UserNewHireInfo
}

export const useNewHire = (isEnabled: boolean) => {
	return useQuery<UserNewHireInfo>({
		queryFn: getQueryFunction(isEnabled),
		queryKey: queryKeys.benefitElections.userNewHireInfo(),
	})
}
