import { useEligiblePlansQuery } from 'api/query-hooks/use-benefit-plans'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectBenefitPlansFetchStatus, selectTemplateTokensFetchStatus } from 'store/benefit-plans/selectors'
import { getBenefitPlansAndEffectiveDates, getTemplateTokens } from 'store/benefit-plans/slice'
import { siteInfoSelector } from 'store/site/selectors'
import { isLoadingUserSelector, userSelector } from 'store/user/selectors'
import { IDLE, SUCCESS } from 'types/status-types'

import { useAppSelector } from './redux'
import { StateMachineStatus } from './use-async-fetch'
import { useCallbackDispatch } from './use-callback-dispatch'

export const useBenefitPlanInit = (isLoggedIn: boolean) => {
	const dispatch = useCallbackDispatch()
	const benefitPlansFetchStatus: StateMachineStatus = useSelector(selectBenefitPlansFetchStatus)
	const templateTokensFetchStatus: StateMachineStatus = useSelector(selectTemplateTokensFetchStatus)
	const isUserLoading = useSelector(isLoadingUserSelector)
	const siteInfo = useSelector(siteInfoSelector)
	const { hasVoluntaryBenefits } = siteInfo.properties
	const user = useSelector(userSelector)
	const sessionToken = useAppSelector((s) => s.oidc.user?.access_token)

	// condition to fetch benefit plans
	const isFetchDisabled =
		!isLoggedIn ||
		!user ||
		isUserLoading ||
		!hasVoluntaryBenefits ||
		benefitPlansFetchStatus !== IDLE ||
		templateTokensFetchStatus !== SUCCESS ||
		!sessionToken

	const { data, isFetched } = useEligiblePlansQuery(!isFetchDisabled, siteInfo.organizationId)

	// Plan Info + Effective Date Fetch when Bennies as Data Source is enabled
	useEffect(() => {
		if (!isFetched || !data) {
			return
		}
		dispatch(getBenefitPlansAndEffectiveDates(data))
	}, [data, dispatch, isFetched])

	useEffect(() => {
		if (templateTokensFetchStatus === IDLE && hasVoluntaryBenefits) {
			dispatch(getTemplateTokens())
		}
	}, [dispatch, hasVoluntaryBenefits, templateTokensFetchStatus])
}
