import { Loading } from 'components/loading'
import { useReturnUrl } from 'hooks/use-return-url'
import { useSilentLogin } from 'hooks/use-silent-login'
import { useEffectOnceWithRef } from 'hooks/utils'
import { useRef } from 'react'
import { store } from 'store/store'
import { LocalStore } from 'utils/utils'

/**
 * This component is solely for handling redirects SAML Requests
 */
const Saml = () => {
	const hasRedirected = useRef(false)

	const silentLogin = useSilentLogin()

	const { handleReturnUrl } = useReturnUrl()

	const isLoggedIn = store.getState().user.isLoggedIn
	const user = store.getState().oidc.user

	const notLoggedInAndNoUser = !isLoggedIn && !user
	const loggedInAndHasUser = isLoggedIn && !!user

	useEffectOnceWithRef(true, () => {
		setupUrlStorage()
	})

	useEffectOnceWithRef(
		// This condition checks if the user has not been redirected yet and either:
		// a) The user is not logged in and there is no user data, or
		// b) The user is logged in and there is user data.
		!hasRedirected.current &&
			((notLoggedInAndNoUser && !loggedInAndHasUser) || (!notLoggedInAndNoUser && loggedInAndHasUser)),
		() => {
			if (notLoggedInAndNoUser) {
				hasRedirected.current = true

				// does an implicit navigate() call, must be in a useEffect().
				// OR we need to refactor to return a <Navigate /> component
				silentLogin()
			} else if (loggedInAndHasUser) {
				hasRedirected.current = true

				// does an implicit navigate() call, must be in a useEffect().
				// OR we need to refactor to return a <Navigate /> component
				handleReturnUrl(user)
			}
		},
	)

	return (
		<div
			// doing this on purpose to force us to take a longer look @ the loading experience
			style={{
				alignItems: 'center',
				display: 'flex',
				height: 'calc(100vh - var(--header-height))',
				justifyContent: 'center',
			}}
		>
			<Loading />
		</div>
	)
}

export default Saml

/**
 * if present, sets the url data to local storage for redirection post login
 *
 * the check for presence of data is needed because SAML gets rendered multiple times from
 *  above which will reset URL params implicitly via handleReturnUrl() / silentLogin()
 */
function setupUrlStorage() {
	const urlParams = new URLSearchParams(window.location.search)

	const returnUrlParams: null | string = urlParams.get('returnUrl')
	const searchQuery: null | string = urlParams.get('query')
	const isImpersonation: null | string = urlParams.get('Impersonation')
	const usedEmailClaimForRegistration: null | string = urlParams.get('UsedEmailClaimForRegistration')

	if (returnUrlParams) {
		LocalStore.set('returnUrl', returnUrlParams)
	}

	if (isImpersonation) {
		LocalStore.set('isImpersonation', isImpersonation)
	}

	if (searchQuery) {
		LocalStore.set('query', searchQuery)
	}

	if (usedEmailClaimForRegistration) {
		LocalStore.set('usedEmailClaimForRegistration', usedEmailClaimForRegistration)
	}
}
