import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SiteInfoResponse } from 'types/tenant-site-types'
import { toTitleCase } from 'utils/str/toTitleCase'

import { initialState, SiteState } from './types'

const mergeProperties = (siteInfo: SiteInfoResponse): SiteInfoResponse => ({
	...siteInfo,
	properties: siteInfo.properties,
})

const applySiteInfoFormatting = (siteInfo: SiteInfoResponse): SiteInfoResponse => {
	// Ensure the Employee Descriptor is Title Cased
	siteInfo.properties.employeeDescriptor = toTitleCase(siteInfo.properties.employeeDescriptor)

	return siteInfo
}

export const siteSlice = createSlice({
	initialState,
	name: 'site',
	reducers: {
		getSiteInfoError: (state: SiteState, action: PayloadAction<Error>): void => {
			state.siteError = action.payload
			state.isSiteLoading = false
		},
		getSiteInfoSuccess: (state: SiteState, action: PayloadAction<SiteInfoResponse>): void => {
			state.siteError = null
			state.isSiteLoading = false
			state.siteInfo = applySiteInfoFormatting(mergeProperties(action.payload))
		},
	},
})

// Extract the action creators object and the reducer
const { actions, reducer } = siteSlice
// Export the reducer and actions
export { actions, reducer as site }
