import { MQ_LG } from 'types/breakpoints'

import useSize from './use-size'

export const useMobileDisplay = (): boolean => {
	const size = useSize()
	const mobile = size < MQ_LG

	return mobile
}
