import { cx } from 'class-variance-authority'
import { HTMLAttributes, useCallback, useEffect, useRef } from 'react'

import styles from './wysiwig-content.module.css'

export type WysiwigContentProps = {
	content: string
	marginReset?: boolean
	marginTopReset?: boolean
	onLinkClick?: (url: string) => void
} & Omit<HTMLAttributes<HTMLDivElement>, 'dangerouslySetInnerHTML'>

export const WysiwigContent = ({
	content,
	marginReset = true,
	marginTopReset = false,
	onLinkClick,
	...props
}: WysiwigContentProps) => {
	const contentRef = useRef<HTMLDivElement>(null)

	const handleClick = useCallback(
		(e) => {
			if (e.target.tagName === 'A') {
				const href = e.target.getAttribute('href')

				if (onLinkClick) onLinkClick(href)

				// Open the link in a new tab
				const win = window.open(href, '_blank')
				win?.focus()
				e.preventDefault()
			}
		},
		[onLinkClick],
	)

	useEffect(() => {
		const contentEl = contentRef.current

		if (onLinkClick && contentEl) {
			contentEl.addEventListener('click', handleClick)
		}

		return () => {
			if (contentEl) contentEl.removeEventListener('click', handleClick)
		}
	}, [handleClick, onLinkClick])

	return (
		<div
			{...props}
			ref={contentRef}
			className={cx({ [styles['container']]: marginTopReset, [styles['margin-reset']]: marginReset }, props.className)}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	)
}
