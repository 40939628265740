import { Snackbar as MuiSnackbar, Slide, SlideProps, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useWindowSize } from 'hooks/ui/use-window-size/use-window-size'
import { FC, SyntheticEvent } from 'react'
import { ERROR, INFO, SUCCESS, WARNING } from 'types/status-types'

import { Alert } from './alert'
import styles from './snackbar.module.css'

export type AlertStatus = typeof ERROR | typeof INFO | typeof SUCCESS | typeof WARNING

interface SnackBarProps {
	anchorOrigin?: any
	message: string
	onClose?: any
	onExited?: any
	open: boolean
	shouldAutoHide?: boolean
	status?: AlertStatus
}

const Snackbar: FC<SnackBarProps> = (props: SnackBarProps) => {
	const autoHide = props.shouldAutoHide ? 5000 : null // 5s in ms

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))
	const { width } = useWindowSize()
	const alertWidth = isMobile ? width : width / 3

	const handleClose = (_?: Event | SyntheticEvent, reason?: string): null | void => {
		if (reason === 'clickaway') return null
		if (props.onClose) props.onClose()
	}

	function SlideTransition(props: SlideProps) {
		return <Slide {...props} direction='down' />
	}

	return (
		<MuiSnackbar
			anchorOrigin={props.anchorOrigin}
			autoHideDuration={autoHide}
			className={styles['snackbar']}
			open={props.open}
			TransitionComponent={SlideTransition}
			TransitionProps={{ onExited: props.onExited }}
			ClickAwayListenerProps={{
				mouseEvent: 'onMouseUp',
				onClickAway: props.open ? handleClose : undefined,
			}}
			sx={{
				'& .MuiPaper-root': {
					borderRadius: '0 0 var(--space-l) var(--space-l)',
				},
				top: '0 !important', // anchor to top of screen
			}}
			onClose={handleClose}
		>
			<div>
				<Alert
					icon={false}
					severity={props.status}
					sx={{
						'& .MuiAlert-message': {
							textAlign: 'center',
							textWrap: 'wrap',
							width: '100%',
						},
						minHeight: '54px',
						minWidth: alertWidth,
					}}
					onClose={handleClose}
				>
					<Typography component='p' variant='h5-body'>
						{props.message}
					</Typography>
				</Alert>
			</div>
		</MuiSnackbar>
	)
}

Snackbar.defaultProps = {
	anchorOrigin: { horizontal: 'center', vertical: 'top' },
	shouldAutoHide: true,
}

export default Snackbar
