import { Close } from '@mui/icons-material'
import { Button, Card, CardContent, CardHeader, CardMedia, IconButton, Modal } from '@mui/material'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useOfferDetailsByIdQueryOptions } from 'api/search/queryOptions/offers'
import { WysiwigContent } from 'components/wysiwig-content/wysiwig-content'
import { AlgoliaEventType, useTrackOffer } from 'hooks/analytics'
import { ReactElement, Suspense, useCallback, useEffect, useMemo, useRef } from 'react'
import { match } from 'ts-pattern'

import { useOfferCode, useOfferRedeemHandler } from '../hooks'
import { OfferCodeButton } from './components'
import { OfferModalSkeleton } from './components/offer-modal-skeleton'
import styles from './offer-modal.module.css'
import { OfferModalProps } from './types'

type OfferCardContentProps = {
	actionButton: ReactElement
	offerObjectID: string
	trackSearchOfferClicked?: (action: AlgoliaEventType) => void
}

export default OfferModal

export function OfferModal(props: OfferModalProps) {
	const { hideModal, offerObjectID, showModal, trackSearchOfferClicked } = props

	const actionButton = useMemo(
		() => (
			<IconButton aria-label='close modal' className={styles['button']} size='large' onClick={hideModal}>
				<Close className={styles['icon']} />
			</IconButton>
		),
		[hideModal],
	)

	if (!showModal) return null

	return (
		<Modal className={styles['container']} disableAutoFocus disableEnforceFocus open={showModal} onClose={hideModal}>
			<Card square={true}>
				<Suspense fallback={<OfferModalSkeleton />}>
					<OfferCardContent
						actionButton={actionButton}
						offerObjectID={offerObjectID}
						trackSearchOfferClicked={trackSearchOfferClicked}
					/>
				</Suspense>
			</Card>
		</Modal>
	)
}

function OfferCardContent({ actionButton, offerObjectID, trackSearchOfferClicked }: OfferCardContentProps) {
	const { data: offer } = useSuspenseQuery(useOfferDetailsByIdQueryOptions(offerObjectID))
	const track = useTrackOffer()
	const offerCode = useOfferCode(offer)
	const onOfferRedeem = useOfferRedeemHandler(offer, 'Offer Modal')
	const contentRef = useRef<HTMLDivElement>(null)

	const onCtaClick = useCallback(
		(e) => {
			if (trackSearchOfferClicked) trackSearchOfferClicked('conversion')
			onOfferRedeem(e)
		},
		[onOfferRedeem, trackSearchOfferClicked],
	)

	useEffect(() => {
		function modalLinkListener(e: MouseEvent) {
			const target = e.target as HTMLAnchorElement
			const url = target.getAttribute('href')
			if (url) {
				const win = window.open(url, '_blank')
				win?.focus()
				e.preventDefault()
			}
		}

		const modalLinks = contentRef.current?.querySelectorAll('.js-modal-content a:not([href^=mailto])') ?? []
		modalLinks.forEach((link) => {
			link.addEventListener('click', modalLinkListener)
		})

		return () => {
			modalLinks.forEach((link) => {
				link.removeEventListener('click', modalLinkListener)
			})
		}
	}, [])

	return (
		<div ref={contentRef}>
			<CardHeader
				action={actionButton}
				avatar={<CardMedia className={styles['logo']} image={offer.vendor.logo} />}
				className={styles['header']}
				classes={{
					action: styles['action'],
					avatar: styles['avatar'],
				}}
			/>
			<CardContent className={styles['card']}>
				<div className={styles['content']}>
					<div className={styles['main']}>
						<div className={styles['top-content']}>
							<p>{offer.category}</p>
							<h2 className={styles['title']}>{offer.title}</h2>
							<p className={styles['body']}>{offer?.description ?? ''}</p>
							{match({ hasCode: !!offerCode })
								.when(
									({ hasCode }) => hasCode === false,
									() => (
										<Button className={styles['content-button']} onClick={onCtaClick}>
											{offer.cta}
										</Button>
									),
								)
								.when(
									({ hasCode }) => hasCode === true,
									() => (
										<OfferCodeButton
											className={styles['content-button']}
											offer={offer}
											trackSearchOfferClicked={trackSearchOfferClicked}
										/>
									),
								)
								.otherwise(() => (
									<Button className={styles['content-button']} onClick={onCtaClick}>
										{offer.cta}
									</Button>
								))}
						</div>

						<WysiwigContent
							className='offer-modal-bottom-content js-modal-content'
							content={offer.terms}
							onLinkClick={(url) => {
								track({
									actionType: 'click',
									discountOfferId: offer.objectID,
									elementDetails: url,
									elementName: 'offer terms',
									elementType: 'hyperlink',
									offerBrand: offer.vendor.name,
									offerName: offer.title,
									offerType: 'discounts',
									pageName: '',
								})
							}}
						/>
						<WysiwigContent
							className='offer-modal-bottom-content js-modal-content'
							content={offer.vendor?.description ?? ''}
							onLinkClick={(url) => {
								track({
									actionType: 'click',
									discountOfferId: offer.objectID,
									elementDetails: url,
									elementName: 'offer description',
									elementType: 'hyperlink',
									offerBrand: offer.vendor.name,
									offerName: offer.title,
									offerType: 'discounts',
									pageName: '',
								})
							}}
						/>
					</div>
				</div>
			</CardContent>
		</div>
	)
}
