// Returns a dates time in ms
export const timeInMs = (date: Date): number => date.getTime()
/**
 * Formats birthdates in yyyy-mm-dd
 * @param birthDate birthdate to format
 */
export function formatDate(birthDate: Date | string): string {
	if (!birthDate) return ''

	const bday = new Date(birthDate)

	const year = bday.getUTCFullYear()
	let month = (bday.getUTCMonth() + 1).toString()
	let date = bday.getUTCDate().toString()
	if (month.length < 2) month = `0${month}`
	if (date.length < 2) date = `0${date}`

	return `${year}-${month}-${date}`
}

/**
 * Formats dates in mm/dd/yyyy
 * @param date date to format
 */
export function formatDateMonthDayYear(date: Date | string): string {
	if (typeof date === 'string') date = new Date(date)

	const year = date.getUTCFullYear()
	const month = (date.getUTCMonth() + 1).toString()
	const day = date.getUTCDate().toString()

	return `${month}/${day}/${year}`
}

/**
 * Checks child age against max age and today's date
 */
export function isChildAgeValid(birthdate: string, planEffectiveDate: string): boolean {
	const birthDate = new Date(birthdate)
	const timeInMs = (date: Date): number => date.getTime()
	const isUnderMaxAge = isYoungerThanTwentySix(birthDate, planEffectiveDate)
	const hasBeenBorn: boolean = timeInMs(birthDate) <= timeInMs(new Date())

	return isUnderMaxAge && hasBeenBorn
}

function getDateWithOffset(date: Date | string, offset: number): Date {
	const dateWithOffset = new Date(date)
	dateWithOffset.setUTCFullYear(dateWithOffset.getUTCFullYear() + offset)

	return dateWithOffset
}

/**
 *
 * @param atLeastAge - age to compare against
 * @param birthdate - birthdate to compare
 * @param planEffectiveDate - effective date to check against
 * @returns whether a birthdate is greater than or equal to a certain age
 */
export const isAtLeast = (atLeastAge: number) => (birthdate: Date | string, planEffectiveDate: Date | string) => {
	const effectiveDate = new Date(planEffectiveDate)
	const birthDate = new Date(birthdate)
	const birthDateWithOffset = getDateWithOffset(birthDate, atLeastAge)

	return birthDateWithOffset.getTime() <= effectiveDate.getTime()
}

/**
 * Checks person is older than defined legalAge
 */
export const isOfAge = isAtLeast(18)

/**
 *
 * @param youngerThanAge - age to compare against
 * @param birthdate - birthdate to compare
 * @param planEffectiveDate - effective date to check against
 * @returns whether a birthdate is less than a certain age
 */
export const isYoungerThan =
	(youngerThanAge: number) =>
	(birthdate: Date | string, planEffectiveDate: Date | string): boolean => {
		const effectiveDate = new Date(planEffectiveDate)
		const birthDate = new Date(birthdate)
		const birthDateWithOffset = getDateWithOffset(birthDate, youngerThanAge)

		return timeInMs(birthDateWithOffset) > timeInMs(effectiveDate)
	}

/**
 * Check if the user is not older than a given age
 * @param olderThanAge Age to compare against
 * @returns Whether the user is older than the given age
 */
export const isOlderThan =
	(olderThanAge: number) =>
	(birthdate: Date | string, planEffectiveDate: Date | string): boolean => {
		const effectiveDate = new Date(planEffectiveDate)
		const birthDate = new Date(birthdate)

		// Gets the user's age compared to the effective date
		const userAge = getAge(timeInMs(birthDate), timeInMs(effectiveDate))

		// Returns if the user's age is less than or at the max age
		return userAge > olderThanAge
	}

export const isYoungerThanTwentySix = isYoungerThan(26)

/**
 * Get the age of the user compared to an endate
 * @param dobMs DoB of the user
 * @param endDateMs End date to compare it to
 * @returns Age of the user compared to the given endate
 */
export function getAge(dobMs, endDateMs) {
	// Convert milliseconds to Date objects
	const dob = new Date(dobMs)
	const endDate = new Date(endDateMs)

	// Calculate the difference in years
	let age = endDate.getFullYear() - dob.getFullYear()

	// Adjust the age if the end date has not yet reached the birthday in the current year
	if (
		endDate.getMonth() < dob.getMonth() ||
		(endDate.getMonth() === dob.getMonth() && endDate.getDate() < dob.getDate())
	) {
		age--
	}

	return age
}

export const subtractMonthsFromDate = (date: Date, months: number): Date => {
	const newDate = new Date(date)
	newDate.setMonth(newDate.getMonth() - months)

	return newDate
}

export const areDatesEqual = (date1?: Date, date2?: Date) => {
	if (!date1 && !date2) return true
	if (!date1 || !date2) return false

	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDay() === date2.getDay()
	)
}
