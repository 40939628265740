import { linearProgressClasses } from '@mui/material/LinearProgress'
import { alpha, CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

const COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'] as const

// ----------------------------------------------------------------------

export function progress(): CssVarsThemeOptions['components'] {
	return {
		MuiLinearProgress: {
			styleOverrides: {
				root: ({ ownerState, theme }) => {
					const bufferVariant = ownerState.variant === 'buffer'

					const defaultStyle = {
						[`& .${linearProgressClasses.bar}`]: {
							borderRadius: 4,
						},
						borderRadius: 4,
						...(bufferVariant && {
							backgroundColor: 'transparent',
						}),
					}

					const colorStyle = COLORS.map((color) => ({
						...(ownerState.color === color && {
							backgroundColor: alpha(theme.palette[color].main, 0.24),
						}),
					}))

					return [defaultStyle, ...colorStyle]
				},
			},
		},
	}
}
