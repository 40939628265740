import { _useCaptureEvent, CaptureEventDetails, RequiredTypes } from './useCaptureEvent'

export interface EventOptions extends RequiredTypes {
	benefitPlanId?: number
	discountOfferId?: string
	elementDetails?: string
	enrollmentType?: CaptureEventDetails['enrollmentType']
	navigationMethod?: 'new tab' | 'redirect' | 'refresh'
	offerBrand?: string
	offerName?: string
	offerType?: 'benefits' | 'discounts'
}
/**
 * Tracking the events user takes on the wizard pages
 */
export const useTrackDetailedPageEvent = () => {
	const capture = _useCaptureEvent()

	return (eventData: EventOptions) => capture(eventData)
}
