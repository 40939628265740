import { avatarGroupClasses } from '@mui/material/AvatarGroup'
import { alpha, CssVarsThemeOptions } from '@mui/material/styles'

// ----------------------------------------------------------------------

const COLORS = ['default', 'primary', 'secondary', 'info', 'success', 'warning', 'error'] as const

const colorByName = (name: string) => {
	const charAt = name.charAt(0).toLowerCase()

	if (['a', 'c', 'f'].includes(charAt)) return 'primary'
	if (['d', 'e', 'h'].includes(charAt)) return 'secondary'
	if (['i', 'k', 'l'].includes(charAt)) return 'info'
	if (['m', 'n', 'p'].includes(charAt)) return 'success'
	if (['q', 's', 't'].includes(charAt)) return 'warning'
	if (['v', 'x', 'y'].includes(charAt)) return 'error'

	return 'default'
}

// NEW VARIANT
declare module '@mui/material/AvatarGroup' {
	interface AvatarGroupPropsVariantOverrides {
		compact: true
	}
}

// ----------------------------------------------------------------------

export function avatar(): CssVarsThemeOptions['components'] {
	return {
		MuiAvatar: {
			styleOverrides: {
				colorDefault: ({ ownerState, theme }) => {
					const color = colorByName(`${ownerState.alt}`)

					return {
						...(!!ownerState.alt && {
							...(color !== 'default'
								? {
										backgroundColor: theme.palette[color].main,
										color: theme.palette[color].contrastText,
								  }
								: {
										backgroundColor: alpha(theme.palette.grey[500], 0.24),
										color: theme.palette.text.secondary,
								  }),
						}),
					}
				},
				rounded: ({ theme }) => ({
					borderRadius: theme.shape.borderRadius * 1.5,
				}),
			},

			variants: COLORS.map((color) =>
				color === 'default'
					? {
							props: { color: 'default' },
							style: ({ theme }) => ({
								backgroundColor: alpha(theme.palette.grey[500], 0.24),
								color: theme.palette.text.secondary,
							}),
					  }
					: {
							props: { color },
							style: ({ theme }) => ({
								backgroundColor: theme.palette[color].main,
								color: theme.palette[color].contrastText,
							}),
					  },
			),
		},
		MuiAvatarGroup: {
			styleOverrides: {
				avatar: ({ theme }) => ({
					'&:first-of-type': {
						backgroundColor: theme.vars.palette.primary.light,
						color: theme.palette.primary.dark,
						fontSize: 12,
					},
					fontSize: 16,
					fontWeight: theme.typography.fontWeightSemiBold,
				}),
				root: ({ ownerState }) => ({
					justifyContent: 'flex-end',
					...(ownerState.variant === 'compact' && {
						[`& .${avatarGroupClasses.avatar}`]: {
							'&:first-of-type': {
								bottom: 0,
								left: 0,
								zIndex: 9,
							},
							'&:last-of-type': {
								right: 0,
								top: 0,
							},
							height: 28,
							margin: 0,
							position: 'absolute',
							width: 28,
						},
						height: 40,
						position: 'relative',
						width: 40,
					}),
				}),
			},
		},
	}
}
