import { SearchFilters } from 'api/search/types/search'

import { DEFAULT_FEATURE_LEVELS, EXCLUSIVE_PARTNERSHIPS } from '../types/feature-level'
import { BENEFIT_PLAN_FILTERS, not, OFFER_FILTERS } from './filter-constants'

export const searchPresets = {
	activeStatus: (): SearchFilters => ({
		[not(OFFER_FILTERS.STATUS)]: '0',
	}),

	blacklistVendorDiscounterId: (discounterId: string): SearchFilters => ({
		[not(OFFER_FILTERS.VENDOR_DISCOUNTER_ID)]: discounterId,
	}),

	byCategory: (category: string): SearchFilters => ({
		[OFFER_FILTERS.CATEGORY]: category,
	}),

	byProvider: (providerId: string): SearchFilters => ({
		[OFFER_FILTERS.PROVIDER]: providerId,
	}),

	byTags: (tags: string[]): SearchFilters => ({
		[OFFER_FILTERS.TAGS]: tags,
	}),

	cvsNonNativePet: (): SearchFilters => ({
		// this is case-insensitive
		[not(OFFER_FILTERS.VENDOR_NAME)]: 'nationwide pet health insurance',
	}),

	defaultFeatureLevels: (): SearchFilters => ({
		[OFFER_FILTERS.LEVEL]: DEFAULT_FEATURE_LEVELS,
	}),

	exclusivePartnerships: (): SearchFilters => ({
		[OFFER_FILTERS.LEVEL]: EXCLUSIVE_PARTNERSHIPS,
	}),

	featured: (): SearchFilters => ({
		featured: true,
	}),

	memberExclusive: (isMemberExclusive: boolean): SearchFilters => ({
		[OFFER_FILTERS.MEMBER_EXCLUSIVE]: isMemberExclusive,
	}),

	orgAccess: (orgId: string): SearchFilters => ({
		[BENEFIT_PLAN_FILTERS.ORGANIZATION_ID]: orgId,
	}),

	specificEndDate: (endDate: string): SearchFilters => ({
		[OFFER_FILTERS.END_DATE]: endDate,
	}),

	specificStartDate: (startDate: string): SearchFilters => ({
		[OFFER_FILTERS.START_DATE]: startDate,
	}),

	tenantAccess: (orgId: string, inclusiveTenants: string = 'ALL'): SearchFilters => ({
		[not(OFFER_FILTERS.BLACKLISTED_TENANT_IDS)]: orgId,
		[OFFER_FILTERS.TENANT_IDS]: [orgId, inclusiveTenants],
	}),
} as const
