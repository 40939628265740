import { axiosInstance } from 'config/axios'

import UrlBuilder from './util/url-builder'

const urlBuilder = new UrlBuilder('benefitelections')

export class ContinuationsService {
	static deleteContinuation(eventType: string) {
		const url = urlBuilder
			.setEndpoint('BenefitElections/Continuation')
			.setQueryParams(new URLSearchParams({ eventType }))
			.url()

		return axiosInstance.delete(url)
	}

	static deleteUserContinuations(): Promise<void> {
		const url = urlBuilder.setEndpoint('BenefitElections/UserContinuations').url()

		return axiosInstance.delete(url)
	}

	static async getContinuation<T>(eventType: string): Promise<null | T> {
		const url = urlBuilder
			.setEndpoint('BenefitElections/Continuation')
			.setQueryParams(new URLSearchParams({ eventType }))
			.url()

		return axiosInstance.get(url)
	}

	static saveContinuation<T>(state: T, eventType: string) {
		const url = urlBuilder.setEndpoint('BenefitElections/Continuation').url()

		return axiosInstance.post(url, { continuation: JSON.stringify(state), eventType })
	}
}
