import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { allLatestUserEnrollmentsSelector, hasCompletedOEOverviewSelector } from 'store/enrollments/selectors'

import { useEnrollmentData, useNewHirePlans } from './user-data'

export const useRecentOECompletion = (): boolean => {
	const hasCompletedOES = useSelector(hasCompletedOEOverviewSelector)
	const allLatestUserEnrollments = useSelector(allLatestUserEnrollmentsSelector)
	const { enrollmentEndDate, enrollmentStartDate } = useEnrollmentData()

	return useMemo((): boolean => {
		const eWindowStartDate = new Date(enrollmentStartDate.length ? enrollmentStartDate : 0)
		const eWindowEndDate = new Date(enrollmentEndDate.length ? enrollmentEndDate : 0)
		const enrollments = allLatestUserEnrollments
		const hasUserCompletedOE = Boolean(
			Object.keys(enrollments)
				.map(Number)
				.find((key) => {
					const submittedOn = new Date(enrollments[key].submittedOn)

					return submittedOn >= eWindowStartDate && submittedOn < eWindowEndDate
				}),
		)

		return hasUserCompletedOE || hasCompletedOES
	}, [allLatestUserEnrollments, enrollmentEndDate, enrollmentStartDate, hasCompletedOES])
}

export default useRecentOECompletion

export const useRecentNewHireCompletion = (): boolean => {
	const { newHireEndDate, newHireStartDate } = useNewHirePlans()
	const allLatestUserEnrollments = useSelector(allLatestUserEnrollmentsSelector)

	return useMemo(() => {
		const eWindowStartDate = new Date(newHireStartDate)
		const eWindowEndDate = new Date(newHireEndDate)
		const enrollments = allLatestUserEnrollments
		const hasUserCompletedOE = Boolean(
			Object.keys(enrollments)
				.map(Number)
				.find((key) => {
					const submittedOn = new Date(enrollments[key].submittedOn)

					return submittedOn >= eWindowStartDate && submittedOn < eWindowEndDate
				}),
		)

		return hasUserCompletedOE
	}, [allLatestUserEnrollments, newHireEndDate, newHireStartDate])
}
