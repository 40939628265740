import { useMemo } from 'react'
import { useIsPaychex } from 'utils/url'

/**
 * Retrieves the referral code for the selected Offer
 */
export const useOfferCode = (offer?: { code: null | string; paychexCouponCode?: null | string }): null | string => {
	const isPaychex = useIsPaychex()

	return useMemo(() => {
		if (!offer) {
			return null
		}

		let code = offer.code

		// only replace link when present && when subdomain is paychex
		if (isPaychex && offer.paychexCouponCode) {
			code = offer.paychexCouponCode
		}

		return code
	}, [isPaychex, offer])
}
