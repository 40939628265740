import { LTCEnrollmentResponse, WorkerAddressResponse } from 'api/benefit-elections'
import { axiosInstance as axios } from 'config/axios'
import UrlBuilder from 'services/util/url-builder'

import { API_SUBDOMAIN, endpoints } from '../constants'

const urlBuilder: UrlBuilder = new UrlBuilder(API_SUBDOMAIN)

export class TrustmarkSimplinkService {
	static getEnrollmentData(signal?: AbortSignal): Promise<LTCEnrollmentResponse> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(endpoints.Trustmark.getEnrollmentData())

		return axios.get(endpoint.url(), { signal })
	}

	static getTrustmarkClientToken(
		data: { isCustomFunctionality: boolean } & Partial<WorkerAddressResponse>,
		signal?: AbortSignal,
	): Promise<string> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(endpoints.Trustmark.getTrustmarkClientToken())

		return axios.post(endpoint.url(), data, { signal })
	}

	static getWorkerAddress(signal?: AbortSignal): Promise<WorkerAddressResponse> {
		const endpoint: UrlBuilder = urlBuilder.setEndpoint(endpoints.Trustmark.getWorkerAddress())

		return axios.get(endpoint.url(), { signal })
	}
}
